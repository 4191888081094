/** CHANGE HOTEL COLORS CONFIG TO GENERIC **/
:root {
    --site-border-color: #CCD9D6 !important;
}

/** BASE CONFIG **/
html, body {
    position: relative;
    /* overflow-x: hidden; */

    display: block;
    min-width: 360px;
    width: auto !important;
    margin: 0 auto;
    padding: 0;

    font-family: var(--site-font);
    color: var(--text-color);
    font-size: 12px;
    font-weight: 400;

    background: #FFFFFF;
}

/* containers */
header, aside, main, footer, section, article, nav, div, label, br, dl, dt, dd {
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    font-weight: normal;
}

br {
    height: 1em;
}

/* lists */
ul.no-list,
ol.no-list {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.no-list > li,
ol.no-list > li {
    display: block;
    margin: 0 0 0.5em;
    padding: 0;
    list-style: none;
}

/* form elements */
input[type="text"], input[type="text"]:hover, input[type="text"]:focus,
input[type="number"], input[type="number"]:hover, input[type="number"]:focus,
input[type="phone"], input[type="phone"]:hover, input[type="phone"]:focus,
input[type="time"], input[type="time"]:hover, input[type="time"]:focus,
input[type="date"], input[type="date"]:hover, input[type="date"]:focus,
input[type="email"], input[type="email"]:hover, input[type="email"]:focus,
input[type="password"], input[type="password"]:hover, input[type="password"]:focus,
select, select:hover, select:focus,
textarea, textarea:hover, textarea:focus {
    position: relative;

    padding: 1em 1.2em;
    outline: none;
    box-shadow: none;
    border: 1px solid var(--site-border-color);
    width: 200px;
    max-width: calc(100% - 2.4em);

    font-size: 1em;
    line-height: 1.5em;
    color: var(--site-color);
    font-family: var(--site-primary-title-font);
}

input[type="text"]:not(:disabled):hover, input[type="text"]:not(:disabled):focus,
input[type="number"]:not(:disabled):hover, input[type="number"]:not(:disabled):focus,
input[type="phone"]:not(:disabled):hover, input[type="phone"]:not(:disabled):focus,
input[type="time"]:not(:disabled):hover, input[type="time"]:not(:disabled):focus,
input[type="date"]:not(:disabled):hover, input[type="date"]:not(:disabled):focus,
input[type="email"]:not(:disabled):hover, input[type="email"]:not(:disabled):focus,
input[type="password"]:not(:disabled):hover, input[type="password"]:not(:disabled):focus,
select:not(:disabled):hover, select:not(:disabled):focus,
textarea:not(:disabled):hover, textarea:not(:disabled):focus {
    border-color: var(--site-color);
}

input:not(.btn):disabled, select:disabled, textarea:disabled {
    opacity: 0.7;
    background-color: var(--grey-color-light);
    border-color: var(--site-border-color) !important;
    cursor: not-allowed;
}

a.btn[disabled],
button.btn:disabled,
input.btn:disabled {
    cursor: default;
    opacity: 0.5;
}

a.btn-primary[disabled],
button.btn-primary:disabled,
input.btn-primary:disabled {
    opacity: 0.25;
}

.center-text {
    text-align: center;
}

.left-text {
    text-align: left;
}

.right-text {
    text-align: right;
}

textarea {
    max-width: 100%;
    min-width: 200px;
    min-height: 5em;
}

select, input[type="checkbox"], input[type="radio"] {
    cursor: pointer;
}

input[type="checkbox"], input[type="radio"] {
    display: inline-block;
    vertical-align: middle;
}

input[type="checkbox"] + label {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 30px);
    cursor: pointer;
    color: var(--site-color);
}

/* form style */
.form-style .input-wrapper,
.search-content .form-style p,
.form-style .input-wrapper + .error-msg-red {
    position: relative;
    display: block;
    margin: 0 auto 12px;
    padding: 0;
}

.form-style .input-wrapper + .error-msg-red {
    margin-top: -12px;
}

.search-content .form-style p {
    color: var(--site-color);
    font-size: 14px;
    font-weight: bold;
}

.form-style .input-wrapper > * + a,
.form-style .input-wrapper > * + p {
    display: block;
    margin: 5px 0 0;
}

.form-style .input-wrapper input[type="text"].error,
.form-style .input-wrapper input[type="number"].error,
.form-style .input-wrapper input[type="phone"].error,
.form-style .input-wrapper input[type="time"].error,
.form-style .input-wrapper input[type="date"].error,
.form-style .input-wrapper input[type="email"].error,
.form-style .input-wrapper input[type="password"].error,
.form-style .input-wrapper select.error,
.form-style .input-wrapper textarea.error {
    border-color: var(--red-color) !important;
}

.form-style .input-wrapper > input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: none;

    margin-top: 10px;
    margin-bottom: 10px;
}

.form-style .input-wrapper input::-webkit-input-placeholder { /* Edge */
    color: var(--site-border-color);
}

.form-style .input-wrapper input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--site-border-color);
}

.form-style .input-wrapper input::placeholder {
    color: var(--site-border-color);
}

.form-style .input-wrapper label.error {
    color: var(--red-color) !important;
}

.form-style .input-wrapper p.error-msg {
    display: none;
    color: var(--red-color);
}

.error-msg-red {
    display: none;
    color: var(--red-color);
}

.error-msg-red-inline {
    display: inline;
    color: var(--red-color);
}

/* position label */
.form-style .input-wrapper > label:first-child,
.form-style .input-wrapper.only-view-phone > input + label {
    position: absolute;
    z-index: 2;
    top: 16px;
    left: 16px;

    /*font-size: 14px;*/
    color: var(--text-color-light);
}

.form-style .input-wrapper.only-view-phone > label:first-child + input {
    display: inline-block;
    width: 3em;
}

.form-style .input-wrapper.only-view-phone > input + label {
    left: 4.6em;
}

.form-style .input-wrapper.only-view-phone > input + label + input {
    display: inline-block;
    width: calc(100% - 6em);
}

.form-style .input-wrapper .final-btn {
    padding: 22px 20px 20px;
}

.form-style .input-wrapper > label + input,
.form-style .input-wrapper > label + .autocomplete-wrapper input,
.form-style .input-wrapper > label + select,
.form-style .input-wrapper > label + textarea {
    position: relative;
    z-index: 1;

    width: calc(100% - 32px);
    max-width: calc(100% - 32px);
    padding: 30px 15px 10px;
    font-size: 16px;
}

/* start simple-input */
.search-content .form-style .input-wrapper > label,
.form-style .input-wrapper.simple-input > label {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 5px;
}

.search-content .form-style .input-wrapper > label + input,
.form-style .input-wrapper.simple-input > label + input,
.search-content .form-style .input-wrapper > label + .autocomplete-wrapper input,
.form-style .input-wrapper.simple-input > label + .autocomplete-wrapper input,
.search-content .form-style .input-wrapper > label + select,
.form-style .input-wrapper.simple-input > label + select,
.search-content .form-style .input-wrapper > label + textarea,
.form-style .input-wrapper.simple-input > label + textarea {
    padding-top: 10px;
}

/* end simple-input */
.autocomplete-wrapper {
    position: relative;
}

.autocomplete-wrapper .autocomplete-results {
    position: absolute;
    z-index: 10;
    left: 0;

    width: 100%;
    padding: 0;
    background: #FFFFFF;
}

.autocomplete-wrapper .autocomplete-results .autocomplete-result {
    display: block;
    cursor: pointer;
    padding: 10px 10px;
    border: 1px solid var(--site-border-color);
    border-top: none;
}

.autocomplete-wrapper .autocomplete-results .autocomplete-result:hover {
    /*
    color: var(--site-color-hover);
    border-color: var(--site-color-hover);
    */
    background-color: var(--site-color-light);
}

.form-style .input-wrapper > label + textarea {
    min-width: calc(100% - 32px);
}

.form-style .input-wrapper > label + select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.form-style .input-wrapper > label + input[type="time"]::-webkit-calendar-picker-indicator,
.form-style .input-wrapper > label + input[type="date"]::-webkit-calendar-picker-indicator {
    color: currentColor;
    opacity: 1;
}

.form-style .input-wrapper > label + .react-tel-input > input {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    padding: 40px 15px 20px 58px;
    font-size: 16px;
    font-family: var(--site-primary-title-font);
    color: var(--site-color);

    outline: none;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid var(--site-border-color);
}

.form-style .input-wrapper > label + .react-tel-input > input:not(:disabled):hover,
.form-style .input-wrapper > label + .react-tel-input > input:not(:disabled):focus {
    border: 1px solid var(--site-color);
}

.form-style .input-wrapper > label + .react-tel-input .flag-dropdown {
    z-index: 2;
    padding-left: 5px;
    padding-top: 20px;
}

.form-style .input-wrapper.simple-input > label + .react-tel-input > input {
    padding-top: 13px;
    padding-bottom: 13px;
}

.form-style .input-wrapper.simple-input > label + .react-tel-input .flag-dropdown {
    padding-top: 0px;
}

.form-style .input-wrapper > label + select {
    width: 100%;
    max-width: 100%;
}

.form-style .select-wrapper::after {
    content: '';
    position: absolute;
    right: 22px;
    bottom: 20px;

    display: block;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    border-color: var(--site-color) transparent transparent transparent;
    line-height: 0px;
}

.form-style .select-wrapper > input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

.form-style .select-wrapper > select {
    -webkit-appearance: none;
    border-radius: 0;
}

/* inline label position */
.search-content .form-style .inline-label-wrapper > label:first-child {
    position: relative;
    top: auto;
    left: auto;

    display: inline-block;
    vertical-align: middle;
}

.search-content .form-style .inline-label-wrapper > .select-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.search-content .form-style .inline-label-wrapper > .select-wrapper::after {
    top: calc(50% - 3px);
    right: 5px;
}

.search-content .form-style .inline-label-wrapper > .select-wrapper > select {
    display: inline-block;
    width: auto;
    max-width: none;
    margin: 0;
    padding: 5px 20px 5px 5px;

    border: none;
    box-shadow: none;
    background: none;
}

/* buttons not column */
.search-content .form-style .btns-wrapper > * + * {
    margin-top: 8px !important;
}

/* column buttons */
.search-content .form-style .column-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.search-content .form-style .column-btns > * + * {
    margin-left: 8px !important;
}

.search-content .form-style .column-btns .btn svg,
.search-content .form-style .column-btns .btn span {
    display: inline-block;
    vertical-align: middle;
}

.search-content .form-style .column-btns .btn-big,
.search-content .form-style .column-btns .btn-primary {
    padding: 15px;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 15px;
}

/* column inputs */
.form-style .column-inputs::after {
    content: '';
    display: block;
    clear: both;
}

.form-style .column-inputs > .input-wrapper {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 5.2px);
}

.form-style .column-inputs > .input-wrapper + .input-wrapper {
    margin-left: 8px;
}

.form-style .column-inputs.also-mobile {
    margin-bottom: 12px;
}

.form-style .column-inputs.also-mobile > .input-wrapper {
    margin-bottom: 0;
}

/* change space bettween inputs */
.form-style.max-space .input-wrapper,
.form-style.max-space .column-inputs.also-mobile {
    margin-bottom: 24px;
}

.form-style.max-space .column-inputs > .input-wrapper + .input-wrapper {
    margin-left: 24px;
}

.form-style.max-space .column-inputs > .input-wrapper {
    width: calc(50% - 14px);
}

.form-style.no-bottom-space .input-wrapper {
    margin-bottom: 0;
}

/* show disabled inputs like text */
.form-style.only-view .input-wrapper input:disabled,
.form-style.only-view .input-wrapper select:disabled,
.form-style.only-view .input-wrapper textarea:disabled {
    border: none !important;
    background: transparent !important;
    color: var(--text-color) !important;
    cursor: default;
}

.form-style.only-view .input-wrapper select:disabled {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}

.form-style.only-view .input-wrapper select:disabled::-ms-expand { /* For IE10 */
    display: none;
}

/* phone inputs */
.form-style .input-wrapper > .input-wrapper {
    margin-bottom: 0 !important;
}

.form-style .phone-wrapper > .input-wrapper.prefix-input {
    display: inline-block;
    width: 80px;
}

.form-style .phone-wrapper > .input-wrapper.prefix-input + .input-wrapper {
    display: inline-block;
    width: calc(100% - 91px);
    margin-left: 8px;
}

/* date range picker */
.form-style .input-wrapper ._1g69z6oy > ._mfgyvi {
    border: none !important;
    background: none !important;
    border-radius: 0 !important;
}

/* date range picker - inputs */
.form-style .input-wrapper ._1g69z6oy > ._4z7e5d > input#startDate,
.form-style .input-wrapper ._1g69z6oy > ._4z7e5d > input#endDate {
    position: relative;

    width: auto;
    max-width: auto;

    padding: 30px 11px 20px !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid var(--site-border-color) !important;

    font-size: 16px !important;
    color: var(--site-color) !important;
    font-family: var(--site-primary-title-font) !important;
}

.form-style .input-wrapper ._1g69z6oy > ._4z7e5d > input#startDate::before,
.form-style .input-wrapper ._1g69z6oy > ._4z7e5d > input#endDate::before {
    content: 'Fecha';

    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;

    display: block;
    font-size: 12px;
    color: var(--text-color-light);
}

.form-style .input-wrapper ._1g69z6oy > ._4z7e5d > input#endDate {
    border-left: none !important;
}

.form-style .input-wrapper ._1g69z6oy > ._mfgyvi {
    display: none !important;
}

/* date range picker - calendar */
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf {
    border: 1px solid var(--site-color) !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._1svux14 {
    padding: 0 !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._754zdu7 {
    left: 0 !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._754zdu7 > * {
    width: 310px;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._1svux14 > ._gucugi {
    padding: 14px 0 !important;
    background-color: var(--grey-color-light) !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._1svux14 > ._gucugi > strong {
    color: var(--site-color) !important;
    font-family: var(--site-primary-title-font) !important;
    font-weight: normal !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._17w2za > ._18s8f6ik,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._17w2za > ._1h5uiygl {
    top: 10px !important;
    background: none !important;
    border: none !important;
    outline: none !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._17w2za > ._18s8f6ik > svg,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._17w2za > ._1h5uiygl > svg {
    fill: var(--site-color) !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._17w2za > ._18s8f6ik:not(:disabled):hover > svg,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._17w2za > ._1h5uiygl:not(:disabled):hover > svg {
    fill: var(--site-color-hover) !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o ._txa8fll {
    /* remove days name */
    display: none !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table {
    width: auto !important;
    margin: 0 7px 0 20px !important;
    /* for show days name margin-top: 40px; */
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr th {
    border-color: transparent !important;

    padding: 0 !important;

    color: var(--site-color) !important;
    font-family: var(--site-primary-title-font) !important;
    font-weight: normal !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 18px !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td[aria-disabled=true] {
    color: var(--text-color-light) !important;
    background: none !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td[aria-disabled=false]:not(:disabled):hover,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td._1v0kcemm,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td[aria-label^="Selected as start date."],
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td[aria-label^="Selected as end date."] {
    background-color: var(--site-color) !important;
    color: #FFF !important;
    /*border-radius: 50%;*/
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td[aria-label^="Selected."],
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td._pjh1mmm:not(:hover),
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf > ._1kx9xc9o table tr td._1y32pr1o:not(:hover) {
    background-color: var(--site-color-light) !important;
}

.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf ._1avmvua6,
.form-style .input-wrapper ._1g69z6oy > ._vtg8tkf ._1t0308zw {
    outline: none !important;
}

/* change label color */
.form-style.color-label .input-wrapper > label {
    color: var(--site-color);
}

.form-style.color-label .input-wrapper > label span {
    color: var(--text-color-light);
}

/* table */
table {
    display: table;
    width: 100%;
    border-collapse: separate;
    box-sizing: border-box;
    border-spacing: 0;
    border: none;
}

table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

table tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

table th,
table td {
    display: table-cell;
    vertical-align: inherit;
    text-align: -internal-center;

    padding: 0 0 5px;
    border: none;
}

table th:first-child,
table td:first-child {
    text-align: left;
}

table th:last-child,
table td:last-child {
    text-align: right;
}

table th {
    font-weight: bold;
}

/* table - add border */
table.border {
    border: 1px solid var(--site-border-color);
}

table.border tr + tr > * {
    border-top: 1px solid var(--site-border-color);
}

table.border th,
table.border td {
    padding: 12px;
}

table.border tr > * + * {
    border-left: 1px solid var(--site-border-color);
}

/* table only border-bottom */
table.border-bottom tr > * {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--site-border-color);
}

table.border-bottom tr + tr > * {
    padding-top: 12px;
}

/* img */
img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
}

/* lists */
ul, ol {
    display: block;
    margin: 0;
    padding: 0 0 2em 4em;
    font-size: 1em;
    line-height: 1.4em;
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

ul > li,
ol > li {
    display: list-item;
    margin: 0;
    padding: 2em 0 0;
    font-size: 1em;
    line-height: 1.4em;
}

/* text paragraph */
p, h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.4em;
}

p {
    line-height: 1.6em;
}

strong {
    font-weight: bold;
}

/* text link */
a, a:hover, a:focus, a:active, a:visited {
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    font-weight: normal;
    text-shadow: none;
    outline: none;

    color: var(--site-color);
}

a:hover, a:focus {
    color: var(--site-color-hover);
}

a.btn-link {
    text-decoration: none;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
}

/* text title */
h1, .h1 {
    color: var(--site-color);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 2em;
}

h2, .h2 {
    color: var(--site-color);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 1.5em;
}

h3, .h3 {
    color: var(--site-color);
    font-family: var(--site-title-font);
    font-size: 1.35em;
}

h4, .h4 {
    color: var(--site-color);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 1.2em;
}

h5, .h5 {
    color: var(--site-color);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
}

h6, .h6 {
    color: var(--site-color);
    font-family: var(--site-title-font);
    font-weight: bold;
    font-size: 0.8em;
}

/** BASE CONFIG CLASS **/
.container {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

/* System messages */
.system-msg {
    position: relative;
    display: block;
    max-width: 750px;
    margin: 20px auto;
    padding: 0 20px;

    font-size: 1.2em;
}

.container .system-msg {
    padding-left: 0;
    padding-right: 0;
}

.system-msg:first-child {
    margin-top: 0;
}

.system-msg:last-child {
    margin-bottom: 0;
}

.system-msg .msg-box {
    position: relative;
    display: block;
    margin: 0;
    padding: 16px;

    color: var(--text-color);
    border: 1px solid var(--text-color);
    background-color: var(--grey-color-light);
}

.system-msg.warning-msg .msg-box {
    border-color: #FFAA10;
    background-color: #FFF2DA;
}

.system-msg.success-msg .msg-box {
    border-color: #00B900;
    background-color: #E0F2E0;
}

.system-msg.error-msg .msg-box {
    border-color: #D52D2D;
    background-color: #FCD5D5;
}

.system-msg .msg-box > img,
.system-msg .msg-box > svg,
.system-msg .msg-box .text-content > img,
.system-msg .msg-box .text-content > svg {
    position: absolute;
    top: 16px;
    left: 20px;

    display: block;
    width: 22px;
    height: 22px;
    margin: 0;
}

.system-msg .msg-box > img + .text-content,
.system-msg .msg-box > svg + .text-content,
.system-msg .msg-box .text-content > img + *,
.system-msg .msg-box .text-content > svg + * {
    padding-left: 44px;
}

.system-msg .msg-box > .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;

    display: block;
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin: 0;
}

.system-msg .msg-box > .close-icon::before,
.system-msg .msg-box > .close-icon::after {
    width: 2px;
}

.system-msg .msg-box .text-content .title {
    color: var(--text-color);
    font-family: var(--site-font);
    font-weight: bold;
    font-size: 1.35em;
    margin-bottom: 0.5em;
}

.system-msg .msg-box .text-content p a {
    color: var(--text-color);
    text-decoration: underline;
}

.system-msg .msg-box .text-content .no-btn {
    display: inline-block;
    text-decoration: underline;
}

.system-msg .msg-box .text-content .btns-wrapper {
    text-align: center;
    margin-top: 24px;
}

.system-msg .msg-box .text-content .btns-wrapper .btn {
    display: inline-block;
    min-width: 220px;
    margin: 0 auto;
}

.system-msg .msg-box .text-content .btn:first-child,
.system-msg .msg-box .text-content .btns-wrapper:first-child {
    margin-top: 0;
}

.system-msg .msg-box .text-content .btns-wrapper > * + * {
    margin-top: 24px;
}

/* in popup remove background */
.popup-content .system-msg {
    margin: 0;
    padding: 40px;
}

.popup-content .system-msg .msg-box {
    border: none;
    background: none;
}

.popup-content .system-msg .msg-box .text-content > img,
.popup-content .system-msg .msg-box .text-content > svg {
    top: 25px;
}

.popup-content .system-msg .msg-box .text-content > img + .title,
.popup-content .system-msg .msg-box .text-content > svg + .title {
    font-size: 2em;
}


.primary-title-font {
    font-family: var(--site-primary-title-font);
}

.title-font {
    font-family: var(--site-title-font);
}

.site-font {
    font-family: var(--site-title-font);
}

.text-normal {
    font-weight: normal;
}

.text-bold {
    font-weight: bold;
}

/* change background colors */
.site-bg-light {
    background-color: var(--site-color-light);
}

.grey-bg-light {
    background-color: var(--grey-color-light);
}

.site-bg,
a.site-bg,
a.site-bg:not(:disabled):hover,
a.site-bg:not(:disabled):focus {
    background-color: var(--site-color-header);
    color: #FFFFFF;
}

.site-bg a, .site-bg a:hover, .site-bg a:focus, .site-bg a:active, .site-bg a:visited,
.site-bg h1, .site-bg .h1,
.site-bg h2, .site-bg .h2,
.site-bg h3, .site-bg .h3,
.site-bg h4, .site-bg .h4,
.site-bg h5, .site-bg .h5,
.site-bg h6, .site-bg .h6 {
    color: var(--text-color-header) !important;
}

.site-bg a, .site-bg a:hover, .site-bg a:focus, .site-bg a:active, .site-bg a:visited {
    text-decoration: underline;
}

/* buttons */
button, button:not(:disabled):hover, button:not(:disabled):focus, button:active, button:visited,
.btn, .btn:hover, .btn:focus, .btn:active, .btn:visited {
    display: inline-block;
    cursor: pointer;
    width: auto;
    min-width: 120px;
    box-sizing: border-box;
    margin: 0;
    padding: 14px 20px;

    color: var(--site-color-button);
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    outline: none;
    border: 1px solid var(--site-color-button);
    background-color: transparent;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

button svg, button span,
.btn svg, .btn span {
    display: inline-block;
    vertical-align: middle;
    color: currentColor;
}

button.no-btn {
    padding: 0;
    min-width: none;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    box-shadow: none;
}

.success .success-banner .btn,
.success .success-banner .btn:hover,
.success .success-banner .btn:focus,
.success .success-banner .btn:active,
.success .success-banner .btn:visited {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

button:disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

button.grey, .btn.grey {
    color: var(--site-color);
    background-color: var(--grey-color-light);
    border-color: var(--grey-color-light);
}

button.ouline, .btn.ouline {
    background-color: #FFFFFF;
    border-color: var(--site-color);
}

button.primary, .btn.primary,
button:not(:disabled):hover, .btn:not(:disabled):hover {
    color: #FFFFFF;
    background-color: var(--site-color);
    border-color: var(--site-color);
}

button.primary, button.primary:hover, button.primary:focus, button.primary:active, button.primary:visited,
.btn.primary, .btn.primary:hover, .btn.primary:focus, .btn.primary:active, .btn.primary:visited {
    min-width: 270px;
}

button.primary:not(:disabled):hover, .btn.primary:not(:disabled):hover {
    color: var(--site-color);
    background-color: transparent;
}

/* chenge text size */
.big-text {
    font-size: 16px;
}

.small-text {
    font-size: 10px;
}

/* change text colors */
.light-color,
a.light-color, a.light-color:active, a.light-color:visited {
    color: var(--text-color-light);
}

.site-color-light,
a.site-color-light, a.site-color-light:active, a.site-color-light:visited {
    color: var(--site-color-light);
}

.site-color {
    color: var(--site-color);
}

.text-color {
    color: var(--text-color);
}

a.light-color:not(:disabled):hover,
a.site-color-light:not(:disabled):hover {
    color: var(--site-color-hover);
}

a.light-color, a.light-color:active, a.light-color:visited, a.light-color:not(:disabled):hover, a.light-color:not(:disabled):focus,
a.site-color-light, a.site-color-light:active, a.site-color-light:visited, a.site-color-light:not(:disabled):hover, a.site-color-light:not(:disabled):focus {
    text-decoration: underline;
}

.grey-color,
a.grey-color, a.grey-color:active, a.grey-color:visited {
    color: var(--grey-color);
}

/* change button styles */
#search-button {
    background-color: var(--site-color-button-search);
    border-color: var(--site-border-color-search);
}

#search-button:hover {
    background-color: var(--site-color-button-search-hover);
    border-color: var(--site-border-color-search-hover);
}

.btn.full-weidth,
.btn.full-weidth:not(:disabled):hover,
.btn.full-weidth:not(:disabled):focus,
.btn.full-weidth:active,
.btn.full-weidth:visited {
    width: 100%;
}

button.btn-big, .btn-big, .btn-big:hover, .btn-big:focus, .btn-big:active, .btn-big:visited,
.btn-big:not(:disabled):hover, .btn-big:not(:disabled):focus,
.btn-big.active:hover, .btn-big.active:focus,
.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:visited,
.btn-primary:not(:disabled):hover, .btn-primary:not(:disabled):focus,
.btn-primary.active:hover, .btn-primary.active:focus {
    padding: 15px;

    font-size: 1em;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:visited,
.btn-primary:not(:disabled):hover, .btn-primary:not(:disabled):focus,
.btn-primary.active:hover, .btn-primary.active:focus {
    color: #FFFFFF;
    border-color: var(--site-color);
    background-color: var(--site-color);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):hover,
.btn-primary:not(:disabled):focus,
.btn-primary:not(:disabled).active:focus,
.btn-primary:not(:disabled).active:hover {
    color: #FFFFFF;
    border-color: var(--site-color-hover);
    background-color: var(--site-color-hover);
}

/* rond button */
.btn-round, .btn-round:hover, .btn-round:focus, .btn-round:active, .btn-round:visited {
    padding: 5px 16px 6px;
    border-radius: 40px;
    border: none;
    box-shadow: none;

    color: var(--site-color);
    background-color: var(--site-color-light);
}

.btn-round > * {
    text-decoration: none !important;
}

.btn-round.active, .btn-round.active:focus, .btn-round.active:active, .btn-round.active:visited {
    color: #FFFFFF;
    background-color: var(--site-color);
}

@media ( hover: hover ) {
    .btn-round:not(:disabled):hover {
        color: #FFFFFF;
        background-color: var(--site-color);
    }
}

/* change button colors */
.btn-green, .btn-green:not(:disabled):hover, .btn-green:not(:disabled):focus, .btn-green:active, .btn-green:visited,
.btn-green.active:not(:disabled):hover, .btn-green.active:not(:disabled):focus {
    font-family: var(--site-primary-title-font);
    color: var(--green-color);
    border-color: var(--green-color);
    background-color: #FFFFFF;
}

.btn-green > * {
    text-decoration: none !important;
}

.btn-green.active, .btn-green.active:not(:disabled):focus, .btn-green.active:active, .btn-green.active:visited {
    background-color: var(--green-color-light);
}

@media ( hover: hover ) {
    .btn-green:not(:disabled):hover {
        background-color: var(--green-color-light);
    }
}

/** ICONS **/
/* to add icon using background */
.ico-before {
    position: relative;
    overflow: hidden;

    display: inline-block;
    vertical-align: middle;

    width: 20px;
    height: 20px;
    margin-right: 5px;

    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    font-size: 0;
    line-height: 0;
    color: transparent;
}

.ico-before + * {
    display: inline-block;
    vertical-align: middle;
}

/* ico search */
.ico-search, .ico-search:hover, .ico-search:focus, .ico-search:active, .ico-search:visited {
    display: inline-block;
    vertical-align: middle;

    font-size: 0;
    line-height: 0;
    color: transparent;
}

/*
.ico-search::before {
    content: '';

    display: block;
    width: 24px;
    height: 24px;

    background: url('/static/media/icons/ico-search.svg') no-repeat left center;
}
*/
.ico-search > img {
    display: block;
    width: 24px;
    height: 24px;
}

/* ico arrow */
.ico-arrow::after {
    content: '';

    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    margin: -1px 0 1px 8px;

    background: no-repeat center center;
    /*background: url(/static/media/ico-arrow.6b4a4e12.svg) no-repeat center center;*/
}

.btn.ico-arrow::after {
    margin: -14px -5px -12px 5px;
}

/* add link ico */
.add-link {
    text-decoration: none;
}

.add-link::before {
    content: '+';

    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin: -1px 5px 0 0;
    text-align: center;

    border-radius: 50%;
    border: 1px solid var(--site-color);
    color: var(--site-color);

    font-size: 14px;
    font-weight: normal;
    line-height: 12px;
}

/* ico info */
.ico-info {
    text-decoration: none;
}

.ico-info:not(.ico-right)::before,
.ico-info.ico-right::after {
    content: 'i';

    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin: -1px 0 0;
    text-align: center;

    border-radius: 50%;
    border: 1px solid var(--site-color);
    color: var(--site-color);

    font-size: 8px;
    font-weight: bold;
    line-height: 12px;
}

.ico-info:not(.ico-right)::before {
    margin-right: 5px;
}

.ico-info.ico-right::after {
    margin-left: 5px;
}

/* ico close */
.close-icon,
.close-icon:hover,
.close-icon:focus,
.close-icon:active,
.close-icon:visited {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    font-size: 0;
    color: transparent;
    text-decoration: none;
}

.close-icon::before,
.close-icon::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;

    display: block;
    width: 1px;
    height: 100%;
    background: var(--text-color-light);
}

.close-icon::before {
    transform: rotate(-45deg);
}

.close-icon::after {
    transform: rotate(45deg);
}

.close-icon:hover::before,
.close-icon:focus::before,
.close-icon:hover::after,
.close-icon:focus::after {
    background: var(--site-color);
}

/** END ICONS **/

/** LANGUAGE SELECT **/
.select-lang {
    position: relative;
    z-index: 10;
    display: inline-block;
}

.select-lang > .current-lang,
.select-lang > .current-lang:hover,
.select-lang > .current-lang:focus,
.select-lang > .current-lang:active,
.select-lang > .current-lang:visited {
    position: relative;
    display: block;
    font-weight: bold;
    padding: 5px 15px 5px 5px;
    text-decoration: none;
}

.select-lang > .current-lang:hover,
.select-lang > .current-lang:focus {
    /*text-decoration: underline;*/
    color: var(--site-color-hover);
}

.select-lang > .current-lang::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 2px;

    display: block;
    width: 8px;
    height: 5px;
    margin: -1px 0 0;

    box-sizing: border-box;
    border-style: solid;
    border-color: transparent transparent var(--site-color) transparent;
    border-width: 0 4px 4px 4px;

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.select-lang:not(.opened) > .current-lang::after {
    transform: rotate(-180deg);
}

.select-lang > .lang-options,
.select-lang > .lang-options > * {
    display: block;
    width: auto !important;
    max-width: none;
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
    background: none;
    box-shadow: none;
}

.select-lang::after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    top: 10px;

    box-sizing: border-box;
    display: block;
    width: 0;
    height: 0;

    border: 3px solid transparent;
    border-left-color: var(--site-color);

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.select-lang > .lang-options {
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;

    color: var(--site-color) !important;

    line-height: 2em;
    padding-left: 5px;
    padding-right: 15px;
}

.select-lang > .current-lang + .lang-options {
    position: absolute;
    z-index: 10;
    display: none;
    background-color: var(--site-color-light);
}

.select-lang > .lang-options a,
.select-lang > .lang-options a:hover,
.select-lang > .lang-options a:focus,
.select-lang > .lang-options a:active,
.select-lang > .lang-options a:visited {
    display: block;
    margin: 0;
    padding: 5px 10px;
    text-decoration: none;
}

.select-lang > .lang-options a:hover,
.select-lang > .lang-options a:focus {
    /*text-decoration: underline;*/
    color: var(--site-color-hover);
}

/** END LANGUAGE SELECT **/

/** MODAL POPUP **/
.motor-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    /*
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    */
    display: none;
    padding: 0;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);
    font-size: 16px;
}

.motor-modal > .motor-modal-close {
    display: block;
    width: 30px;
    height: 30px;
    min-height: 30px;

    margin: 50px 80px 30px auto;
}

.motor-modal > .motor-modal-close::before,
.motor-modal > .motor-modal-close::after {
    width: 2px;
    background-color: #FFFFFF;
}

.motor-modal > .motor-modal-box {
    overflow: auto;
    width: calc(100% - 200px);
    max-width: 700px;
    max-height: calc(100% - 240px);

    margin: 0 auto 50px;
    padding: 50px 100px;

    background: #FFF;
    text-align: center;
}

.motor-modal > .motor-modal-box .title {
    font-size: 20px;
    margin-bottom: 30px;
}

.motor-modal > .motor-modal-box .small-text {
    font-size: 12px;
}

/* room info modal */
.motor-modal.modal-info-room > .motor-modal-box {
    padding: 0;
    max-width: 850px;
}

.motor-modal.modal-info-room > .motor-modal-box .site-bg-light {
    padding: 40px 20px 50px;
}

.motor-modal.modal-info-room > .motor-modal-box > .title {
    padding: 25px 30px;
    text-align: left;
    font-size: 24px;
    border-bottom: 1px solid var(--site-border-color);
}

.motor-modal.modal-info-room > .motor-modal-box .sub-title {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: normal;
    color: var(--site-color-up);
}

.motor-modal.modal-info-room > .motor-modal-box .room-desc img {
    display: block;
    margin: 0 auto 20px;
}

.motor-modal.modal-info-room > .motor-modal-box .room-desc,
.motor-modal.modal-info-room > .motor-modal-box .features-columns > .features-column {
    padding: 0 30px 64px;
}

.motor-modal.modal-info-room > .motor-modal-box .features-columns > .features-column .features-list > li {
    margin-top: 30px;
    margin-bottom: 30px;
}

.motor-modal.modal-info-room > .motor-modal-box .room-desc p:not(.sub-title):not(.primary) {
    text-align: left;
}

.motor-modal.modal-info-room > .motor-modal-box .room-desc p + p {
    margin-top: 20px;
}

.motor-modal.modal-info-room > .motor-modal-box .room-desc .primary {
    font-family: var(--site-title-font);
    color: var(--site-color);
    font-size: 24px;
    margin-bottom: 30px;
}

/* up info */
.motor-modal.modal-info-room > .motor-modal-box .room-up-wrapper {
    margin-top: -25px;
    padding: 25px 30px;
    color: var(--site-color);
}

.motor-modal.modal-info-room > .motor-modal-box .room-up-list,
.motor-modal.modal-info-room > .motor-modal-box .room-up-list > li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.motor-modal.modal-info-room > .motor-modal-box .room-up-list {
    text-align: left;
    margin: 0 -12px;
}

.motor-modal.modal-info-room > .motor-modal-box .room-up-list > li {
    display: inline-block;
    vertical-align: top;
    width: calc(25% - 27px);
    padding: 0 12px 40px;
    text-align: left;
}

.motor-modal.modal-info-room > .motor-modal-box .room-up-list img {
    margin-bottom: 15px;
}

.motor-modal.modal-info-room > .motor-modal-box .room-up-list .pre-title {
    font-size: 12px;
    font-weight: normal;
    font-family: var(--site-title-font);
}

.motor-modal.modal-info-room > .motor-modal-box .room-up-list .title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: var(--site-primary-title-font);
}

/* features info */
.motor-modal.modal-info-room > .motor-modal-box .features-columns {
    text-align: left;
}

/* Hector popups close icon */
.popup-overlay .close-icon {
    position: absolute;
    z-index: 2;
    right: 16px;
    top: 16px;
    display: block;
    width: 20px;
    height: 20px;
}

/* adjust popup colors */
.popup-content {
    /*width: 670px !important;*/
    width: 80% !important;
    max-width: calc(100% - 40px) !important;
    max-height: calc(100% - 60px) !important;
    border: none !important;
    border-radius: 0;
    padding: 0 !important;
    overflow: auto !important;
}

/* @media only screen and (max-width:370px){
    .popup-content { 
        width:60% !important;
    }
} */

.popup-overlay.big-popup::before,
.popup-overlay.big-popup .popup-content,
.popup-overlay .popup-content.big-popup {
    width: 800px !important;
}

.popup-overlay.full-popup::before,
.popup-overlay.full-popup .popup-content,
.popup-overlay .popup-content.full-popup {
    width: 1200px !important;
}

.popup-content #motor_search_box .search-header {
    /* padding: 80px 20px 24px; */
    padding: 40px 20px 30px;
    color: var(--site-color);
}

.popup-content #motor_search_box .search-header h2,
.popup-content #motor_search_box .search-header .h2 {
    color: var(--site-color) !important;
}

/* login modal popup */
.popup-content .login-form-wrapper {
    padding: 32px 40px;
}

/* add close icon in poup */
.popup-overlay {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 2147483641 !important;
}

.popup-overlay::before {
    content: 'x';
    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAYAAAARIY8tAAABLUlEQVRIS6WVsVHEMBBF328AEogog4AmiCACWoHorhWOCCKaIKAMIkioYBndaD1CluT1WJk94//0/+56BWBm98CzJKXnrcfMDHiQdJCZnQC/LroVksVd7vR4YzM7A77z2xdJydHqY2YH4C5/eC7pZ4pkK6QlnkD/Ms+Qd+AKCDspxD+A63TzKfI6h7WQkfjMgcOikCXxLqAofDeuiPgQMIJExRcBLUiOMbXirKCtvg5NblWTpBMSDzkoCv8K3OTnN0m3kUmMOvAJ/cyil9E5WQTUBc2A8DAOAb1uic7J0hx4LM2CRiFNB9E+j0BmgKh49LdS/02HsfTacuSk3Adpzfk5LotIn1dOfGnhm9E32ibxEcQBj8AOWH3zzj5JTp4k7cuILiR9rYllUJNJ6w9TEdYj5wFwUAAAAABJRU5ErkJggg==');

    display: block;
    height: 1.2em;
    /*width: 670px;*/
    width: 80%;
    margin: auto auto 0;
    padding: 5px;

    font-family: monospace, Verdana, sans-serif;
    font-size: 20px;
    text-align: right;
    font-weight: lighter;

    color: #FFF;
    background: transparent;
}

.popup-overlay .popup-content {
    margin-top: 0 !important;
    cursor: default;
}

.popup-overlay .popup-content > .close-icon {
    display: none;
}

/** END MODAL POPUP **/


/** MODAL OFERTAS APLICADAS **/
.discount-info-wrapper {
    text-align: center;
    font-size: 1.2em;
    padding: 40px;
}

.discount-info-wrapper p {
    margin-bottom: 1.5em;
}

.discount-info-wrapper .title {
    color: currentColor;
    font-family: var(--site-primary-title-font);
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0.8em;
}

.discount-info-wrapper .discount-info {
    position: relative;
    padding-left: 40px;
}

.discount-info-wrapper .discount-info > img,
.discount-info-wrapper .discount-info > svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
}

.discount-info-wrapper .discount-info strong {
    display: block;
}

.discount-info-wrapper p:last-child {
    margin-bottom: 0;
}

/** END MODAL OFERTAS APLICADAS **/

/** LOGIN FORM **/
.login-form-wrapper .title {
    margin-bottom: 1em;
}

.login-form-wrapper .form-style .input-wrapper > label:first-child {
    color: var(--text-color);
}

.login-form-wrapper .form-style .input-wrapper a {
    color: var(--grey-color);
}

.login-form-wrapper .form-style .input-wrapper a:hover,
.login-form-wrapper .form-style .input-wrapper a:focus {
    color: var(--site-color);
}

.login-form-wrapper .form-style input.btn-primary {
    margin-top: 1.5em;
}

.login-form-wrapper .or-label {
    position: relative;
    display: block;
    width: 100%;
    margin: 30px 0;
    padding: 0;
    text-align: center;
}

.login-form-wrapper .or-label::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    display: block;
    width: 100%;
    height: 1px;
    background: var(--site-border-color);
}

.login-form-wrapper .or-label > span {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin: 0 auto;
    padding: 8px;

    background: #fff;
    color: var(--grey-color);
    line-height: 1em;
}

.login-form-wrapper .btn + .btn {
    margin-top: 1.5em;
}

.login-form-wrapper .btn-google,
.login-form-wrapper .btn-facebook {
    font-size: 16px;
    color: var(--site-color);
    border-color: var(--site-border-color);
    background-color: #fff;
    box-shadow: none;
}

.login-form-wrapper .btn-google:hover,
.login-form-wrapper .btn-google:focus,
.login-form-wrapper .btn-facebook:hover,
.login-form-wrapper .btn-facebook:focus {
    color: var(--site-color);
    background-color: var(--site-border-color);
    border-color: var(--site-border-color);
}

.login-form-wrapper .btn-google span,
.login-form-wrapper .btn-facebook span {
    display: inline-block;
    vertical-align: middle;
}

.login-form-wrapper .btn-google img.social-logo,
.login-form-wrapper .btn-facebook img.social-logo {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
}

/** END LOGIN FORM **/

/** IMAGE VISOR **/
.img-visor {
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

/* prev/next buttons */
.img-visor > .prev,
.img-visor > .next {
    position: absolute;
    z-index: 2;
    top: 50%;

    display: block;
    overflow: hidden;
    width: 24px;
    height: 24px;
    margin: -12px 0 auto;
    padding: 0;

    font-size: 0;
    line-height: 0;
    color: transparent !important;

    background: #FFFFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    opacity: 0.8;
}

.img-visor > .prev {
    left: 12px;
}

.img-visor > .next {
    right: 12px;
}

.img-visor > .prev > svg,
.img-visor > .next > svg {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.img-visor > .prev > svg {
    transform: rotate(180deg);
}

.img-visor > .prev:hover,
.img-visor > .prev:focus,
.img-visor > .next:hover,
.img-visor > .next:focus {
    opacity: 1;
}

.img-visor > .prev.disabled,
.img-visor > .next.disabled {
    opacity: 0.2 !important;
    cursor: default !important;
    box-shadow: none;
}

/* images list */
.img-visor > .img-visor-list {
    position: relative;
    z-index: 1;
    left: 0;

    display: block;
    overflow: visible;
    width: auto;
    height: auto;
    list-style: none;
    margin: 0;
    padding: 0;

    font-size: 0;
    line-height: 0;
    white-space: nowrap;
    transition: left 0.5s;
}

.img-visor > .img-visor-list > li {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    background: var(--site-color-light);
    white-space: nowrap;
}

.img-visor > .img-visor-list > li > .img-wraper {
    display: block;
    width: 100%;
    height: auto;
}

.img-visor > .img-visor-list > li img {
    width: 100%;
    height: auto;
    margin: auto;
    padding: 0;
}

/* image counter */
.img-visor > .img-counter {
    position: absolute;
    z-index: 3;
    left: 30px;
    bottom: 25px;

    font-family: var(--site-title-font);
    font-size: 16px;
    color: #FFFFFF;
}

/** END IMAGE VISOR **/

/** ROOM OPTIONS VISOR **/
.room-options-visor {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    margin: 0 -10px;
    padding: 0 25px;
}

/* prev/next buttons */
.room-options-visor > .prev,
.room-options-visor > .next {
    position: absolute;
    z-index: 2;
    top: 50%;

    display: block;
    overflow: hidden;
    width: 24px;
    height: 24px;
    margin: -12px 0 auto;
    padding: 0;

    font-size: 0;
    line-height: 0;
    color: transparent !important;

    background: #FFFFFF;
    opacity: 0.8;
}

.room-options-visor > .prev {
    left: 0;
}

.room-options-visor > .next {
    right: 0;
}

.room-options-visor > .prev > svg,
.room-options-visor > .next > svg {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.room-options-visor > .prev > svg {
    transform: rotate(180deg);
}

.room-options-visor > .prev:hover,
.room-options-visor > .prev:focus,
.room-options-visor > .next:hover,
.room-options-visor > .next:focus {
    opacity: 1;
}

.room-options-visor > .prev.disabled,
.room-options-visor > .next.disabled {
    opacity: 0.2 !important;
    cursor: default !important;
}

/* options list */
.room-options-visor > .room-options-list {
    position: relative;
    z-index: 1;
    left: 0;

    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transition: left 0.5s;

    font-size: 0;
    line-height: 0;
}

.room-options-visor > .room-options-list > .room-option-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 0;
    padding: 0;

    font-size: 12px;
    line-height: 1.4em;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 80px;
    margin: 0;
    padding: 0 6px;
    color: var(--site-color);
    white-space: normal;
    text-align: center;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option,
.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > * {
    text-decoration: none !important;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > .selector {
    position: relative;
    overflow: visible;

    display: block;
    width: 72px;
    height: 72px;
    margin: 0 auto 12px;

    border-radius: 50%;
    background: #FFFFFF;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > .selector > .icon-option {
    position: relative;
    top: 16px;

    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;

    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.35;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > .selector > .icon-status {
    position: absolute;
    bottom: 0;
    right: 0;

    display: block;
    overflow: hidden;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--grey-color);
    background: #FFFFFF;

    font-size: 0;
    color: transparent;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > .selector > .icon-status::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 7px;

    display: block;
    width: 2px;
    height: 8px;
    background: var(--site-color);
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > .selector > .icon-status::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 4px;


    display: block;
    width: 8px;
    height: 2px;
    background: var(--site-color);
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option > .details > .price {
    display: block;
    margin: 5px auto 0;
    color: var(--text-color);
    font-weight: bold;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option:hover > .details > .title {
    /*text-decoration: underline;*/
    color: var(--site-color-hover);
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option.selected > .selector > .icon-option {
    opacity: 1;
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option.selected > .selector > .icon-status {
    width: 15px;
    height: 15px;
    background: no-repeat center center;
    /*background: url(/static/media/ico-check-green.e75ce815.svg) no-repeat center center;*/
    border-color: var(--green-color);
}

.room-options-visor > .room-options-list > .room-option-wrapper > .room-option.selected > .selector > .icon-status::before,
.room-options-visor > .room-options-list > .room-option-wrapper > .room-option.selected > .selector > .icon-status::after {
    display: none;
}

/** END ROOM OPTIONS VISOR **/

/** ROOM FEATURES LIST **/
.features-list,
.features-list > li {
    display: block;
    list-style: none;
    padding: 0;
}

.features-list > li {
    position: relative;
    min-height: 24px;
    margin: 5px 0;
    padding-left: 20px;
    line-height: 24px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px;
}

/** END ROOM FEATURES LIST **/

/** ROOM UP VISOR **/
.room-up-visor {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    margin: 0 -10px;
    padding: 0 25px;
}

.room-up-visor::before,
.room-up-visor::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;

    display: block;
    width: 25px;
    height: 100%;
}

.room-up-visor::before {
    left: 0;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%);
}

.room-up-visor::after {
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, #FFFFFF 100%);
}

/* prev/next buttons */
.room-up-visor > .prev,
.room-up-visor > .next {
    position: absolute;
    z-index: 3;
    top: 50%;

    display: block;
    overflow: hidden;
    width: 24px;
    height: 24px;
    margin: -12px 0 auto;
    padding: 0;

    font-size: 0;
    line-height: 0;
    color: transparent !important;

    opacity: 0.8;
}

.room-up-visor > .prev {
    left: 0;
}

.room-up-visor > .next {
    right: 0;
}

.room-up-visor > .prev > svg,
.room-up-visor > .next > svg {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.room-up-visor > .prev > svg {
    transform: rotate(180deg);
}

.room-up-visor > .prev:hover,
.room-up-visor > .prev:focus,
.room-up-visor > .next:hover,
.room-up-visor > .next:focus {
    opacity: 1;
}

.room-up-visor > .prev.disabled,
.room-up-visor > .next.disabled {
    opacity: 0.2 !important;
    cursor: default !important;
}

/* up elements list */
.room-up-visor > .room-up-list {
    position: relative;
    z-index: 1;
    left: 0;

    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transition: left 0.5s;

    font-size: 0;
    line-height: 0;
}

.room-up-visor > .room-up-list > .room-up-element {
    display: inline-block;
    vertical-align: top;
    width: auto;
    max-width: 80px;
    margin: 0;
    padding: 0 6px;

    white-space: normal;
    text-align: left;
    font-size: 12px;
    line-height: 1.4em;
}

.room-up-visor > .room-up-list > .room-up-element > img {
    display: block;
    margin: 0 auto 5px;
}

/** END ROOM UP VISOR **/

/** MULTIROOM HEADER **/
.multiroom-header {
    display: flex;
    overflow: auto;
    margin: 0 -20px 15px;
    padding: 0 0 10px;
    white-space: nowrap;

    color: var(--site-color);
    font-family: var(--site-primary-title-font);
}

.multiroom-header .room-div {
    padding: 0 20px;
}

.multiroom-header .room-div.finished,
.multiroom-header .room-div + .room-div {
    position: relative;
    padding-left: 30px;
}

.multiroom-header .room-div.finished::before,
.multiroom-header .room-div + .room-div:not(.finished)::before,
.multiroom-header .room-div + .room-div:not(.finished)::after {
    content: '';
    position: absolute;
    left: 0;

    display: block;
    width: 9px;
    height: 1px;

    background-color: var(--site-color);
}

.multiroom-header .room-div + .room-div:not(.finished)::before {
    top: 20px;
    transform: rotate(45deg);
}

.multiroom-header .room-div + .room-div:not(.finished)::after {
    top: 25px;
    transform: rotate(-45deg);
}

.multiroom-header .room-div.finished::before {
    top: 14px;

    width: 22px;
    height: 22px;
}

.multiroom-header .room-div.finished:first-child {
    padding-left: 55px;
}

.multiroom-header .room-div.finished:first-child::before {
    left: 20px;
}

.multiroom-header .room-div:not(.active) {
    opacity: 0.3;
}

.multiroom-header .room-div .title {
    font-family: var(--site-title-font);
    font-size: 2em;
    font-weight: normal;
}

/** END MULTIROOM HEADER **/

/** PROCESS CART BOX **/
.fixed-cart {
    display: none;
}

.process-cart {
    display: block;
    margin: 0 auto 20px;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid var(--site-color-light);
}

.process-cart > .close-btn {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;

    display: none;
    text-align: center;
    width: 100%;
    height: 100vh;

    background: rgba(43, 43, 43, 0.6);
}

.checkout .checkout-content .checkout-elements > .process-cart > .close-btn,
.checkout-aside > .process-cart > .close-btn,
.success-aside > .process-cart > .close-btn {
    display: none !important;
}

.process-cart > .close-btn > .close-icon {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 25px auto 0;
    padding: 35px 0 0;

    font-size: 16px;
    color: #FFFFFF;
}

.process-cart > .close-btn > .close-icon::before,
.process-cart > .close-btn > .close-icon::after {
    height: 25px;
    background-color: #FFFFFF;
}

/* header */
.process-cart div.cart-content .dropdown-cart-header,
.process-cart > .process-cart-header {
    position: relative;
    display: block;
    margin: 0;
    padding: 17px 20px 20px;
    border-bottom: 1px solid var(--site-color-light);
    cursor: pointer;
}

.process-cart div.cart-content .dropdown-cart-header.only-mobile {
    display: none;
}

/*.process-cart div.cart-content .dropdown-cart-header::after,*/
.process-cart > .process-cart-header::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;

    display: block;
    width: 8px;
    height: 5px;
    margin: -3px 0 0;

    box-sizing: border-box;
    border-style: solid;
    border-color: transparent transparent var(--site-color) transparent;
    border-width: 0 4px 4px 4px;

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.process-cart > .process-cart-header .price,
.process-cart > .process-cart-header .mobile-price-info {
    display: none;
}

.process-cart > .process-cart-header .mobile-price-info p + p {
    margin-top: 5px;
}

/* success process-cart */
.success-aside > .process-cart > .process-cart-header {
    cursor: default;
}

.success-aside > .process-cart > .process-cart-header::after {
    display: none !important;
}

.success-aside > .process-cart .process-cart-content > dl.cart-content,
.process-cart div.cart-content {
    display: block !important;
}

.success-aside > .process-cart .process-cart-content > .cart-price {
    display: flex !important;
    background: none;
}

/* content */
.process-cart .process-cart-content > dl.cart-content,
.process-cart div.cart-content {
    display: none;
    /*
    overflow: auto;
    max-height: calc(100vh - 300px);
    */
}

.process-cart .process-cart-content > dl.cart-content > dt,
.process-cart .process-cart-content > dl.cart-content > dd,
.process-cart div.cart-content > div,
.process-cart .process-cart-content > .cart-price {
    position: relative;
    display: block;
    margin: 0;
    padding: 17px 20px 20px;
}

.process-cart .process-cart-content > .cart-price > * {
    flex: 100% 1;
}

.process-cart .process-cart-content > .cart-price,
.process-cart .process-cart-content > dl.cart-content > dd + dt,
.process-cart div.cart-content > div.dd + div.dt {
    border-top: 1px solid var(--site-color-light);
}

.process-cart .process-cart-content > dl.cart-content > dt,
.process-cart div.cart-content > div.dt,
dl.can-close > dt {
    position: relative;
    cursor: pointer;
}

.process-cart .process-cart-content > dl.cart-content > dt::after,
.process-cart div.cart-content > div.dt::after,
dl.can-close > dt::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;

    display: block;
    width: 8px;
    height: 5px;
    margin: -2px 0 0;

    box-sizing: border-box;
    border-style: solid;
    border-color: transparent transparent var(--site-color) transparent;
    border-width: 0 4px 4px 4px;

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.process-cart .process-cart-content > dl.cart-content > dt:not(.opened)::after,
.process-cart div.cart-content > div.dt:not(.opened)::after,
dl.can-close > dt.closed::after {
    transform: rotate(-180deg);
}

.process-cart .process-cart-content > dl.cart-content > dt + dd {
    display: none;
    padding-top: 0;
}

/* cart room */
.process-cart .process-cart-content > dl.cart-content > dd.rooms-list > * + *,
.process-cart div.cart-content > div.rooms-list > * + * {
    display: block;
    margin-top: 20px;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-header,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header {
    position: relative;
    margin-bottom: 10px;
}

.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header {
    border-bottom: 1px solid var(--site-color-light);
    padding-bottom: 15px;
    cursor:pointer;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-header > *,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header > * {
    display: inline-block;
    padding-right: 20px;
}

.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header > p {
    float:right;
    color: var(--text-color-light);
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-header > *:last-child,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header > *:last-child {
    padding-right: 0;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-header > .price,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header > .price {
    float: right;
    text-align: right;
}

.process-cart .price > .old,
.fixed-cart .price > .old,
.process-cart-content .price > .old {
    display: inline-block;
    margin: 0.2em 5px 0 0;
    padding: 0;
    text-decoration: line-through;
    color: var(--text-color-light);
}

.process-cart .price > .final,
.fixed-cart .price > .final {
    display: inline-block;
    margin: 0;
    padding: 0;

    font-size: 1.35em;
    font-weight: bold;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-header > .remove-room,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-header > .remove-room {
    position: absolute;
    top: 3px;
    right: 0;
    width: 10px;
    height: 10px;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info {
    color: var(--text-color-light);
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info .extra-list,
.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info .extra-list > li,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info .extra-list,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info .extra-list > li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info p,
.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info .extra-list > li,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info p,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info .extra-list > li {
    margin-bottom: 2px;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info .extra-list > li > *,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info .extra-list > li > * {
    display: inline-block;
}

.process-cart .process-cart-content > dl.cart-content > dd.rooms-list .cart-room > .cart-room-info .extra-list > li > .price,
.process-cart div.cart-content > div.rooms-list .cart-room > .cart-room-info .extra-list > li > .price {
    float: right;
}

/* total price */
.process-cart .process-cart-content > .cart-price {
    display: flex;
    background-color: var(--site-color-light);
    font-family: var(--site-primary-title-font);
}

/*.process-cart > .process-cart-header .price > .final,*/
.process-cart .process-cart-content > .cart-price .price > .final {
    font-size: 1.7em;
}

.process-cart .process-cart-content > .cart-price > *:last-child {
    text-align: right;
}

/* footer */
.final-btn,
.final-btn:not(:disabled):hover,
.final-btn:not(:disabled):focus,
.final-btn:active,
.final-btn:visited {
    display: block;
    width: 100%;
    height: auto;

    margin: 0;
    padding: 18px 20px;
    text-align: center;

    /*
    background: var(--green-color);
    border-color: var(--green-color);
    */
    border-color: var(--site-color-button);
    background-color: var(--site-color-button);

    color: #FFFFFF;
    font-size: 1.35em;
    text-decoration: none;
    transition: background-color 0.5s linear;
}

.final-btn:not(:disabled):hover,
.final-btn:not(:disabled):focus {
    /*
    background: var(--green-color);
    border-color: var(--green-color);
    */
    border-color: var(--site-color-hover);
    background-color: var(--site-color-hover);
}

.final-btn > svg {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
}

/** END PROCESS CART BOX **/

/** MOTOR INFO BANNER **/
.motor-info-banner {
    display: block;
    margin: 0 auto 40px;
    padding: 10px 0;

    border: 1px solid var(--site-border-color);

    color: var(--site-color);
}

@media only screen and (max-width:751px){
    .motor-info-banner > * {
        display: flex;
        align-items: center;

        padding: 3px 13px;
    }
}

@media only screen and (min-width:752px){
    .motor-info-banner > * {
        display: block;
        align-items: center;
        padding: 3px 13px;
        text-align:center;
    }
}

.motor-info-banner .ico-before,
.motor-info-banner svg {
    display: inline-block;
    vertical-align: middle;

    width: 30px;
    height: 30px;
    margin-right: 5px;
    color: var(--site-color);
}

.motor-info-banner .ico-before + *,
.motor-info-banner svg + * {
    max-width: calc(100% - 40px);
}

/** END MOTOR INDO BANNER **/

/** HOTEL ROOM CARD **/
.room-card {
    position: relative;
    margin: 0 0 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid var(--site-border-color);
}

.room-card:last-child {
    margin-bottom: 20px;
}

.room-card.selected,
.room-card.up-room.selected {
    border-color: var(--green-color);
}

.room-card.selected::before {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;

    display: block;
    margin: 0;
    padding: 5px 12px;
    background: var(--green-color-light);

    color: var(--green-color);
    font-weight: bold;
}

/* room label */
.room-card > .room-label {
    position: absolute;
    z-index: 3;
    top: 12px;
    left: 12px;

    display: block;
    padding: 6px 13px 6px 30px;
    background: #FFFFFF;
    border-radius: 15px;

    font-size: 10px;

    background-position: left top;
    background-size: 30px;
    background-repeat: no-repeat;
}

/* header info */
.room-card .room-card-header .room-info {
    padding: 30px 14px 20px;
}

.room-card .room-card-header .room-info > * + * {
    display: block;
    margin: 10px 0 0;
}

.room-card .room-card-header .room-info > button {
    min-width: 0;
    margin-top: 50px;

    box-shadow: none;
    background-color: transparent;

    color: var(--site-color-button);
    transition: background-color 0.5s linear;
}

.room-card .room-card-header .room-info > button:hover,
.room-card .room-card-header .room-info > button:focus {
    color: var(--site-color-hover) !important;
}

.room-card .room-card-header .room-info .description {
    margin: 5px 0 20px;
    color: var(--grey-color);
}

.room-card .room-card-header .features-list {
    column-count: 2;
}

.room-card .room-card-header .room-up-wrapper > .title,
.room-card .room-config .pension-up-wrapper > .title {
    display: block;
    margin-bottom: 1em;
}

/* room config */
.room-card .room-config > .title-row > *,
.room-card .room-config > .room-pensions dt,
.room-card .room-config > .room-pensions dd > *,
.room-card .room-config > .toggle-pensions,
.room-card .room-config .room-options {
    display: block;
    margin: 0;
    padding: 15px 1em 13px;

    border-top: 1px solid var(--site-border-color);
}

.room-card .room-config > .room-pensions > * {
    position: relative;
}

.room-card .room-config > .room-pensions > *::after {
    content: '';
    display: block;
    clear: both;
}

.room-card .room-config > .room-pensions > * > button {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;

    border: none;
    box-shadow: none;
    background: none;

    text-align: left;
    color: currentColor;
}

/* UP image slider */
.room-card .room-config > .room-pensions .btn-up {
    margin: 12px 1em 0;
}

.room-card .room-config > .room-pensions > * > .btn-up {
    float: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 15px 12px 5px;

    border: none;
    background: #FFF;

    color: var(--site-color-up);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 1.35em;
}

.room-card .room-config > .room-pensions dd  + .btn-up {
    border-top: 1px solid var(--site-border-color);
}

.room-card .room-config > .room-pensions .pension-up-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 1px;
    background: var(--site-border-color);
}

.room-card .room-config > .room-pensions .pension-up-wrapper .title {
    margin: 0;
    padding: 0px 12px 13px;
    background: #FFF;

    color: var(--grey-color);
    font-weight: 400;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .title + div,
.room-card .room-config > .room-pensions .pension-up-wrapper .sc-bdVaJa,
.room-card .room-config > .room-pensions .pension-up-wrapper .sc-bwzfXH,
.room-card .room-config > .room-pensions .pension-up-wrapper .sc-htpNat {
    margin: 0;
    padding: 0 !important;
    background: #FFF;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-bdVaJa {
    position: relative;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-bwzfXH {
    width: 100%;
    overflow-x: hidden;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-htpNat {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-bxivhb {
    display: inline-block;
    vertical-align: top;

    width: 162.5px;
    flex-shrink: 0;
    margin-right: 10px;
    margin-left: 10px;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .room-up-element {
    margin: 0;
    padding: 0;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-ifAKCX {
    position: absolute;
    height: 100%;
    width: 21px;
    cursor: pointer;
    top: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;

    margin: 0 29px;
    opacity: 0.7;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-ifAKCX.bNXQnk {
    left: -20px;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-ifAKCX.hTCnlQ {
    right: -20px;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-ifAKCX:hover {
    opacity: 1;
}

.room-card .room-config > .room-pensions .pension-up-wrapper .sc-ifAKCX button {
    display: inline-block;
    width: auto;
    min-width: 40px;

    margin: 0;
    padding: 0 12px;
    
    line-height: 40px;

    border: none;
    background: var(--site-color);
    color: #FFF;
}

/* END UP image slider */

.room-card .room-config > .title-row > *:not(.title) {
    display: none;
}

.room-card .room-config > .title-row > *:not(.title) .grey-color {
    font-size: 10px;
}

.room-card .room-config > .title-row > .standar-price {
    text-align: right;
}

.room-card .room-config > .room-pensions dt {
    cursor: pointer;
}

.room-card .room-config > .room-pensions > .start_hidden {
    display: none;
}

.room-card .room-config > .room-pensions dd > * {
    padding: 12px 1em 16px;
    background-color: var(--site-color-light);
}

.room-card .room-config > .room-pensions > .pension-up-wrapper {
    padding: 12px 1em 16px;
    text-align: left;
}

.room-card .room-config > .toggle-pensions {
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    text-decoration: none !important;
}

.room-card .room-config > .toggle-pensions > .open-txt,
.room-card .room-config > .toggle-pensions > .close-txt {
    display: inline-block;
    vertical-align: middle;
}

.room-card .room-config > .room-pensions dt::before,
.room-card .room-config > .toggle-pensions::after {
    content: '';
    position: relative;

    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 5px;
    margin: 3px 0 0 5px;

    box-sizing: border-box;
    border-style: solid;
    border-color: transparent transparent var(--site-color) transparent;
    border-width: 0 4px 4px 4px;

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.room-card .room-config > .room-pensions dt:not(.opened)::before,
.room-card .room-config > .toggle-pensions:not(.opened)::after {
    transform: rotate(-180deg);
}

.room-card .room-config > .room-pensions button[aria-expanded="true"] dt::before {
    transform: rotate(0deg);
}

.room-card .room-config > .room-pensions button[aria-expanded="false"] dt::before {
    transform: rotate(-180deg);
}

.room-card .room-config > .room-pensions dd,
.room-card .room-config > .room-pensions > .pension-up-wrapper,
.room-card .room-config > .toggle-pensions:not(.opened) > .close-txt,
.room-card .room-config > .toggle-pensions.opened > .open-txt,
.room-card .room-config > .room-pensions dt > .info:not(.opened) > .more-link > .close-txt,
.room-card .room-config > .room-pensions dt > .info.opened > .more-link > .open-txt {
    display: none;
}

.room-card .room-config > .room-pensions dt > .title {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin-bottom: 5px;
}

.room-card .room-config > .room-pensions dt::before,
.room-card .room-config > .room-pensions dt > .price {
    float: right;
    display: block;
    width: auto;
    margin-top: 5px;
}

.room-card .room-config > .room-pensions dt > .price {
    margin-top: 0;
    font-size: 14px;
}

.room-card .room-config > .room-pensions dt::before {
    margin-top: 10px;
    margin-left: 10px;
}

.room-card .room-config > .room-pensions dt > .info > span {
    /*
    overflow: hidden;
    text-overflow: ellipsis;
    */
    display: inline-block;
    max-width: 100%;
    vertical-align: bottom;

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.room-card .room-config > .room-pensions dt > .info > .more-link {
    display: inline-block;
    vertical-align: bottom;
    width: auto;
    margin: 5px 0 0;
    text-align: left;
    text-decoration: none;
}

/*
.room-card .room-config > .room-pensions dt > .info:not(.opened) > span {
    white-space: nowrap;
    height: 1.3em;
}
*/

/* room pension prices */
/*.room-card .room-config > .room-pensions dd > * {
    text-align: right;
}*/

.room-card .room-config > .room-pensions dd .pension-info {
    margin-bottom: 20px;
    text-align: left;
}

.room-card .room-config > .room-pensions dd .mobile-info {
    display: block;
    margin: 0 0 15px;
    text-align: left;
}

.room-card .room-config > .room-pensions dd .pension-price-info,
.room-card .room-config > .room-pensions dd .btn-select {
    display: inline-block;
    vertical-align: middle;
    width: auto;
}

.room-card .room-config > .room-pensions dd .pension-price-info a {
    text-decoration: none;
}

.room-card .room-config > .room-pensions dd .pension-price-info a svg {
    display: inline-block;
    vertical-align: middle;
}

.room-card .room-config > .room-pensions dd::after,
.room-card .room-config > .room-pensions dd > div::after {
    content: '';
    display: block;
    clear: both;
}

.room-card .room-config > .room-pensions dd .btn-select,
.room-card .room-config > .room-pensions dd .btn-selected {
    margin-left: 10px;
    float: right;
}

.room-card .room-config > .room-pensions dd .btn-select.active > .to-select,
.room-card .room-config > .room-pensions dd .btn-select:not(.active) > .selected {
    display: none;
}

.room-card .room-config > .room-pensions dd .btn-select,
.room-card .room-config > .room-pensions dd .btn-select:active,
.room-card .room-config > .room-pensions dd .btn-select:visited {
    min-width: 10.5em;

    color: var(--site-color);
    background-color: transparent;
    border-color: var(--site-color);
}

.room-card .room-config > .room-pensions dd .pension-club .btn-select,
.room-card .room-config > .room-pensions dd .pension-club .btn-select:active,
.room-card .room-config > .room-pensions dd .pension-club .btn-select:visited {
    color: #FFFFFF;
    background-color: var(--site-color-button);
    border-color: var(--site-color-button);
    transition: background-color 0.5s linear;
}

.room-card .room-config > .room-pensions dd .btn-select:not(:disabled):hover,
.room-card .room-config > .room-pensions dd .btn-select:not(:disabled):focus,
.room-card .room-config > .room-pensions dd .btn-select.active:not(:disabled):hover,
.room-card .room-config > .room-pensions dd .btn-select.active:not(:disabled):focus {
    color: #FFFFFF;
    background-color: var(--site-color-hover);
    border-color: var(--site-color-hover);
}

.room-card .room-config > .room-pensions dd .pension-price-info .original-price {
    display: block;
    font-family: var(--title-font);
    color: var(--grey-color);
    text-decoration: line-through;
    margin: 0;
}

.room-card .room-config > .room-pensions dd .pension-price-info .pension-price {
    display: block;
    font-size: 1.3em;
}

.room-card .room-config > .room-pensions dd .pension-price-info .pension-price + * {
    display: block;
    margin-top: 3px;
}

/* room options */
.room-card .room-config .room-options {
    display: none;
    background-color: var(--grey-color-light);
}

.room-card .room-config .room-options > .title {
    margin-bottom: 1em;
}

/* changes for UP room */
/*
.room-card.up-room,
.room-card.up-room .room-config > .title-row > *,
.room-card.up-room .room-config > .room-pensions > dt,
.room-card.up-room .room-config > .room-pensions > dd > *,
.room-card.up-room .room-config > .toggle-pensions,
.room-card.up-room .room-config .room-options,
.room-card.up-room p.ico-info:not([class*='-color']):not(.btn-green)::before {
    border-color: var(--site-color-up);
}
*/

.room-card.up-room h2,
.room-card.up-room .h2,
.room-card.up-room h3,
.room-card.up-room .h3,
.room-card.up-room a:not([class*='-color']):not(.btn-green),
.room-card.up-room p.ico-info:not([class*='-color']):not(.btn-green)::before,
.room-card.up-room .room-config > .room-pensions > dd > *,
.room-card.up-room .room-options-visor > .room-options-list > .room-option-wrapper > .room-option:hover > .details > .title {
    color: var(--site-color-up);
}

.room-card.up-room .standar-price * + a,
.room-card.up-room .club-price * + a{
    margin-left: 7px;
    vertical-align: middle;
}

.room-card.up-room .standar-price a:not([class*='-color']):not(.btn-green),
.room-card.up-room .club-price a:not([class*='-color']):not(.btn-green),
.room-card.up-room .popup-overlay h2,
.room-card.up-room .popup-overlay .h2,
.room-card.up-room .popup-overlay h3,
.room-card.up-room .popup-overlay .h3 {
    color: var(--site-color);
}

.room-card.up-room .room-config > .room-pensions > dt::before,
.room-card.up-room .room-config > .toggle-pensions::after {
    border-color: transparent transparent var(--site-color-up) transparent
}

/** END HOTEL ROOM CARD **/

/** HEADER STYLE **/
#motor_header {
    border-bottom: 1px solid var(--grey-color-light);
}

#motor_header .container {
    text-align: center;
    padding-top: 13px;
    padding-bottom: 13px;
    font-family: var(--site-primary-title-font);
    line-height: 24px;
}

#motor_header .container::after {
    content: '';
    display: block;
    clear: both;
}

#motor_header a, #motor_header a:active, #motor_header a:visited {
    text-decoration: none;
}

#motor_header a:hover, #motor_header a:focus {
    /*text-decoration: underline;*/
    color: var(--site-color-hover);
}

@media only screen and (max-width:450px){
    #motor_header .container > a {
        float:left;
    }
}

#motor_header .go-hotel {
    position: relative;
    float: left;
    padding: 2px 0 2px 35px;
}

#motor_header .go-hotel::before,
#motor_header .go-hotel::after {
    content: '';

    position: absolute;
    left: 10px;

    display: block;
    width: 10px;
    height: 1px;
    background: var(--site-color);
}

#motor_header .go-hotel::before {
    top: calc(50% - 4px);
    transform: rotate(-45deg);
}

#motor_header .go-hotel::after {
    top: calc(50% + 3px);
    transform: rotate(45deg);
}

#motor_header .header-logo-wrapper,
#motor_header .header-logo {
    display: inline-block;
    margin: 0 auto;
}

#motor_header .header-logo {
    width: auto;
    height: 24px;
}

#motor-header .header-logo.logo-gf {
    height:35px;
}

#motor_header .header-logo.spring-logo {
    height: 35px;
    margin-top: -15px;
    margin-bottom: -15px;
}

#motor_header .right-nav {
    float: right;
}

#motor_header .right-nav > ul,
#motor_header .right-nav > ul > li{
    z-index: 1;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
}

#motor_header .right-nav > ul > li + li {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid var(--site-border-color);
}

#motor_header .right-nav .mail-icon > *:first-child {font-size:22px;}

#motor_header .right-nav .mail-icon #screensharing{font-size:13px;}

#motor_header .right-nav a.phone-icon,
#motor_header .right-nav a.mail-icon,
#motor_header .right-nav a.user-icon {
    text-decoration: none !important;
}

#motor_header .right-nav a.phone-icon > *,
#motor_header .right-nav a.mail-icon > *,
#motor_header .right-nav a.user-icon > * {
    display: inline-block;
    vertical-align: middle;
}

#motor_header .agency-no-dispo {
    z-index: 999999999999999;
    position: sticky;
    background: white;
    opacity: 1;
    padding: 5px;
}
/** END HEADER STYLE **/

/** CONTENT STYLE **/
#motor_main {
    position: relative;
}

/** END CONTENT STYLE **/

/** FOOTER STYLE **/
#motor_footer {
    margin: 25px 0 0;
    padding: 30px 0 0;
    border-top: 1px solid var(--grey-color);
}

#motor_footer a,
#motor_footer a:active,
#motor_footer a:visited {
    font-family: var(--site-font);
    color: var(--text-color-light);
    text-decoration: none;
}

#motor_footer a:hover,
#motor_footer a:focus {
    color: var(--site-color-hover);
}

#motor_footer .select-lang > .current-lang:not(:hover):not(:focus)::after {
    border-color: transparent transparent var(--text-color) transparent;
}

/* pre/sub footer */
#motor_footer .pre-footer {
    margin: 0;
    padding: 0 0 24px;
    border-bottom: 1px solid var(--grey-color);
}

#motor_footer .sub-footer {
    margin: 0;
    padding: 24px 0;
    border-top: 1px solid var(--grey-color);
    background: var(--grey-color-light);
}

#motor_footer .pre-footer .container::after,
#motor_footer .sub-footer .container::after {
    content: '';
    display: block;
    clear: both;
}

#motor_footer .pre-footer .container > *,
#motor_footer .sub-footer .container > * {
    display: inline-block;
    vertical-align: middle;
}

#motor_footer .pre-footer .container > .select-lang,
#motor_footer .sub-footer .container > .select-lang {
    float: right;
}

#motor_footer .pre-footer .container > .select-lang {
    margin-top: 18px;
}

#motor_footer .sub-footer .container > .select-lang {
    display: none;
}

#motor_footer .pre-footer .container > .select-lang .current-lang,
#motor_footer .sub-footer .container > .select-lang .current-lang {
    padding-left: 30px;
    background: no-repeat left center;
    /*background: url(/static/media/ico-worald.d8888482.svg) no-repeat left center;*/
}

#motor_footer .pre-footer .container > .select-lang .lang-options {
    right: 0;
}

#motor_footer .sub-footer .container > .select-lang .lang-options {
    position: relative;
    float: right;
    background: transparent;
}

#motor_footer .sub-footer .container > .copyrigt {
    font-size: 16px;
    line-height: 26px;
    color: var(--text-color-light);
}

/* footer nav */
#motor_footer .footer-nav .group-logo-wrapper {
    display: none;
}

#motor_footer .footer-nav .col-nav .nav-title,
#motor_footer .footer-nav .col-nav ul {
    position: relative;
    display: block;
    padding-top: 16px;
    padding-bottom: 16px;
    list-style: none;
}

#motor_footer .footer-nav .col-nav .nav-title {
    cursor: pointer;

    font-size: 20px;
    font-family: var(--site-primary-title-font);
    color: var(--text-color);
}

#motor_footer .footer-nav .col-nav .nav-title::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;

    display: block;
    width: 8px;
    height: 5px;
    margin: -3px 0 0;

    box-sizing: border-box;
    border-left: 4px solid transparent;
    border-bottom: 4px solid var(--site-color);
    border-right: 4px solid transparent;
    border-top: 0 solid transparent;

    transform: rotate(-180deg);
    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

#motor_footer .footer-nav .col-nav.opened .nav-title::after {
    transform: rotate(0deg);
}

#motor_footer .footer-nav .col-nav ul {
    display: none;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    background: var(--grey-color-light);
}

#motor_footer .footer-nav .col-nav ul > li {
    display: block;
    padding-top: 16px;
    padding-bottom: 4px;
    list-style: none
}

#motor_footer .footer-nav .col-nav ul > li:first-child {
    padding-top: 8px;
}

#motor_footer .footer-nav .col-nav ul > li:last-child {
    padding-bottom: 20px;
}

@media only screen and (max-width:1099px){
    #motor_footer .footer-nav .rrss {
        margin-top:20px;
    }
}

#motor_footer .footer-nav .rrss ul {
    display:flex;
    padding:0px;
    justify-content: center;
}

#motor_footer .footer-nav .rrss ul li {
    display:inline;
    padding-left:10px;
    padding-right:10px;
    padding-top:0;
    font-size: 16px;
    text-align:center;
}
/** END FOOTER STYLE **/

/** SEARCH RESULT PAGE **/
/* search bar */
.current-search-bar {
    position: sticky;
    z-index: 5;
    top: 0;

    margin-bottom: 20px;
    padding: 20px 0;
}

@media only screen and (max-width: 740px) {
    .current-search-bar {
        position: sticky;
        z-index: 5;
        top: 0;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom:0;
    }
}

.current-search-bar .current-search {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 60px);
    padding-left: 8px;
}

.current-search-bar .current-search + .search-btn {
    margin-left: 25px;
}

.current-search-bar .current-search .title {
    font-size: 12px;
    font-weight: normal;
}

.current-search-bar .current-search .search-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.current-search-bar #breadcrumb-mobile {
    margin-top: 20px;
    padding-top: 0;
    padding-bottom: 5px;
    background-color:white;
    display:none;
}

@media only screen and (max-width: 740px) {
    .current-search-bar #breadcrumb-mobile {display:block;}
}

/* searc result header */
.search-result .search-result-header > * {
    margin-bottom: 20px;
}

/* hotel banner */
.hotel-banner {
    padding: 15px 20px 20px;
    background-color: var(--site-color-light);
}

.hotel-banner > .img-wrapper {
    display: block;
    margin: 0;
    padding: 10px 0 0;
}

.hotel-banner > .img-wrapper img {
    margin-left: 0;
}

.hotel-banner > img {
    margin-top: 10px;
}

.hotel-banner > .info-wrapper {
    padding-top: 16px;
    color: var(--text-color-light);
}

.hotel-banner > .info-wrapper > .short-info > p {
    display: inline;
}

.hotel-banner > .info-wrapper > .short-info > .open-facilities {
    display: inline-block;
    margin-left: 5px;
}

.hotel-banner > .info-wrapper > .large-info {
    display: none;
}

.hotel-banner > .info-wrapper > .large-info > * + * {
    margin-top: 1.5em;
}

.hotel-banner > .info-wrapper > .large-info .hotel-facilities {
    color: var(--text-color);
}

.hotel-banner > .info-wrapper > .large-info .hotel-facilities-list,
.hotel-banner > .info-wrapper > .large-info .hotel-facilities-list > li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.hotel-banner > .info-wrapper > .large-info .hotel-facilities-list {
    margin: 0 -10px 10px;
}

.hotel-banner > .info-wrapper > .large-info .hotel-facilities-list > li {
    display: inline-block;
    vertical-align: top;
    max-width: 72px;
    padding: 10px;
}

.hotel-banner > .info-wrapper > .large-info .hotel-facilities-list > li > * + * {
    margin-top: 8px;
}

.hotel-banner > .info-wrapper > .large-info > a + a::before,
.search-result-filters > .button-options > a + a::before {
    content: '';
    display: inline-block;
    height: 1em;
    vertical-align: middle;
    margin: 0 0 0 1em !important;
    padding: 0 1em 0 0 !important;
    border-left: 1px solid var(--site-border-color);
}

/* search result filters */
.search-result .search-result-header > .search-result-filters {
    overflow: visible;
    margin: 10px auto 20px;
}

.search-result-filters .title,
.search-result-filters > .button-options {
    font-family: var(--site-primary-title-font);
}

.search-result-filters > .title {
    float: left;
}

.search-result-filters > .button-options {
    float: right;
}

.search-result-filters.opened .toggle-filters > .open-txt,
.search-result-filters:not(.opened) .toggle-filters > .close-txt {
    display: none;
}

.search-result-filters .filters-list {
    overflow: auto;
    white-space: nowrap;

    display: block;
    clear: both;
    margin: 0 -20px;
    padding: 18px 20px;
    list-style: none;
}

.search-result-filters .filters-list > li {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0 6px;
    list-style: none;
    white-space: normal;
}

.search-result-filters .more-filters {
    display: none;
}

.search-result-filters .more-filters > .title {
    padding-top: 5px;
}

/* search result show options */
.show-options {
    margin: 0 0 20px;
}

.show-options::after {
    content: '';
    display: block;
    clear: both;
}

.show-options > .order-option {
    float: left;
}

.show-options > .price-unit {
    float: right;
}

.show-options label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;

    font-family: var(--site-primary-title-font);
    font-weight: normal;
}

.show-options select, .show-options select:hover, .show-options select:focus {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    border: none;
    padding: 0;

    font-weight: bold;
}

/* NEW FILTERS */
.search-result .filter-box .title {
    margin-bottom: 0.5em;
}

.search-result .filter-box .filter-buttons {
    display: block;
    max-width: calc(100% + 8px);
    margin: 0 -4px 2em;
    white-space: nowrap;
    overflow: auto;
    text-align: left;
}

.search-result .filter-box .filter-buttons::-webkit-scrollbar {
    height: 5px;
    border-radius: 0;
}

.search-result .filter-box .filter-buttons::-webkit-scrollbar-track {
    margin: 0 4px;
    border-radius: 0;
    background: #FFFFFF;
}

.search-result .filter-box .filter-buttons::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: var(--site-color-light);
}

.search-result .filter-box .filter-buttons::-webkit-scrollbar-thumb:hover {
    background: var(--site-border-color);
}

.search-result .filter-box .filter-buttons .filter-button,
.search-result .filter-box .filter-buttons .filter-button:hover,
.search-result .filter-box .filter-buttons .filter-button:focus,
.search-result .filter-box .filter-buttons .filter-button:active,
.search-result .filter-box .filter-buttons .filter-button.active,
.search-result .filter-box .filter-buttons .filter-button:visited {
    display: inline-block;
    vertical-align: top;
    margin: 0 4px 8px;
    padding: 12px;
    cursor: pointer;
    white-space: normal;

    border-radius: 0;
    border: 1px solid var(--site-color-light);
    background: var(--site-color-light);
    box-shadow: none;

    color: var(--text-color);
    font-family: var(--text-font);
    font-size: 1em;
    line-height: 1em;
    text-align: left;
    text-decoration: none;
}

.search-result .filter-box .filter-buttons .filter-button:hover,
.search-result .filter-box .filter-buttons .filter-button:focus,
.search-result .filter-box .filter-buttons .filter-button:active,
.search-result .filter-box .filter-buttons .filter-button.active {
    border-color: var(--site-color);
}

.search-result .filter-box .filter-buttons .filter-button strong {
    color: var(--site-color);
}

.search-result .filter-box .filter-buttons .filter-button .price {
    display: block;
    margin: 5px 0 0;
    font-weight: normal;
    font-family: var(--site-primary-title-font);
}

.search-result .filter-box .filter-buttons .filter-button .price strong {
    color: currentColor;
    font-weight: bold;
}

/** END SEARCH RESULT PAGE **/

/** SEARCH BOX **/
#motor_search_box {
    position: relative;

    display: block;
    margin: 0 auto;
    padding: 0;

    background: #FFFFFF;
}

/* header */
#motor_search_box .search-header {
    position: relative;

    display: block;
    margin: 0 auto;
    padding: 96px 20px 40px;

    text-align: center;
    font-size: 16px;

    background: var(--site-color-light);
}

#motor_search_box .search-header .motor-search-close {
    position: absolute;
    top: 40px;
    right: 40px;

    width: 24px;
    height: 24px;
}

#motor_search_box .search-header-content h2,
#motor_search_box .search-header-content .h2 {
    font-weight: normal;
    margin-bottom: 5px;
}

/* header in popup */
.dialog-message #motor_search_box .search-header {
    max-width: 480px;
    margin: 0 auto;
    padding: 30px 20px 0;
    background: none;
}

.dialog-message #motor_search_box .search-header-content h2,
.dialog-message #motor_search_box .search-header-content .h2 {
    font-family: var(--site-title-font);
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 0.5em !important;
}

.dialog-message #motor_search_box .search-header-content img,
.dialog-message #motor_search_box .search-header-content img + br {
    display: none !important;
}

/* content */
#motor_search_box .search-content {
    position: relative;

    display: block;
    max-width: 480px;
    margin: 0 auto;
    padding: 30px 20px;
}

.dialog-message #motor_search_box .search-header + .search-content {
    padding-top: 0;
}

#motor_search_box .search-content .h2 {
    text-align: center;
    margin-bottom: 0.5em;
    font-size: 25px;
}

#motor_search_box .search-content .room-config .room-title {
    margin: 15px 0 5px;
    font-size: 16px;
    color: var(--text-color-light);
}

#motor_search_box .search-content .room-config:first-child .room-title {
    margin-top: 15px;
}

#motor_search_box .search-content input[type="checkbox"] + label {
    font-size: 14px;
}

#motor_search_box .search-content .info-banner {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto 18px;
    padding: 8px 12px;
    background-color: var(--site-color-light);
    color: var(--site-color);
}

#motor_search_box .search-content .info-banner > .ico-before {
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: 10px;
}

#motor_search_box .search-content .info-banner > .close-icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    margin-left: 12px;
}

/** END SEARCH BOX **/

/** CHECKOUT/SUCCESS PAGE **/
.checkout .checkout-header {
    text-align: center;
    margin: 24px auto 20px;
}

.checkout .checkout-content .checkout-elements::after,
.success .success-summary .success-elements::after {
    content: '';
    display: block;
    clear: both;
}

.checkout .checkout-content .checkout-elements > * {
    margin: 0 auto;
}

.success .success-summary .success-elements > * {
    margin: 0 auto 40px;
}

.checkout .checkout-content > * + *,
.checkout .checkout-content .checkout-elements .payment-data,
.checkout .checkout-content .checkout-elements .spring-club-banner {
    margin-top: 40px;
}

.success .success-summary .success-elements > *:last-child {
    margin-bottom: 20px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > *:not(button):not(.spring-club-banner),
.success .success-summary .success-elements dl.success-box {
    background-color: var(--site-color-light);
    border: 1px solid var(--site-border-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box > *:not(dt) {
    border: none !important;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box > dt {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box:not(.payment-data),
.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box > dt,
.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box + dd {
    border-top: none !important;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box {
    border-bottom: none !important;
}

.checkout .checkout-content .checkout-elements dl.checkout-box .column-inputs .header {
    padding-top: 20px;
}

.success .success-summary .success-elements dl.success-box {
    padding: 0;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > .checkout-box {
    border: none;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt,
.success .success-summary .success-elements dl.success-box > dt {
    position: relative;
    padding: 25px 20px 20px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd:not(.plegable),
.checkout .checkout-content .checkout-elements dl.checkout-box > dd:not(.plegable),
.checkout .checkout-content .checkout-elements dl.checkout-box > dd.plegable > * {
    padding: 20px;
}

/** END CHECKOUT/SUCCESS PAGE **/
/** ONLY CHECKOUT PAGE **/
.checkout .checkout-content .checkout-elements .header > h3,
.checkout .checkout-content .checkout-elements .header > .h3 {
    margin: 0 auto 5px;
    font-family: var(--site-primary-title-font);
}

.checkout .checkout-content .checkout-elements .header > a,
.checkout .checkout-content .checkout-elements .header > p {
    margin: 0 auto 20px;
}

.checkout .checkout-content .checkout-elements .header > a {
    display: inline-block;
}

.checkout .checkout-content .checkout-elements .spring-club-banner {
    position: relative;
    overflow: hidden;
    padding: 25px 30px;
    background: var(--site-color-spring) no-repeat center center;
    /*background: var(--site-color-spring) url(/static/media/bg-spring-club.85584ce2.svg) no-repeat center center;*/
    background-size: cover;
    color: #FFFFFF;
}

.checkout .checkout-content .checkout-elements .spring-club-banner .header h3 {
    margin-bottom: 20px;
}

.checkout .checkout-content .checkout-elements .spring-club-banner input::-webkit-input-placeholder { /* Edge */
    color: var(--site-color-spring);
    opacity: 0.7;
}

.checkout .checkout-content .checkout-elements .spring-club-banner input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--site-color-spring);
    opacity: 0.7;
}

.checkout .checkout-content .checkout-elements .spring-club-banner input::placeholder {
    color: var(--site-color-spring);
    opacity: 0.7;
}

.checkout .checkout-content .checkout-elements .spring-club-banner > * {
    position: relative;
    z-index: 2;
}

.checkout .checkout-content .checkout-elements .spring-club-banner > img {
    margin: 0 auto 20px 0;
}

.checkout .checkout-content .checkout-elements .spring-club-banner > .discount-corner {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

    display: block;
    width: 120px;
    height: 120px;
    margin: 0;
    padding: 16px 12px;

    text-align: right;
}

.checkout .checkout-content .checkout-elements .spring-club-banner > .discount-corner::before {
    content: '';
    position: absolute;
    top: -15px;
    right: -70px;
    z-index: 1;

    display: block;
    width: 210px;
    height: 105px;
    margin: 0;
    padding: 0;

    box-sizing: border-box;
    border-style: solid;
    border-color: transparent transparent var(--site-color-spring) transparent;
    border-width: 0 105px 105px 105px;
    transform: rotate(45deg);
}

.checkout .checkout-content .checkout-elements .spring-club-banner > .discount-corner > * {
    position: relative;
    z-index: 2;
    display: block;
    margin: 0;
    padding: 0;
}

.checkout .checkout-content .checkout-elements .spring-club-banner > .discount-corner > strong {
    font-size: 30px;
    font-family: var(--site-title-font);
    font-weight: normal;
}

.checkout .checkout-content .checkout-elements .spring-club-banner h3,
.checkout .checkout-content .checkout-elements .spring-club-banner .h3 {
    color: #FFFFFF;
    font-family: var(--site-primary-title-font);
}

.checkout .checkout-content .checkout-elements .spring-club-banner a,
.checkout .checkout-content .checkout-elements .spring-club-banner input[type="checkbox"] + label {
    color: #FFFFFF !important;
}

.checkout .checkout-content .checkout-elements.form-style .spring-club-banner .input-wrapper > label:first-child,
.checkout .checkout-content .checkout-elements.form-style .spring-club-banner .input-wrapper input,
.checkout .checkout-content .checkout-elements.form-style .spring-club-banner .input-wrapper select,
.checkout .checkout-content .checkout-elements.form-style .spring-club-banner .input-wrapper textarea {
    color: var(--site-color-spring);
}

.checkout .checkout-content .checkout-elements .payment-data .form-wrapper img {
    margin: 0 0 20px;
}

.checkout .checkout-content .checkout-elements .secure-text {
    float: right;
    padding-left: 32px;
    background: no-repeat left center;
    /*background: url(/static/media/ico-secure.b839f7c6.svg) no-repeat left center;*/
    color: var(--text-color-light);
    font-family: var(--site-primary-title-font);
}


/*Estilos formulario de pago*/
.MuiBox-root {
    width:100%;
}

.checkout .checkout-content .checkout-elements #resume-passengers dd {
    padding:20px;
}

.checkout .checkout-content .checkout-elements #resume-passengers dd h3 {
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
    color: var(--site-color);
    border-bottom: 1px solid var(--site-border-color);
    margin: 0 0 28px;
    padding: 0 0 10px;
}

.checkout .checkout-content .checkout-elements #resume-passengers dd .fila{
    display:flex;
    margin-bottom:20px;
}

.checkout .checkout-content .checkout-elements #resume-passengers dd .fila > div{
    width:50%;
}

.checkout .checkout-content .checkout-elements #resume-passengers dd .fila > div p:first-child {font-weight:bold;}

#payment-form #pay {margin-top:25px;}

.checkout-content .checkout-box .table-test { margin-top:10px;}

@media only screen and (min-width:701px){
    .checkout-content .checkout-box .table-test {
        width:60%
    }
}

@media only screen and (max-width:700px){
    .checkout-content .checkout-box .table-test {
        width:100%
    }
}
/*Fin estilos formulario de pago*/

/** END CHECKOUT PAGE **/
/** ONLY SUCCESS PAGE **/
.success > * {
    margin-top: 40px;
}

.success .success-header,
.success .success-banner {
    text-align: center;
    padding: 24px 15px;
}

.success .success-header p:not(:last-child),
.success .success-banner p:not(:last-child) {
    margin-bottom: 1em;
}

.success .success-header h1 {
    margin-bottom: 8px;
    font-size: 20px;
    font-family: var(--site-title-font);
    font-weight: normal;
}

.success .success-banner {
    background-color: var(--grey-color);
    background-size: cover;
    background-position: center center;
}

.success .success-banner * {
    color: #FFFFFF;
    border-color: #FFFFFF;
}

.success .success-banner a:not(.btn):hover {
    color: var(--site-color);
}

.success .success-banner button:hover,
.success .success-banner .btn:hover {
    border-color: var(--site-color);
}

/* summary elements */
.success .success-summary .success-elements dl.success-box > dd:not(.comment-wrapper) {
    padding-left: 5px;
    padding-right: 5px;
}

.success .success-summary .success-elements dl.success-box > dd.comment-wrapper {
    padding-bottom: 20px;
}

/** END SUCCESS PAGE **/

/** ONLY FOR MOBILE AND TABLET **/
@media only screen and (max-width: 1099px) {
    /* increase spaces */
    .search-result-filters > .title,
    .search-result-filters > .button-options {
        padding-bottom: 15px;
    }

    .search-result .search-result-header > *,
    .multiroom-header,
    .show-options {
        margin-bottom: 30px;
    }

    /* change process cart in checkout and success */
    .checkout .checkout-content .checkout-elements > .process-cart > .process-cart-header,
    .checkout-aside > .process-cart > .process-cart-header,
    .success-aside > .process-cart > .process-cart-header {
        cursor: default;
    }

    .checkout .checkout-content .checkout-elements > .process-cart > .process-cart-header::after,
    .checkout-aside > .process-cart > .process-cart-header::after,
    .success-aside > .process-cart > .process-cart-header::after,
    .checkout-aside > .process-cart > .process-cart-footer {
        display: none;
    }

    .checkout .checkout-content .checkout-elements > .process-cart .process-cart-content > dl.cart-content,
    .checkout-aside > .process-cart .process-cart-content > dl.cart-content,
    .success-aside > .process-cart .process-cart-content > dl.cart-content,
    .process-cart div.cart-content {
        display: block !important;
        max-height: none;
    }

    /* show checkout fixed cart */
    .fixed-cart {
        position: fixed;
        z-index: 15;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
    }

    .fixed-cart > .price-info {
        display: flex;
        padding: 5px 16px;
        background: #FFFFFF;
        border-top: 1px solid var(--site-border-color);
        font-family: var(--site-primary-title-font);
    }

    .fixed-cart > .price-info > * {
        flex: 1 1;
    }

    .fixed-cart > .price-info > .title {
        font-weight: bold;
        color: var(--site-color);
    }

    .fixed-cart > .price-info > .price {
        text-align: right;
    }

    .fixed-cart.disabled-button > .final-btn {
        display: none;
    }

    /* change modal popups */
    .motor-modal > .motor-modal-close {
        position: absolute;
        bottom: 20px;
        left: calc(50% - 25px);
        z-index: 2;

        width: 50px;
        height: auto;
        margin: 0;
        padding: 40px 0 0;

        text-align: center;
        font-size: 16px;
        color: #FFF;
    }

    .motor-modal > .motor-modal-close::before,
    .motor-modal > .motor-modal-close::after {
        height: 30px;
    }

    .motor-modal > .motor-modal-box {
        width: calc(100% - 40px);
        height: 100%;
        max-height: calc(100% - 220px);
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
    }

    .motor-modal.modal-info-room > .motor-modal-box {
        width: 100%;
        max-height: calc(100% - 120px);
    }

    .motor-modal.modal-info-room > .motor-modal-box > .title,
    .motor-modal.modal-info-room > .motor-modal-box .room-desc .primary {
        font-size: 20px;
    }

    .motor-modal.modal-info-room > .motor-modal-box .room-desc,
    .motor-modal.modal-info-room > .motor-modal-box .features-columns > .features-column {
        padding-bottom: 30px;
    }

    .motor-modal.modal-info-room > .motor-modal-box .room-up-wrapper {
        margin-top: 0;
        padding-bottom: 0;
    }

    /* add scroll for hotel banner facilities */
    .hotel-banner > .info-wrapper > .large-info .hotel-facilities-list {
        overflow: auto;
        white-space: nowrap;
    }

    .hotel-banner > .info-wrapper > .large-info .hotel-facilities-list > li {
        white-space: normal;
    }

    .search-result-aside > .process-cart {
        position: fixed;
        z-index: 100;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        margin: 0;
    }

    .process-cart.empty-price {
        display: none;
    }

    .process-cart > * {
        position: relative;
        z-index: 2;
        background: #FFFFFF;
    }

    .process-cart > .process-cart-header {
        display: flex;
        background: var(--green-color);
        cursor: pointer;
    }

    .process-cart.opened > .process-cart-header::after,
    .process-cart div.cart-content .dropdown-cart-header::after {
        transform: rotate(-180deg);
    }

    .process-cart > .process-cart-header > .price {
        display: block;
        text-align: right;
        padding-right: 20px;
    }

    .process-cart .process-cart-content > dl.cart-content,
    .process-cart div.cart-content {
        overflow: auto;
        max-height: calc(100vh - 220px);
    }

    .process-cart .process-cart-content > .cart-price {
        display: none;
    }

    /* remove form columns not ipad */
    .column-inputs.also-desktop > .input-wrapper {
        width: 100% !important;
    }

    .column-inputs.also-desktop > .input-wrapper + .input-wrapper {
        margin-left: 0 !important;
    }

    .column-inputs.also-desktop > .input-wrapper:not(:last-child) {
        margin-bottom: 0;
    }
}

/** ONLY FOR MOBILE **/
@media only screen and (max-width: 600px) {
    #motor_header .container {
        text-align: left;
        line-height: 20px;
    }

    #motor_header .container .go-hotel {
        font-size: 0;
        color: transparent;
    }

    #motor_header .header-logo {
        height: 20px;
    }

    .motor-modal.modal-info-room > .motor-modal-box .room-up-list > li {
        width: calc(33.33% - 27px);
    }

    /* remove form columns not mobile */
    .column-inputs:not(.also-mobile) > .input-wrapper {
        width: 100% !important;
    }

    .column-inputs:not(.also-mobile) > .input-wrapper + .input-wrapper {
        margin-left: 0 !important;
    }
}

/** ONLY FOR SMALL MOBILE **/
@media only screen and (max-width: 450px) {
    #motor_header .go-hotel + a {
        display: inline-block;
        vertical-align: middle;
    }

    #motor_header .header-logo:not(.spring-logo) {
        height: auto;
        width: 100px;
        margin: 0 0 0 -5px;
        display: block;
    }

    .motor-modal.modal-info-room > .motor-modal-box .room-up-list > li {
        width: calc(50% - 27px);
    }
}

/** ONLY FOR IPHONE MOBILE **/
@media only screen and (max-width: 300px) and (min-width: 250px) {
    .process-cart .process-cart-content > dl.cart-content,
    .process-cart div.cart-content {
        max-height: calc(100vh - 250px);
    }

    .process-cart > .process-cart-footer > .final-btn,
    .process-cart > .process-cart-footer > .final-btn:hover,
    .process-cart > .process-cart-footer > .final-btn:focus,
    .process-cart > .process-cart-footer > .final-btn:active,
    .process-cart > .process-cart-footer > .final-btn:visited,
    .fixed-cart.disabled-button > .price-info,
    .fixed-cart:not(.disabled-button) > .final-btn {
        padding-bottom: 48px;
    }
}

/** CHANGE TO BIG MOBILE **/
@media only screen and (min-width: 500px) {
    /* hotel banner (logo to left) */
    .hotel-banner {
        display: flex;
        align-items: flex-start;
    }

    .hotel-banner > .info-wrapper {
        max-width: calc(100% - 190px);
        padding-top: 0;
        padding-left: 50px;
    }

    /* room pension mobile info (in left) */
    .room-card .room-config > .room-pensions dd {
        text-align: right;
    }

    .room-card .room-config > .room-pensions dd > *::after {
        content: '';
        display: block;
        clear: both;
    }

    .room-card .room-config > .room-pensions dd .mobile-info {
        float: left;
        max-width: calc(100% - 17em);
    }
}



/** CHANGE TO TABLET **/
@media only screen and (min-width: 751px) {
    /* put 2 btns in columns */
    .system-msg .msg-box .text-content .btns-wrapper::after {
        content: '';
        display: block;
        clear: both;
    }

    .system-msg .msg-box .text-content .btns-wrapper > *:nth-child(1):nth-last-child(2) {
        float: left;
    }

    .system-msg .msg-box .text-content .btns-wrapper > *:nth-child(2):nth-last-child(1) {
        float: right;
        margin-top: 0;
    }

    .system-msg .msg-box .text-content .btns-wrapper > .no-btn:nth-child(1):nth-last-child(2),
    .system-msg .msg-box .text-content .btns-wrapper > .no-btn:nth-child(2):nth-last-child(1) {
        margin-top: 12px;
    }

    /** TABLET SEARCH RESULT PAGE **/
    /* motor info banner in columns */
    .motor-info-banner {
        display: flex;
        max-width: none;
    }

    .motor-info-banner > * {
        flex-basis: 100%;
    }

    /* change room info modal popups */
    .motor-modal.modal-info-room > .motor-modal-box .features-columns::after {
        content: '';
        display: block;
        clear: both;
    }

    .motor-modal.modal-info-room > .motor-modal-box .features-columns > .features-column {
        float: left;
        width: calc(50% - 60px);
    }

    /* room card header */
    .room-card .room-card-header {
        display: flex;
    }

    .image-gallery img{
        /* max-height: 360px; */
        height:100%
    }

    .room-card .room-card-header > .img-visor {
        width: 50%;
    }

    .room-card .room-card-header > .room-info {
        width: calc(50% - 28px);
    }

    /* room config */
    .room-card .room-config {
        position: relative;
    }

    .room-card .room-config::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;

        display: block;
        width: 20em;
        height: 100%;
        background: var(--site-color-light);
    }

    .room-card .room-config > * {
        position: relative;
        z-index: 2;
    }

    .room-card .room-config > .room-pensions {
        z-index: 3;
    }

    .room-card .room-config > .room-pensions > .pension-up-wrapper,
    .room-card .room-config > .toggle-pensions {
        background-color: #FFF;
    }

    .room-card .room-config > .title-row::after,
    .room-card .room-config > .room-pensions::after,
    .room-card .room-config > .room-pensions dd::after {
        content: '';
        display: block;
        clear: both;
    }

    .room-card .room-config > .room-pensions > .pension-up-wrapper {
        clear: both;
    }

    .room-card .room-config > .title-row > *,
    .room-card .room-config > .room-pensions > * > dt,
    .room-card .room-config > .room-pensions > * > button {
        float: left;
    }

    .room-card .room-config > .title-row > * {
        width: 18em;
    }

    .room-card .room-config > .title-row > .title,
    .room-card .room-config > .room-pensions > * > dt {
        width: calc(100% - 42em);
    }

    .room-card .room-config > .room-pensions > * > button {
        width: calc(100% - 40em);
    }

    .room-card .room-config > .room-pensions dt,
    .room-card .room-config > .room-pensions > * > button {
        clear: both;
    }

    .room-card .room-config > .room-pensions dt::before,
    .room-card .room-config > .room-pensions dt > .price {
        display: none;
    }

    .room-card .room-config > .title-row > *,
    .room-card .room-config > .room-pensions dt.show_alltime + dd {
        display: block !important;
    }

    .room-card .room-config > .room-pensions dd::after,
    .room-card .room-config > .room-pensions dd > div::after {
        content: '';
        display: block;
        clear: both;
    }

    .room-card .room-config > .room-pensions dd button {
        float: right;
        min-width: 0;
        padding: 1em 1.3em;
        margin-left: 10px;
        min-width: 10.5em;
    }

    .room-card .room-config > .room-pensions dd .popup-overlay button {
        float: none;
    }

    .room-card .room-config	> .room-pensions > * > button + section {
        display: block !important;
		height: auto !important;
    }

    .room-card .room-config > .room-pensions dd,
    .room-card .room-config > .room-pensions > * > button + section {
        float: left;
        width: 40em;
    }

    .room-card .room-config > .room-pensions > * > button > dt {
        width: calc(100% - 2em);
    }

    .room-card .room-config > .room-pensions > * > button + section > dd {
		width: 100%;
	}

    .room-card .room-config > .room-pensions dd > * {
        float: left;
        width: calc(50% - 2em);
        background: none;
    }

    .room-card .room-config > .room-pensions dd .mobile-info {
        display: none;
    }

    /*
    .room-card .room-config > .room-pensions dd > * > .pension-price-info {
        display: flex;
    }

    .room-card .room-config > .room-pensions dd > * > .pension-price-info > .pension-price {
        order: 2;
    }

    .room-card .room-config > .room-pensions dd > * > .pension-price-info > .motor-modal-link {
        order: 1;
        font-size: 0;
        color: transparent;
    }
    */
    /*.room-card .room-config > .room-pensions .pension-up-wrapper {
        float: none;
        clear: both;
        width: calc(150% - 26px);
        margin-left: -50%;
        border-top: none;
    }*/
    /** END TABLET SEARCH RESULT PAGE **/
    /** TABLET SEARCH BOX **/
    #motor_search_box .search-header-content h2.title-font,
    #motor_search_box .search-header-content .h2.title-font {
        font-size: 2em;
    }

    .dialog-message #motor_search_box .search-header h2.title-font,
    .dialog-message #motor_search_box .search-header .h2.title-font {
        font-size: 25px;
    }

    /** END TABLET SEARCH BOX **/
    /** TABLET CHECKOUT PAGE **/
    .checkout .checkout-header {
        margin: 40px auto 58px;
    }

    .checkout .checkout-header h1,
    .checkout .checkout-header .h1,
    .success .success-header h1 {
        font-size: 32px;
    }

    .checkout .checkout-content h2,
    .checkout .checkout-content .h2 {
        font-size: 24px;
    }

    .checkout .checkout-content h3,
    .checkout .checkout-content .h3,
    .success .success-banner h3,
    .success .success-banner .h3 {
        font-size: 20px;
    }

    .checkout .checkout-content .checkout-elements .header > h3,
    .checkout .checkout-content .checkout-elements .header > .h3 {
        display: inline-block;
    }

    .checkout .checkout-content .checkout-elements .header > a {
        float: right;
        margin: 10px 0;
    }

    .checkout .checkout-content .checkout-elements .header > p {
        clear: both;
    }

    .checkout .checkout-content .checkout-elements .payment-data .form-wrapper > img {
        float: right;
        width: 240px;
    }

    .checkout .checkout-content .checkout-elements .payment-data .form-wrapper > img + .text-block {
        max-width: calc(100% - 270px);
    }

    /** END TABLET CHECKOUT PAGE **/
}

/** CHANGE TO DESKTOP **/
@media only screen and (min-width: 1100px) {
    .container,
    .system-msg {
        max-width: 1200px;
    }

    /* desktop disable open/close process cart */
    .process-cart > .close-btn {
        display: none !important;
    }

    .process-cart:not(.opened) > .process-cart-header::after {
        transform: rotate(-180deg);
    }

    /** DESKTOP FOOTER **/
    #motor_footer .pre-footer,
    #motor_footer .footer-nav .col-nav .nav-title::after {
        display: none;
    }

    #motor_footer .footer-nav .group-logo-wrapper,
    #motor_footer .sub-footer .container > .select-lang {
        display: block;
    }

    #motor_footer .footer-nav .col-nav .nav-title {
        cursor: default;
    }

    #motor_footer .footer-nav .col-nav ul {
        display: block !important;
        background: none;
        padding: 0 20px;
    }

    #motor_footer .footer-nav .col-nav ul > li {
        padding-left: 0;
        padding-right: 0;
    }

    /* footer content in columns */
    #motor_footer .footer-nav .desktop-container {
        max-width: 1240px;
        margin: 0 auto 20px;
    }

    #motor_footer .footer-nav .desktop-container::after {
        content: '';
        display: block;
        clear: both;
    }

    #motor_footer .footer-nav .desktop-container > * {
        float: left;
    }

    #motor_footer .footer-nav .desktop-container > * + * {
        padding-left: 100px;
    }

    /* sub footer */
    #motor_footer .sub-footer .container > .select-lang {
        float: left;
    }

    #motor_footer .sub-footer .container > .copyrigt {
        float: right;
    }

    /** PUT LATERAL ASIDE **/
    /* .register-form {
        width:112% !important;
    } */

    .checkout .checkout-content > *,
    .success .success-summary > *,
    .search-result .search-result-header,
    .search-result .search-result-content > * {
        width: 66%;
    }

    .checkout .checkout-content::after,
    .success .success-summary::after,
    .search-result .search-result-content::after {
        content: '';
        display: block;
        clear: both;
    }

    .checkout .checkout-content .checkout-elements,
    .success .success-summary .success-elements,
    .search-result .search-result-content .search-result-list,
    .search-result .search-result-content .checkout {
        float: left;
    }

    .checkout .checkout-content .checkout-aside,
    .success .success-summary .success-aside,
    .search-result .search-result-content .search-result-aside {
        position: sticky;
        top: 100px;

        float: right;
        width: calc(34% - 20px);
    }

    .search-result .search-result-content .checkout-aside {
        display: none;
    }

    .checkout .checkout-content .checkout-aside,
    .success .success-summary .success-aside {
        top: 20px;
    }

    /* in success left aside */
    .success .success-summary .success-aside {
        float: left;
        margin-right: 20px;
    }
}


/*CORT MOBILE*/
/*Estilos del carrito del movil*/
@media only screen and (max-width:1099px){
    .search-result.mobile {
        display:block;
    }
}

@media only screen and (min-width:1100px){
    .search-result.mobile {
        display:none;
    }
}

.search-result.mobile .search-result-content .checkout-box .close {
    border-bottom: 1px solid var(--site-border-color) !important;
}

.search-result.mobile .search-result-content .checkout-box .plegable {
    background-color: #FFFFFF !important;
}

.search-result.mobile .search-result-content .checkout-box .plegable:last-child{
    border-top:none;
}

.search-result.mobile .search-result-content .checkout-box .itinerary-step {
    padding: 17px;
}

.search-result.mobile .search-result-content .checkout-box .itinerary-step p {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--site-color-light);
}

.search-result.mobile .search-result-content .checkout-box .itinerary-step table {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--site-color-light);
}

.search-result.mobile .search-result-content .checkout-box .itinerary-step .title {float:left;}

.search-result.mobile .search-result-content .checkout-box .itinerary-step table tr td:last-child {
    float:right;
    color: var(--text-color-light);
}

.search-result.mobile .search-result-content .checkout-box .cart-flight {
    padding: 17px;
    margin-bottom: 20px;
}

.search-result.mobile .search-result-content .checkout-box .cart-flight:first-child {
    border-right: 1px solid var(--site-color-light);
}

.search-result.mobile .search-result-content .checkout-box .cart-flight tr th,
.search-result.mobile .search-result-content .checkout-box .cart-flight tr td {
    text-align:left;
}
.search-result.mobile .search-result-content .checkout-box .cart-flight .airline img {
    margin:0;
}

.search-result.mobile .search-result-content .checkout-box .cart-flight .flight-outbound,
.search-result.mobile .search-result-content .checkout-box .cart-flight .flight-inbound {
    font-weight: bold;
    color: var(--site-color);
}

.search-result.mobile .search-result-content .checkout-box .cart-flight .stops {
    color: var(--site-color);
}
.search-result.mobile .search-result-content .checkout-box .room-list,
.search-result.mobile .search-result-content .checkout-box .extras-list {
    padding: 17px;
    border-top: 1px solid var(--site-border-color);
}
.search-result.mobile .search-result-content .checkout-box .room-list .cart-room-header,
.search-result.mobile .search-result-content .checkout-box .extras-list .extras-list-header {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--site-color-light);
}

.search-result.mobile .search-result-content .checkout-box .room-list .cart-room-header table tr td:last-child {
    float:right;
    color: var(--text-color-light);
}

.search-result.mobile .search-result-content .checkout-box .room-list .cart-room-header table tr h5 {
    float: left;
}

.search-result.mobile .search-result-content .checkout-box .room-list .cart-room-info {
    color:var(--text-color);
    margin-bottom: 2px;
}
.search-result.mobile .search-result-content .checkout-box .room-list .cart-room-info p{
    margin-bottom: 2px;
}

.search-result.mobile .search-result-content .checkout-box .extras-list .extras-list-header {
    margin-bottom:17px;
    cursor: pointer;
}

.search-result.mobile .search-result-content .checkout-box .extras-list .extras-list-header tr:last-child td {color: var(--text-color-light);}

.search-result.mobile .search-result-content .checkout-box .extras-list .extras-list-header .header-info .title{float:left}

.search-result.mobile .search-result-content .checkout-box .extras-list .extras-list-header .header-info .price{
    float:right;
    padding: 0 5px 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: var(--site-color);
}

.search-result.mobile .search-result-content .checkout-box .extras-list .cart-extra p:first-child { 
    float:left;
}

.search-result.mobile .search-result-content .checkout-box .extras-list .cart-extra .price {
    color: var(--site-color);
 }

.search-result.mobile .search-result-content .checkout-box .room-list .promo-banner{
    background-color:rgba(223, 242, 223, 0.35);
    color: var(--site-color);

    padding: 10px 18px;
    margin-left: -9px;
    margin-right: -9px;
    margin-top:15px;
    width: calc(100% - 18px);
}

.search-result.mobile .search-result-content .checkout-box .process-cart-content {
    border-top: 1px solid var(--site-border-color);
    padding: 17px !important;
}

.search-result.mobile .search-result-content .checkout-box .process-cart-content table tr{border:1px solid red;}

.search-result.mobile .search-result-content .checkout-box .process-cart-content .cart-price h5 {
    font-size: 16px;
    color: var(--text-color);
}

.search-result.mobile .search-result-content .checkout-box .process-cart-content .cart-price .price {
    font-size: 1.7em;
    color: var(--site-color);
}

.search-result.mobile .search-result-content .checkout-box .process-cart-content .cart-price .price .old{
    display: inline-block;
    margin: 0.2em 5px 0 0;
    padding: 0;
    text-decoration: line-through;
    color: var(--text-color-light);
    font-size:0.7em;
}
/*Fin estilos del carrito del movil */

/* DATEPICKER V1 */
/* Datepicker input */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper .react-datepicker__input-container > input,
.react-datepicker-wrapper .react-datepicker__input-container > input:hover,
.react-datepicker-wrapper .react-datepicker__input-container > input:focus {
    width: 100%;
}

/* Datepicker popup */
.react-datepicker-popper {
    z-index: 100 !important;
}

.react-datepicker {
    font-family: var(--site-primary-title-font);
}

.react-datepicker .react-datepicker__navigation,
.react-datepicker .react-datepicker__navigation:hover,
.react-datepicker .react-datepicker__navigation:focus {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    min-width: 0;
    box-shadow: none;
}

.react-datepicker .react-datepicker__navigation:active {
    min-width: 0;
    box-shadow: none;
    padding: 0;
    width: 10.78px;
    background: none;
    border: 0.45rem solid transparent;
}

.react-datepicker .react-datepicker__navigation--previous,
.react-datepicker .react-datepicker__navigation--previous:hover,
.react-datepicker .react-datepicker__navigation--previous:focus,
.react-datepicker .react-datepicker__navigation--previous:active {
    border-right-color: var(--text-color-light);
}

.react-datepicker .react-datepicker__navigation--next,
.react-datepicker .react-datepicker__navigation--next:hover,
.react-datepicker .react-datepicker__navigation--next:focus,
.react-datepicker .react-datepicker__navigation--next:active {
    border-left-color: var(--text-color-light);
}

.react-datepicker .react-datepicker__navigation--previous:hover,
.react-datepicker .react-datepicker__navigation--previous:focus {
    border-right-color: var(--site-color);
}

.react-datepicker .react-datepicker__navigation--next:hover,
.react-datepicker .react-datepicker__navigation--next:focus {
    border-left-color: var(--site-color);
}

/* DATEPICKER V2 */
/* Datepicker input */
.react-datepicker-wrapper .react-datepicker__input-container > input,
.react-datepicker-wrapper .react-datepicker__input-container > input:hover,
.react-datepicker-wrapper .react-datepicker__input-container > input:focus {
    max-width: calc(100% - 32px);
    padding: 25px 15px 20px;
    font-size: 16px;
    line-height: 1.5em;
}

.form-style .input-wrapper > label:first-child + .react-datepicker-wrapper .react-datepicker__input-container > input,
.form-style .input-wrapper > label:first-child + .react-datepicker-wrapper .react-datepicker__input-container > input:hover,
.form-style .input-wrapper > label:first-child + .react-datepicker-wrapper .react-datepicker__input-container > input:focus {
    /* padding-top: 30px;
    padding-bottom: 15px; */
    padding: 10px 15px 10px;
}

/* Datepicker popup (full width) */
.react-datepicker-popper {
    width: calc(200% + 8px);
    margin-left: -1px;
}

.form-style .column-inputs.also-mobile > .input-wrapper + .input-wrapper .react-datepicker-popper {
    left: calc(-100% - 8px) !important;
}

.form-style .column-inputs.also-mobile > .input-wrapper + .input-wrapper .react-datepicker .react-datepicker__triangle {
    left: auto !important;
    right: 25%;
}

/* Datepicker popup style */
.react-datepicker,
.react-datepicker .react-datepicker__month-container {
    width: 100%;
    /*font-size: 16px;*/
}

.react-datepicker {
    border-radius: 0;
}

.react-datepicker .react-datepicker__current-month,
.react-datepicker .react-datepicker-time__header,
.react-datepicker .react-datepicker-year-header {
    font-size: 16px;
    color: var(--site-color);
}

.react-datepicker .react-datepicker__header {
    padding-top: 16px;
    background-color: var(--site-color-light);
}

.react-datepicker .react-datepicker__navigation,
.react-datepicker .react-datepicker__navigation:hover,
.react-datepicker .react-datepicker__navigation:focus {
    top: 18px;
}

.react-datepicker .react-datepicker__navigation--previous,
.react-datepicker .react-datepicker__navigation--previous:hover,
.react-datepicker .react-datepicker__navigation--previous:focus,
.react-datepicker .react-datepicker__navigation--previous:active {
    border-right-color: var(--site-color);
}

.react-datepicker .react-datepicker__navigation--next,
.react-datepicker .react-datepicker__navigation--next:hover,
.react-datepicker .react-datepicker__navigation--next:focus,
.react-datepicker .react-datepicker__navigation--next:active {
    border-left-color: var(--site-color);
}

.react-datepicker .react-datepicker__current-month {
    padding-bottom: 16px;
}

.react-datepicker .react-datepicker__day-names > .react-datepicker__day-name {
    color: var(--text-color-light);
    margin: 0 13px 10px;
}

.react-datepicker .react-datepicker__month {
    margin: 10px 4px;
    margin-left: 0;
    margin-left: 0.5px
}

.react-datepicker .react-datepicker__day {
    width: 27px;
    height: 27px;
    padding: 5px;
    margin: 5px;
    line-height: 28px;
    color: var(--site-color);
    border-radius: 50%;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
    color: #8B8B8B;
    opacity: 0.5;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--in-range {
    /* primeros colores
    background-color: var(--site-color-light);
    color: var(--site-color);
    */
    /* colores de Hector */
    background-color: #84B440;
    color: var(--site-color);
}

.react-datepicker .react-datepicker__day.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: var(--site-color);
    color: #FFF;
}

.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__day:focus {
    border: none;
    outline: none;
    background: var(--site-border-color);
    color: var(--site-color);
}

.react-datepicker .react-datepicker__day.react-datepicker__day--disabled,
.react-datepicker .react-datepicker__day.react-datepicker__day--disabled:hover,
.react-datepicker .react-datepicker__day.react-datepicker__day--disabled:focus {
    color: var(--text-color-light);
    background: none;
}

/* DATEPICKER V3 */
.react-datepicker-popper,
.react-datepicker {
    width: calc(100% - 2px) !important;
    margin: 0 auto;
}

.react-datepicker .react-datepicker__month-container {
    font-size: 0;
}

.react-datepicker-popper.two-months .react-datepicker__month-container {
    width: 50% !important;
}

.react-datepicker .react-datepicker__day-names > .react-datepicker__day-name,
.react-datepicker .react-datepicker__month .react-datepicker__day,
.react-datepicker .react-datepicker__month .react-datepicker__day.react-datepicker__day--disabled {
    vertical-align: top;
    margin: 2px 0 !important;
    padding: 2px 0 !important;
    width: 14% !important;
    height: auto !important;
    border-radius: 0;
    font-size: 16px;
}

.react-datepicker .react-datepicker__month {
    margin: 0 !important;
    padding: 5px 0 !important;
}

.react-datepicker .react-datepicker__header__dropdown{
    margin:7px;
}

.react-datepicker .react-datepicker__month-dropdown-container .react-datepicker__month-select {
    font-size:14px;
    width:125px;
    max-width:125px;
}
.react-datepicker .react-datepicker__year-dropdown-container .react-datepicker__year-select{
    font-size:14px;
    width:125px;
    max-width:100px;
}

/* DATEPICKER V4 */
/* NEW ARROWS TO CHANGE MONTHS */
.react-datepicker .react-datepicker__navigation,
.react-datepicker .react-datepicker__navigation:hover,
.react-datepicker .react-datepicker__navigation:focus,
.react-datepicker .react-datepicker__navigation:active {
    position: absolute;
    z-index: 1;
    text-indent: -999em;
    top: 5px;

    display: block;
    overflow: hidden;
    width: 30px;
    min-width: 0;
    height: 30px;
    padding: 0;
    line-height: 0;

    cursor: pointer;
    text-align: center;
    border: none;
    box-shadow: none;
    background: none;
}

.react-datepicker .react-datepicker__navigation::after {
    content: '';
    position: absolute;
    top: calc(50% - 4px);

    display: block;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;

    opacity: 0.7;
    border: 6px solid transparent;
    border-left-color: var(--site-color);
}

.react-datepicker .react-datepicker__navigation:hover::after {
    opacity: 1;
}

.react-datepicker .react-datepicker__navigation--previous,
.react-datepicker .react-datepicker__navigation--previous:hover,
.react-datepicker .react-datepicker__navigation--previous:focus,
.react-datepicker .react-datepicker__navigation--previous:active {
    left: 10px;
}

.react-datepicker .react-datepicker__navigation--next,
.react-datepicker .react-datepicker__navigation--next:hover,
.react-datepicker .react-datepicker__navigation--next:focus,
.react-datepicker .react-datepicker__navigation--next:active {
    right: 10px;
}

.react-datepicker .react-datepicker__navigation--previous::after {
    left: 0;
    transform: rotate(180deg);
}

.react-datepicker .react-datepicker__navigation--next::after {
    right: 0;
}

/* END NEW ARROWS TO CHANGE MONTHS */

/* NEW HEADER VERSION */
.react-datepicker .react-datepicker__header {
    padding: 0;
    background-color: transparent;
    border: none;
}

.react-datepicker .react-datepicker__current-month {
    padding: 10px;
    background-color: var(--site-color-light);
    border-bottom: 1px solid var(--site-border-color);
}

/* END NEW HEADER VERSION */

/* NEW DAYS COLOR VERSION */
.react-datepicker .react-datepicker__day.react-datepicker__day--in-range {
    background-color: var(--site-color-light);
    color: var(--site-color);
}

.react-datepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start {
    border-radius: 20px 0 0 20px;
    background-color: var(--site-color);
    color: #FFF;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-radius: 0 20px 20px 0;
    background-color: var(--site-color);
    color: #FFF;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day.react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background: #CCD3DD;
    color: var(--site-color);
}

.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__day:focus {
    border: none;
    outline: none;
    background: #CCD3DD;
    color: var(--site-color);
}

/* disabled calendar days */
.react-datepicker .react-datepicker__day[aria-disabled="true"] {
    cursor: default;
}

.react-datepicker .react-datepicker__day[aria-disabled="true"]:hover,
.react-datepicker .react-datepicker__day[aria-disabled="true"]:focus {
    background: transparent;
    opacity: 0.5;
}

/* END NEW DAYS COLOR VERSION */

/* NEW FONTS VERSION */
.react-datepicker .react-datepicker__month-container .react-datepicker__current-month {
    font-family: var(--site-primary-title-font);
    font-weight: 400;
    font-size: 16px;
}

.react-datepicker .react-datepicker__day-names > .react-datepicker__day-name,
.react-datepicker .react-datepicker__week > .react-datepicker__day {
    font-family: var(--site-font);
    font-weight: 400;
    font-size: 16px;
}

.react-datepicker .react-datepicker__week > .react-datepicker__day {
    font-size: 15px;
}

/* END NEW FONTS VERSION */

/* NEW MOBILE VERSION (two months in column) */
@media only screen and (max-width: 600px) {
    .react-datepicker-popper.two-months .react-datepicker__month-container {
        width: 100% !important;
        padding-top: 40px;
        background-color: var(--site-color-light);
    }

    .react-datepicker-popper.two-months .react-datepicker__month-container + .react-datepicker__month-container {
        display: block;
        margin-top: 10px;
        padding-top: 0;
        padding-bottom: 40px;
    }

    .react-datepicker-popper.two-months .react-datepicker__month-container > * {
        background-color: #FFF;
    }

    .react-datepicker-popper.two-months .react-datepicker__month-container .react-datepicker__current-month {
        padding-top: 5px;
        padding-bottom: 5px;
        background: none;
        border: none;
    }

    .react-datepicker-popper.two-months .react-datepicker__month-container:first-child .react-datepicker__current-month,
    .react-datepicker-popper.two-months .react-datepicker__month-container:last-child .react-datepicker__current-month {
        padding-left: 10px;
        padding-right: 10px;
    }

    .react-datepicker-popper.two-months .react-datepicker__navigation,
    .react-datepicker-popper.two-months .react-datepicker__navigation:hover,
    .react-datepicker-popper.two-months .react-datepicker__navigation:focus,
    .react-datepicker-popper.two-months .react-datepicker__navigation:active {
        top: 0;
        right: 0;
        left: 0;

        width: 100%;
        height: 40px;
    }

    .react-datepicker-popper.two-months .react-datepicker__navigation--previous::after {
        top: 5px;
        /* left: calc(50% - 15px); Con imagen */
        left: calc(50% - 3px); /* Sin imagen*/
        transform: rotate(-90deg);
    }

    .react-datepicker-popper.two-months .react-datepicker__navigation--next,
    .react-datepicker-popper.two-months .react-datepicker__navigation--next:hover,
    .react-datepicker-popper.two-months .react-datepicker__navigation--next:focus,
    .react-datepicker-popper.two-months .react-datepicker__navigation--next:active {
        top: auto;
        bottom: 0;
    }

    .react-datepicker-popper.two-months .react-datepicker__navigation--next::after {
        top: auto;
        bottom: 5px;
        /* right: calc(50% - 15px); Con imagen */
        right: calc(50% - 3px); /* Sin imagen*/
        transform: rotate(90deg);
    }
}

/* END DATEPICKER */

.image-gallery-slide-wrapper {
    position: relative;
    z-index: 1;
}

.image-gallery-fullscreen-button,
.image-gallery-left-nav,
.image-gallery-play-button,
.image-gallery-right-nav{
    z-index: 3;
}

.image-gallery-slide-wrapper .image-gallery-left-nav,
.image-gallery-slide-wrapper .image-gallery-right-nav,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button,
.image-gallery-slide-wrapper .image-gallery-left-nav:hover,
.image-gallery-slide-wrapper .image-gallery-right-nav:hover,
.image-gallery-slide-wrapper .image-gallery-left-nav:focus,
.image-gallery-slide-wrapper .image-gallery-right-nav:focus,
.image-gallery-slide-wrapper .image-gallery-left-nav:active,
.image-gallery-slide-wrapper .image-gallery-right-nav:active,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button:hover,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button:focus,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button:active {
    color: #fff;
    font-size: 30px;
    padding: 0;
    margin: 0;
    width: 45px;
    height: 45px;
    min-width: 0;
    background: none;
    border: none;
    box-shadow: none;
    opacity: 0.7;
}

.image-gallery-slide-wrapper .image-gallery-left-nav:hover,
.image-gallery-slide-wrapper .image-gallery-right-nav:hover,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button:hover {
    opacity: 1;
}

.image-gallery-slide-wrapper .image-gallery-fullscreen-button,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button:hover {
    width: 50px;
    height: 50px;
}

.image-gallery-slide-wrapper .image-gallery-left-nav::before,
.image-gallery-slide-wrapper .image-gallery-right-nav::before,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button:before {
    position: absolute;
    top: 0;
    right: auto;
    left: auto;
    display: block;
    margin: 0;
    padding: 0;
    line-height: 45px;
    font-size: 1em;
    font-weight: bold;
    transform: none;
    text-shadow: none;
    color: inherit;
}

.image-gallery-slide-wrapper .image-gallery-left-nav::before {
    left: 15px;
}

.image-gallery-slide-wrapper .image-gallery-right-nav::before,
.image-gallery-slide-wrapper .image-gallery-fullscreen-button::before {
    right: 15px;
}

.image-gallery-slide-wrapper .image-gallery-fullscreen-button::before {
    line-height: 50px;
    font-weight: 100;
}

/** Area Privada **/
.apriv-header {
    position: relative;
    display: block;
    margin: 40px 0 30px;
}

.apriv-header.small-margin {
    margin-bottom: 20px;
}

.apriv-header::after {
    content: '';
    display: block;
    clear: both;
}

.apriv-header .back-link {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold;

    text-decoration: none;
}

.apriv-header .back-link:hover,
.apriv-header .back-link:focus {
    text-decoration: underline;
}

.apriv-header .back-link::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 5px;
    left: 0;

    display: block;
    width: 0;
    height: 0;

    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: currentColor transparent transparent transparent;
    line-height: 0px;

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.apriv-menu {
    display: block;
    margin: 0;
    padding: 0 0 15px;
    overflow: auto;
}

.apriv-menu ul {
    display: block;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    list-style: none;
}

.apriv-menu ul li,
.apriv-menu a,
.apriv-menu .current {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
}

.apriv-menu ul li + li {
    padding-left: 30px;
}

.apriv-menu a,
.apriv-menu .current {
    margin: 0;
    padding-bottom: 5px;
    text-decoration: none;
    color: var(--grey-color);
    font-family: var(--site-primary-title-font);
}

.apriv-menu a:hover {
    text-decoration: none;
    color: var(--site-color);
}

.apriv-menu .current {
    cursor: default;
    color: var(--site-color);
    font-weight: bold;
}

.apriv-menu .current::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;

    display: block;
    width: 100%;
    height: 2px;
    background: currentColor;
}

.apriv-header .logout-link,
.apriv-footer .logout-link {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 0 15px 20px;
    padding: 0;
    color: var(--grey-color);
    text-decoration: none;
}

.apriv-header .logout-link {
    display: none;
}

.apriv-header .logout-link span,
.apriv-header .logout-link svg,
.apriv-footer .logout-link span,
.apriv-footer .logout-link svg {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
}

.apriv-header .logout-link svg,
.apriv-footer .logout-link svg {
    width: auto;
    height: 1em;
    margin-left: 0.5em;
}

.apriv-header .logout-link:hover,
.apriv-footer .logout-link:hover {
    color: var(--site-color);
    text-decoration: none;
}

/* bookings list and cards */
.bookings-list-wrapper,
.booking-view-contain {
    overflow: visible;
    display: block;
    margin: 0;
    padding: 0 0 65px;
}

.bookings-list-wrapper .bookings-list-header .title {
    margin: 0;

    font-family: var(--site-title-font);
    font-weight: 400;
    font-size: 2em;
}

.bookings-list-wrapper .bookings-list-header .toggle-bookings-list {
    display: inline-block;
    cursor: pointer;
}

.bookings-list-wrapper .bookings-list-header .toggle-bookings-list img.after {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 0;
    padding: 0;

    -webkit-transition: all 100ms;
    -moz-transition: all 100ms;
    -o-transition: all 100ms;
    transition: all 100ms;
}

.bookings-list-wrapper .bookings-list-header .toggle-bookings-list.opened img.after,
.bookings-list-wrapper .bookings-list-header .toggle-bookings-list.opened::after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.bookings-list-wrapper .bookings-list-header .select-wrapper {
    position: relative;
    display: inline-block;
    margin-top: 1em;
}

.bookings-list-wrapper .bookings-list-header .select-wrapper::after {
    content: '';
    position: absolute;
    z-index: 1;
    right: 0;
    top: 50%;

    box-sizing: border-box;
    display: block;
    width: 0;
    height: 0;

    border: 3px solid transparent;
    border-left-color: var(--site-color);

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.bookings-list-wrapper .bookings-list-header .select-wrapper > label,
.bookings-list-wrapper .bookings-list-header .select-wrapper > select {
    position: relative;
    z-index: 2;

    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    min-width: 0;
    height: auto;
    min-height: 0;
    line-height: 1.3em;
    margin: 0;
    padding: 0 0.3em 0 0;

    border: none;
    box-shadow: none;
    background: none;
}

.bookings-list-wrapper .bookings-list-header .select-wrapper > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
    padding-right: 1em;
    font-weight: bold;
    line-height: 1em;
}

.bookings-list-wrapper .empty-bookings-list {
    display: block;
    margin: 30px 0 0;
    padding: 0;
}

.bookings-list-wrapper .empty-bookings-list svg,
.bookings-list-wrapper .empty-bookings-list .text-info {
    display: inline-block;
    vertical-align: middle;
    margin: auto 0;
    padding: 0;
}

.bookings-list-wrapper .empty-bookings-list .text-info * + * {
    margin-top: 4px;
}

.bookings-list-wrapper .empty-bookings-list svg {
    width: 40px;
    height: auto;
    margin-right: 24px;
    color: var(--site-color);
}

.bookings-list-wrapper .empty-bookings-list .text-info {
    max-width: calc(100% - 70px);
}

.bookings-list-wrapper .filter {
    display: inline-block;
    vertical-align: middle;
    margin: 15px 0 0;
    padding: 0;
}

.bookings-list-wrapper .filter ul {
    display: block;
    margin: 0 -12px;
    padding: 0;
    list-style: none;
}

.bookings-list-wrapper .filter ul li,
.bookings-list-wrapper .filter label,
.bookings-list-wrapper .filter select {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 1.4em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.4em;
    list-style: none;
    font-family: var(--site-primary-title-font);
}

.bookings-list-wrapper .filter ul li {
    margin: 5px 12px 0;
}

.bookings-list-wrapper .filter select {
    border: none;
    Color: var(--site-color);
    background: none;
}

.bookings-list-wrapper .list-title {
    display: inline-block;
    margin: 30px 0 0;

    text-decoration: none;
    color: var(--text-color);
    font-family: var(--site-primary-title-font);
    font-size: 1.35em;
    font-weight: 400;
}

.bookings-list-wrapper a.list-title:hover {
    text-decoration: underline;
}

.bookings-list .list-title strong {
    font-weight: 400;
    color: var(--site-color);
}

.bookings-list .booking-card,
.bookings-list .add-new-booking,
.booking-view-contain > .booking-view-aside-wrapper,
.booking-view-rooms .booking-room-card,
.booking-view-rooms .add-new-room {
    position: relative;
    z-index: 10;
    display: block;
    padding: 0;
    margin: 24px 0 0;
}

.bookings-list .booking-card,
.booking-view-rooms .booking-room-card {
    display: block;
    min-height: 210px;
    border: 1px solid var(--grey-color-light);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
}

.booking-view-rooms .booking-room-card:first-child {
    margin-top: 0;
}

.bookings-list .booking-card .checkbox-label {
    position: absolute;
    z-index: 10;
    top: 12px;
    left: 12px;

    display: block;
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;

    border-radius: 50%;
    border: 1px solid #979797;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.bookings-list .booking-card .checkbox-label > * {
    display: none;
}

.bookings-list .booking-card.selected {
    border-color: var(--site-color);
}

/* no image version */
.bookings-list .booking-card.selected .checkbox-label::after {
    content: '';
    position: absolute;
    z-index: 3;
    top: 4px;
    left: 4px;

    display: block;
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;

    border-radius: 50%;
    background: var(--site-color);
}

.bookings-list .booking-card .label,
.booking-view-wrapper .booking-view-header .label,
.booking-view-rooms .booking-room-card .label {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;

    display: block;
    margin: 0;
    padding: 10px 15px;

    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 12px;
    line-height: 1em;
}

.booking-view-wrapper .booking-view-header .label {
    top: 20px;
}

.bookings-list .booking-card .booking-img > .label,
.booking-view-rooms .booking-room-card .booking-rom-img > .label {
    left: 0;
    right: auto;
    text-decoration: none;
}

.bookings-list .booking-card .label .ico-info::before,
.bookings-list .booking-card .label .ico-info::after,
.booking-view-wrapper .booking-view-header .label .ico-info::before,
.booking-view-wrapper .booking-view-header .label .ico-info::after,
.booking-view-rooms .booking-room-card .label .ico-info::before,
.booking-view-rooms .booking-room-card .label .ico-info::after {
    width: 1.3em;
    height: 1.3em;
    line-height: 1.4em;

    color: currentColor;
    border-color: currentColor;
}

.bookings-list .booking-card .label:not(.site-bg),
.booking-view-wrapper .booking-view-header .label:not(.site-bg),
.booking-view-rooms .booking-room-card .label:not(.site-bg) {
    color: var(--grey-color);
    background-color: var(--grey-color-light);
}

.bookings-list .booking-card .label.confirmed,
.bookings-list:not(.cancel-bookings-list) .booking-card .label.in-progress,
.booking-view-wrapper .booking-view-header.confirmed .label,
.booking-view-wrapper .booking-view-header.in-progress .label,
.booking-view-rooms .booking-room-card .label.confirmed,
.booking-view-rooms .booking-room-card .label.in-progress {
    color: #00B900;
    background-color: #E0F2E0;
}

.bookings-list .booking-card .label.cancelled,
.booking-view-wrapper .booking-view-header.cancelled .label,
.booking-view-rooms .booking-room-card .label.cancelled {
    color: #D00000;
    background-color: #FAE5E5;
}

.booking-view-wrapper .booking-view-header.cancelled .title,
.booking-view-wrapper .booking-view-header.cancelled .room-info,
.booking-view-wrapper .booking-view-header.cancelled .booking-id,
.booking-view-wrapper .booking-view-header.cancelled .booking-info-list strong {
    color: var(--text-color-light);
}

/*ESTILOS DEL RESUMEN DEL AREA DE CLIENTES*/
.booking-view-wrapper h2.title {
    color: var(--text-color);
    font-family: var(--site-title-font);
    font-size: 20px;
    padding: 25px 20px 20px;
}


.bookings-list .booking-card > .booking-img,
.booking-view-rooms .booking-room-card > .booking-rom-img {
    display: block;
    width: 100%;
    height: auto;
    min-height: 160px;
    margin: 0;
    padding: 0;

    background-color: var(--site-color);
    background-position: center center;
    background-size: cover;
}

.bookings-list .booking-card > .booking-img {
    background-color: var(--site-color);
    background-position: 50%;
    background-size: cover;
    display: block;
    height: auto;
    margin: 0;
    min-height: 160px;
    padding: 0;
}
/* .bookings-list .booking-card > .booking-img img, */
.booking-view-rooms .booking-room-card > .booking-rom-img img {
    display: none;
}
@media only screen and (min-width:600px){
    .bookings-list .booking-card > .booking-img img {
        position: relative;
        top:25%;
    }
}

.bookings-list .booking-card > .booking-info,
.booking-view-rooms .booking-room-card > .booking-rom-info {
    position: relative;
    width: 100%;
    padding: 0;
}

.bookings-list .booking-card > .booking-info .principal-info,
.bookings-list .booking-card > .booking-info .footer-info,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info,
.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info {
    padding: 15px 15px 15px 24px;
}

.bookings-list .booking-card > .booking-info .principal-info,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info {
    color: var(--text-color-light);
}

.bookings-list .booking-card > .booking-info .footer-info,
.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info {
    padding-top: 5px;
}

.bookings-list .booking-card > .booking-info .principal-info a.locator,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info a.title {
    text-decoration: none;
    font-weight: bold;
}

.bookings-list .booking-card > .booking-info .principal-info a.locator:hover,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info a.title:hover {
    text-decoration: underline;
}

.bookings-list .booking-card > .booking-info .principal-info table.date,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date {
    width: auto;
    margin: 1em 0;
}

.bookings-list .booking-card > .booking-info .principal-info table.date th,
.bookings-list .booking-card > .booking-info .principal-info table.date td,
.bookings-list .booking-card > .booking-info .principal-info .info,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date th,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date td,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info {
    text-align: left;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.bookings-list .booking-card > .booking-info .principal-info div {margin-bottom:15px;}

.bookings-list .booking-card > .booking-info .principal-info div .info {float:left}
.bookings-list .booking-card > .booking-info .principal-info  div .icons {
    float:right;
    color: var(--site-color);
    font-size: 18px;
}

.bookings-list .booking-card > .booking-info .principal-info  div .icons svg{
    margin: 0px 5px 0px 5px;
}

.bookings-list .booking-card > .booking-info .principal-info table.date th,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date th {
    padding-bottom: 0.3em;
}

.bookings-list .booking-card > .booking-info .principal-info .info strong,
.bookings-list .booking-card > .booking-info .principal-info table.date td,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date td,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info strong {
    color: var(--text-color);
    font-family: var(--site-primary-title-font);
    font-size: 1.2em;
    font-weight: normal;
}

.bookings-list .booking-card > .booking-info .principal-info .info strong,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info strong {
    display: block;
}

.bookings-list .booking-card > .booking-info .principal-info table.date th + th,
.bookings-list .booking-card > .booking-info .principal-info table.date td + td,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date th + th,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date td + td {
    padding-left: 50px;
}

.bookings-list .booking-card > .booking-info .principal-info table.date.with-info .info,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date.with-info .info {
    display: none;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info {
    display: flex;
    font-size: 14px;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info > * {
    flex: 1;
}

.bookings-list .booking-card > .booking-info .footer-info .price {
    display: inline-block;
    font-size: 1.5em;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info .price {
    font-size: 1.3em;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info .links-wrapper {
    text-align: right;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info .links-wrapper a {
    text-decoration: none;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .footer-info .links-wrapper a:hover {
    text-decoration: underline;
}

.bookings-list .booking-card > .booking-info .footer-info .links-wrapper {
    float: right;
    padding-top: 0.5em;
}

.bookings-list .booking-card > .booking-info .footer-info .links-wrapper::after {
    content: '';
    display: block;
    clear: both;
}

.bookings-list .booking-card > .booking-info .footer-info .links-wrapper a {
    display: inline-block;
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-size: 14px;
}

.bookings-list .booking-card > .booking-info .footer-info .links-wrapper a:hover {
    text-decoration: underline;
}

.bookings-list .booking-card > .booking-info .footer-info .links-wrapper .left-link {
    float: left;
    margin-right: 5px;
}

.bookings-list .booking-card > .booking-info .footer-info .links-wrapper .right-link {
    float: right;
    margin-left: 5px;
}

.bookings-list .booking-card.booking-previous > .booking-info .principal-info .h2,
.bookings-list .booking-card.booking-previous > .booking-info .principal-info table.date td,
.bookings-list .booking-card.booking-previous > .booking-info .principal-info .info strong {
    color: var(--text-color-light);
}

.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info-list,
.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info-list li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info-list li {
    margin-top: 10px;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info-list p {
    margin: 0;
}

.booking-view-rooms .booking-room-card > .booking-rom-info .principal-info .info-list strong {
    font-family: var(--site-primary-title-font);
    color: var(--text-color);
}

.bookings-list .add-new-booking,
.booking-view-rooms .add-new-room {
    text-decoration: none;
    color: var(--site-color);
    background-color: var(--grey-color-light);
}

.bookings-list .add-new-booking span,
.booking-view-rooms .add-new-room span {
    display: block;
    margin: 0;
    padding: 25px 25px 25px 56px;
}

.bookings-list .add-new-booking:hover,
.booking-view-rooms .add-new-room:hover {
    background-color: var(--site-border-color);
}

.bookings-list .add-new-booking::before,
.bookings-list .add-new-booking::after,
.booking-view-rooms .add-new-room::before,
.booking-view-rooms .add-new-room::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;

    display: block;
    width: 24px;
    height: 2px;
    margin: -1px 0 0;
    padding: 0;
    background: currentColor;
}

.bookings-list .add-new-booking::after,
.booking-view-rooms .add-new-room::after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* Reserva Full */
.booking-view-wrapper .booking-view-header,
.booking-view-wrapper .mobile-price-info {
    position: relative;
    margin-bottom: 30px;
}

.booking-view-wrapper .booking-view-header .booking-info-list,
.booking-view-wrapper .booking-view-header .room-info,
.booking-view-wrapper .booking-view-header .booking-id {
    margin: 5px 0 0;
    padding: 0;

    font-family: var(--site-primary-title-font);
    font-size: 1.35em;
}

.booking-view-wrapper .booking-view-header a.booking-id {
    color: var(--text-color);
    text-decoration: none;
}

.booking-view-wrapper .booking-view-header a.booking-id:hover {
    text-decoration: underline;
}

.booking-view-wrapper .booking-view-header .booking-info-list li {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    color: var(--text-color-light);
}

.booking-view-wrapper .booking-view-header .booking-info-list li + li {
    margin-left: 5px;
    border-left: 1px solid currentColor;
    padding-left: 7px;
}

.booking-view-wrapper .booking-view-header .booking-info-list strong {
    color: var(--text-color);
}

.booking-view-wrapper .mobile-price-info {
    display: none;
}

.booking-view-wrapper .booking-view-price {
    position: relative;
    padding: 16px 20px;
    background: var(--grey-color-light);
    display:grid;
}

.booking-view-wrapper .booking-view-price::after {
    content: '';
    display: block;
    clear: both;
}

.booking-view-wrapper .booking-view-price > div:first-child { 
    padding-bottom:15px; 
    border-bottom: 1px solid var(--site-border-color);
    margin-bottom: 10px;
}

.booking-view-wrapper .booking-view-price .desglose .price {font-size:15px}

.booking-view-wrapper .booking-view-price .title,
.booking-view-wrapper .booking-view-price .price {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;

    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
}

.booking-view-wrapper .booking-view-price .title {
    float: left;
    color: var(--text-color-light);
}

.booking-view-wrapper .booking-view-price .price {
    float: right;
    font-size: 20px;
}

.booking-view-wrapper .booking-view-price .booking-id {
    float: right;
    clear: right;
    margin-top: 0.5em;

    font-size: 1em;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    text-decoration: none;
    color: var(--site-color);
}

.booking-view-wrapper .booking-view-price .booking-id:hover {
    text-decoration: underline;
}

.booking-view-wrapper .booking-view-match-price {
    position: relative;
    overflow: visible;

    display: block;
    margin: 0;
    padding: 20px;

    font-family: var(--site-primary-title-font);
    font-size: 14px;
    color: var(--text-color);
    text-decoration: none;

    background: #FFC857;
    border: 1px solid #FFC857;
}

.booking-view-wrapper .booking-view-match-price span {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    padding: 0;
    line-height: 20px;
}

.booking-view-wrapper .booking-view-match-price span::before {
    content: '€';
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 0;
    padding: 0;

    font-weight: bold;
    font-size: 1.2em;
    line-height: 20px;
}

.booking-view-wrapper .booking-view-match-price::before,
.booking-view-wrapper .booking-view-match-price::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: -10px;
    right: 40px;

    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #FFC857 transparent;
}

.booking-view-wrapper .booking-view-match-price::after {
    z-index: 3;
    top: -9px;
    border-color: transparent transparent #FFC857 transparent;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content {
    max-height: calc(100vh - 75px);
    overflow: auto;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options {
    position: relative;
    padding: 20px;
    background: var(--grey-color-light);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options .popup-overlay .popup-content,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options .popup-overlay::before {
    width:50% !important
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options .popup-overlay .popup-content p {
    padding:20px;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-footer {
    border-top: 1px solid var(--site-border-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header .title {
    font-weight: bold;
    color: var(--text-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header img.after {
    position: absolute;
    top: 0;
    right: 5px;
    display: block;
    width: 40px;
    height: 100%;

    transform: rotate(-180deg);

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header.down img.after,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header.down::after {
    transform: rotate(0deg);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress {
    font-size: 14px;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress .progress-var {
    position: relative;
    display: block;
    width: 100%;
    height: 8px;
    margin: 10px 0 0;
    background: var(--site-border-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress .current-progress {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 0;
    height: 8px;
    background: var(--site-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress.color-green {
    color: var(--green-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress.color-green .current-progress {
    background-color: var(--green-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress.color-yellow {
    color: #FFC857;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress.color-yellow .current-progress {
    background-color: #FFC857;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress.color-red {
    color: var(--red-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress.color-red .current-progress {
    background-color: var(--red-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li.only-mobile {
    display: none;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li + li {
    margin-top: 1.3em;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li a:not(.btn) {
    font-family: var(--site-primary-title-font);
    font-size: 1.2em;
    text-decoration: none;
    color: var(--text-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li a:not(.btn):hover,
.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li a:not(.btn):focus {
    color: var(--site-color);
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li .btn {
    width: 100%;
    font-weight: 400;
    font-size: 1.2em;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content ul.booking-update-options li .btn {
    padding: 25px 20px;
    box-shadow: none;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li .MuiCollapse-container {
    width: 100%;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-buttons .btn {
    position: relative;
    display: block;
    width: 100%;
    margin: 30px 0 0;
    padding: 20px;

    text-align: left;
    font-size: 1.3em;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-buttons .btn.ico-arrow {
    padding-right: 60px;
}

.booking-view-aside-wrapper .booking-view-aside .booking-view-aside-buttons .btn.ico-arrow::after {
    position: absolute;
    top: 0;
    right: 0;

    width: 60px;
    height: 100%;
    margin: 0;

    background: no-repeat right center;
    /*background: url(/static/media/icons/ico-arrow.svg) no-repeat right center;*/
}

.booking-view-room > .booking-rom-img {
    display: block;
    /*width: 50%;
     height: 240px; */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 20px;
}

/* @media only screen and (min-width:600px){
    .booking-view-room > .booking-rom-img {
        width:50%
    }
}

@media only screen and (max-width:599px){
    .booking-view-room > .booking-rom-img {
        width:100%
    }
} */

/* .booking-view-room > .booking-rom-img > img {
    display: none;
} */

.booking-view-room > .personalized-wishes {
    display: block;
    margin: 12px auto 0;
    padding: 0;
    background: var(--site-color-light);
}

.booking-view-room > .personalized-wishes .personalized-wishes-header,
.booking-view-room > .personalized-wishes .personalized-wishes-content {
    position: relative;
    display: block;
    margin: 0;
    padding: 18px 20px;
}

.booking-view-room > .personalized-wishes .personalized-wishes-header {
    cursor: pointer;
    padding-right: 50px;
}

.booking-view-room > .personalized-wishes .personalized-wishes-header > .title {
    font-size: 14px;
}

.booking-view-room > .personalized-wishes .personalized-wishes-header img.before {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
}

.booking-view-room > .personalized-wishes .personalized-wishes-header img.after {
    position: absolute;
    top: 0;
    right: 5px;

    display: block;
    width: 40px;
    height: 100%;

    transform: rotate(-0deg);

    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
}

.booking-view-room > .personalized-wishes .personalized-wishes-header.down::after,
.booking-view-room > .personalized-wishes .personalized-wishes-header.down img.after {
    transform: rotate(-180deg);
}

.booking-view-room > .personalized-wishes .personalized-wishes-header > .title {
    display: inline-block;
    vertical-align: middle;
}

.booking-view-room > .personalized-wishes .personalized-wishes-content {
    padding-top: 0;
    padding-bottom: 20px;
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .input-wrapper textarea {
    color: var(--text-color);
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .input-wrapper textarea::-webkit-input-placeholder { /* Edge */
    color: var(--site-border-color);
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .input-wrapper textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--site-border-color);
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .input-wrapper textarea::placeholder {
    color: var(--site-border-color);
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .form-footer {
    padding-top: 12px;
    text-align: right;
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .form-footer::after {
    content: '';
    display: block;
    clear: both;
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .form-footer .msg {
    float: left;
    padding-top: 16px;
    font-size: 1.2em;
    color: #FFC857;
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .form-footer .msg.error {
    color: #D00000;
}

.booking-view-room > .personalized-wishes .personalized-wishes-content .form-footer .msg.success {
    color: #00B900;
}

.booking-client-info {
    border:1px solid var(--site-border-color);
    margin-bottom: 30px;
}

.booking-client-info .title{
    border-bottom:1px solid var(--site-border-color);
}

.booking-client-info .title svg{
    color:var(--site-color);
}

.booking-client-info .title span{
    margin-left: 0.5em;
}

.booking-view-room .booking-rom-info,
.booking-view-data {
    /* margin-top: 30px; */
    padding:19px;
    background-color: var(--site-color-light);
}

.booking-view-room .booking-rom-info .title,
.booking-view-data .title {
    color: var(--text-color);
    margin-bottom: 0.5em;
}

/* .booking-client-info .booking-rom-info .form-style span {
    font-size: 14px;
} */

.booking-client-info .booking-rom-info .form-style > p,
.booking-view-room .booking-view-data .form-style > p {
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
    color: var(--site-color);
    display: block;
    width: 100%;
    margin: 0 0 28px;
    padding: 0 0 10px;
    border-bottom: 1px solid var(--site-border-color);
}

.booking-view-room .booking-rom-info .form-style.only-view .column-inputs > .input-wrapper,
.booking-view-data .form-style.only-view .column-inputs > .input-wrapper {
    width: calc(50% - 6px);
}

.booking-view-room .booking-rom-info .form-style.only-view .column-inputs > .input-wrapper > label:first-child,
.booking-view-data .form-style.only-view .column-inputs > .input-wrapper > label:first-child {
    left: 0;
    color: var(--text-color);
    font-weight: bold;
    font-size:14px;
}

.booking-view-room .booking-rom-info .form-style.only-view .column-inputs > .input-wrapper input:disabled,
.booking-view-data .form-style.only-view .column-inputs > .input-wrapper input:disabled {
    opacity: 1;
    padding-bottom: 0;
    padding-left: 0;
    font-size: 14px;
}

/* Medias de Lista Reservas Area Privada */
@media only screen and (min-width: 600px) {
    .apriv-page h1,
    .apriv-page .h1 {
        font-size: 2.7em;
    }

    .apriv-footer .logout-link {
        display: none;
    }

    .apriv-header .logout-link {
        display: block;
        float: right;
    }

    .apriv-header .apriv-menu {
        display: inline-block;
        vertical-align: middle;
    }

    .apriv-header .apriv-menu ul {
        display: block;
        width: auto;
    }

    .apriv-header .apriv-menu ul li {
        width: auto;
        text-align: left;
    }

    .bookings-list-wrapper .bookings-list-header .title {
        display: inline-block;
    }

    .bookings-list-wrapper .bookings-list-header .select-wrapper {
        float: right;
        text-align: right;
    }

    .bookings-list .booking-card,
    .booking-view-rooms .booking-room-card {
        display: flex;
    }

    .bookings-list .booking-card > .booking-img,
    .booking-view-rooms .booking-room-card > .booking-rom-img {
        width: 192px;
    }

    .bookings-list .booking-card > .booking-info,
    .booking-view-rooms .booking-room-card > .booking-rom-info {
        width: calc(100% - 192px);
    }

    .bookings-list .booking-card > .booking-info .principal-info,
    .booking-view-rooms .booking-room-card > .booking-rom-info .principal-info {
        padding-top: 40px;
    }
}

@media only screen and (min-width: 700px) {
    .bookings-list .booking-card > .booking-info .principal-info table.date.with-info .info,
    .booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date.with-info .info {
        display: table-cell;
    }

    .bookings-list .booking-card > .booking-info .principal-info table.date.with-info + .info,
    .booking-view-rooms .booking-room-card > .booking-rom-info .principal-info table.date.with-info + .info {
        display: none;
    }
}


/* FIN ESTILOS RESUMEN AREA CLIENTES*/

@media only screen and (min-width: 1100px) {
    .apriv-header {
        margin-top: 60px;
    }

    #motor_footer {
        margin-top: 80px;
    }

    .bookings-list::after {
        content: '';
        display: block;
        clear: both;
    }

    .bookings-list .booking-card:nth-child(2n) {
        margin-left: 30px;
    }

    .bookings-list .booking-card {
        float: left;
        width: calc(50% - 17px);
    }

    .bookings-list .add-new-booking {
        float: left;
        clear: both;
        width: calc(50% - 15px);
    }

    .popup-content .bookings-list .booking-card {
        float: none;
        width: 100%;
        margin-left: 0;
    }

    .booking-view-contain {
        display: flex;
    }

    .booking-view-contain > .booking-view-aside-wrapper {
        order: 2;
        width: 384px;
        margin-top: 0;
        margin-left: 24px;
    }

    .booking-view-contain > .booking-view-aside-wrapper .booking-view-aside {
        position: sticky;
        top: 10px;
    }

    .booking-view-contain > .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header img.after,
    .booking-view-contain > .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header::after {
        display: none;
    }

    .booking-view-contain > .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header + .MuiCollapse-container {
        display: block !important;
        height: auto !important;
    }

    .booking-view-contain > .booking-view-info {
        order: 1;
        width: calc(100% - 408px);
    }

    /* .booking-view-room .booking-rom-info, .booking-view-data {
        margin-top: 56px;
    } */
}

@media only screen and (max-width: 1099px) {
    /* floating aside */
    .booking-view-wrapper .mobile-price-info,
    .process-cart > .process-cart-header .mobile-price-info,
    .process-cart > .process-cart-header .mobile-price-info .price,
    .process-cart div.cart-content .dropdown-cart-header.only-mobile,
    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li.only-mobile {
        display: block;
    }

    .process-cart > .process-cart-header .mobile-price-info + .title {
        display: none;
    }

    .process-cart > .process-cart-header::after {
        top: auto;
        bottom: 1.5em;
    }

    .booking-view-contain > .booking-view-aside-wrapper {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 8px;
        width: calc(100% - 16px);
        margin: 0;
    }

    .booking-view-contain > .booking-view-aside-wrapper .booking-view-aside {
        width: 100%;
        max-width: 750px;
        margin: 0 auto 32px;
        background: #FFF;
        border: 1px solid var(--site-border-color);
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress,
    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options {
        background: none;
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header {
        padding: 15px;
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-view-progress {
        padding: 24px 15px;
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options {
        padding: 25px 15px 30px;
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-content .booking-edit-options li + li {
        margin-top: 24px;
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-footer:not(.show-mobile),
    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-footer .booking-view-match-price,
    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-buttons {
        display: none;
    }

    .checkout + * .search-result-aside {
        display: none;
    }

    /* create shadow */
    .booking-view-aside-wrapper .booking-view-aside .MuiCollapse-container.MuiCollapse-entered::after {
        content: '';
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        background: rgba(0, 0, 0, 0.6);
    }

    .booking-view-aside-wrapper .booking-view-aside .booking-view-aside-header,
    .booking-view-aside-wrapper .booking-view-aside .MuiCollapse-container.MuiCollapse-entered .MuiCollapse-wrapper {
        position: relative;
        z-index: 2;
    }

    .booking-view-aside-wrapper .booking-view-aside .MuiCollapse-container.MuiCollapse-entered .MuiCollapse-wrapper {
        background: #FFF;
    }
}

@media only screen and (max-width: 600px) {
    .apriv-data-block .inputs-group,
    .apriv-data-block .form-footer {
        padding: 10px 20px 0;
    }

    .apriv-data-block .inputs-group:first-child {
        padding-top: 25px;
    }

    .apriv-data-block .form-footer {
        padding-bottom: 25px;
    }

    .apriv-data-block .form-footer input[type="submit"] {
        width: 100%;
    }

    .booking-view-wrapper .booking-view-header .label {
        position: relative;
        display: inline-block;
        margin: 0 0 20px;
    }

    .booking-view-wrapper .booking-view-header .booking-info-list,
    .booking-view-wrapper .booking-view-header .booking-info-list li {
        display: block;
    }

    .booking-view-wrapper .booking-view-header .booking-info-list li + li {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
    }
}

/** Popup de cancelaciónes **/
#cancel_rooms.apriv-page {
    min-height: calc(100vh - 403px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.update-wrapper,
.cancel-rooms-wrapper,
.taxi-wrapper,
.match-rate-wrapper {
    width: 100%;
    padding: 40px 0 30px;
}

.popup-content .update-wrapper,
.popup-content .cancel-rooms-wrapper,
.popup-content .taxi-wrapper,
.popup-content .match-rate-wrapper {
    width: auto;
    padding: 50px 20px;
}

.update-wrapper .h1,
.cancel-rooms-wrapper .h1,
.taxi-wrapper .h1,
.match-rate-wrapper .h1 {
    margin: 0 auto 5px;
}

.update-wrapper .title-sentence,
.cancel-rooms-wrapper .title-sentence,
.taxi-wrapper .title-sentence,
.match-rate-wrapper .title-sentence {
    margin: 0 auto 20px;

    font-size: 16px;
    font-family: var(--site-font);
    font-weight: normal;
    color: var(--text-color);
}

.popup-content .cancel-rooms-wrapper .h1,
.popup-content .cancel-rooms-wrapper .title-sentence {
    text-align: center;
    max-width: 450px;
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room {
    position: relative;
    display: block;
    margin: 0 0 20px;
    padding: 15px 25px 20px 50px;
    border: 1px solid var(--site-border-color);
    cursor: pointer;
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 17px;
    left: 20px;

    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid var(--site-color);
    border-radius: 50%;
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room.selected {
    border-color: var(--site-color);
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room.selected::before {
    background: var(--site-color);
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room.selected::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 9px;
    left: 12px;

    display: block;
    width: 32px;
    height: 32px;
    background: no-repeat center center;
    /*background: url('/static/media/icons/ico-check.svg') no-repeat center center;*/
    background-size: contain;
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room .room-info {
    color: var(--text-color-light);
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room .room-info .title {
    font-size: 14px;
    color: var(--site-color);
    margin-bottom: 0.5em;
}

.cancel-rooms-wrapper .cancel-rooms-list .cancel-room .room-price {
    font-size: 16px;
    margin-top: 1em;
}

.cancel-rooms-wrapper .bookings-list .booking-card {
    cursor: pointer;
}

.cancel-rooms-wrapper .bookings-list .booking-card:hover {
    cursor: pointer;
    border-color: var(--site-color);
}

.cancel-rooms-wrapper .cancel-cart-wrapper,
.taxi-wrapper .cancel-cart-wrapper {
    font-size: 14px;
    font-family: var(--site-primary-title-font);
    margin-top: 20px;
}

.cancel-rooms-wrapper .cancel-cart-wrapper {
    margin-top: 60px;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cart-price,
.taxi-wrapper .cancel-cart-wrapper .cart-price,
.cancel-rooms-wrapper .cancel-cart-wrapper hr,
.taxi-wrapper .cancel-cart-wrapper hr,
.cancel-rooms-wrapper .btn-primary {
    max-width: 23em;
    margin: 0 auto 1em;
}

.update-wrapper .form-style .process-cart-content {
    background: var(--grey-color-light);
    margin: -1.3em 0 1.5em;
    padding: 1.2em;
}

.update-wrapper .form-style .process-cart-content .diff-price {
    text-align: right;
}

.update-wrapper .form-style .process-cart-content .diff-price .price {
    font-size: 12px;
    color: var(--text-color-light);
}

.update-wrapper .form-style .process-cart-content .cart-price * {
    display: inline-block;
    vertical-align: middle;
    color: var(--text-color);
}

.update-wrapper .form-style .process-cart-content .cart-price .price {
    float: right;
    text-align: right;
}

.update-wrapper .form-style .process-cart-content .cart-price .price .final {
    font-size: 20px;
}

.update-wrapper .form-style .process-cart-content .cart-price::after {
    content: '';
    display: block;
    clear: both;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-conditions-link,
.taxi-wrapper .cancel-cart-wrapper .cancel-conditions-link {
    margin: 1em auto;
}

.cancel-rooms-wrapper .btn-primary {
    display: block;
    width: 100%;
    max-width: 240px;
    margin-bottom: 0;
}

.cancel-rooms-wrapper .cancel-cart-wrapper a,
.taxi-wrapper .cancel-cart-wrapper a {
    text-decoration: none
}

.cancel-rooms-wrapper .cancel-cart-wrapper a:not(.hide) .hide,
.cancel-rooms-wrapper .cancel-cart-wrapper a.hide .show,
.taxi-wrapper .cancel-cart-wrapper a:not(.hide) .hide,
.taxi-wrapper .cancel-cart-wrapper a.hide .show {
    display: none;
}

.cancel-rooms-wrapper .cancel-cart-wrapper a:not(.btn):hover,
.taxi-wrapper .cancel-cart-wrapper a:not(.btn):hover {
    text-decoration: underline;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .btn-primary,
.taxi-wrapper .cancel-cart-wrapper .btn-primary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cart-price p,
.taxi-wrapper .cancel-cart-wrapper .cart-price p {
    display: inline-block;
    vertical-align: top;
    max-width: calc(100% - 50px);
    color: var(--text-color-light);
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cart-price .price,
.taxi-wrapper .cancel-cart-wrapper .cart-price .price {
    float: right;
    color: var(--text-color);
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer {
    text-align: center;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions {
    position: relative;
    display: block;
    text-align: left;
    margin: 0 0 1.5em;
    padding: 15px 15px 15px 25px;
    border: 1px solid var(--site-border-color);
    color: var(--text-color);
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .title,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .title {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 1em;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content {
    overflow: auto;
    max-height: 7.1em;
    padding: 0 10px 0 0;

    font-size: 12px;
    font-family: var(--site-font);
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar {
    -webkit-appearance: none;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar:vertical,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar:vertical {
    width: 5px;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-button,
.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-button:increment,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-button,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-button:increment {
    display: none;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar:horizontal,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar:horizontal {
    height: 10px;
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-thumb,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-thumb {
    background-color: var(--site-border-color);
    border-radius: 0px;
    border: 2px solid var(--site-border-color);
}

.cancel-rooms-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-track,
.taxi-wrapper .cancel-cart-wrapper .cancel-cart-footer .cancel-conditions .content::-webkit-scrollbar-track {
    border-radius: 0px;
}

.cancel-rooms-wrapper .options-wrapper {
    display: block;
    margin: 0 -12px 20px;
    font-size: 14px;
}

.cancel-rooms-wrapper .options-wrapper .btn {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: calc(100% - 24px);
    min-height: 80px;
    margin: 12px;
    padding: 20px 16px 20px 55px;
    line-height: 1.45em;

    border: none;
    box-shadow: none;
    background: var(--grey-color-light);

    text-align: left;
    font-family: var(--site-primary-title-font);
    font-weight: 400;
    color: var(--site-color);
}

.cancel-rooms-wrapper .options-wrapper .MuiCollapse-container {
    height: auto;
    margin: 12px 12px 0;
}

/* Show input version */
.cancel-rooms-wrapper .options-wrapper .btn input[type="radio"] {
    display: block;
    position: absolute;
    left: 20px;
}

.cancel-rooms-wrapper .options-wrapper .btn:hover {
    background: var(--site-color);
    color: #FFFFFF;
}

/* no image version */
.cancel-rooms-wrapper .options-wrapper .btn.selected::after {
    z-index: 3;

    left: 25px;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;

    border: none;
    background: var(--site-color);
}

.cancel-rooms-wrapper .cancel-info {
    margin-bottom: 20px;
}

.cancel-rooms-wrapper .input-wrapper,
.taxi-wrapper .input-wrapper {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
}

.cancel-rooms-wrapper .input-wrapper label:first-child,
.taxi-wrapper .input-wrapper label:first-child {
    position: absolute;
    z-index: 2;
    top: 16px;
    left: 16px;
    font-size: 14px;
    color: var(--site-color);
}

.cancel-rooms-wrapper .input-wrapper label span,
.taxi-wrapper .input-wrapper label span {
    font-size: 12px;
    color: var(--text-color-light);
}

.cancel-rooms-wrapper .input-wrapper textarea,
.taxi-wrapper .input-wrapper textarea {
    position: relative;
    z-index: 1;
    width: calc(100% - 32px);
    max-width: calc(100% - 32px);
    padding: 15px 15px 20px;
    font-size: 16px;
}

.cancel-rooms-wrapper .input-wrapper label + textarea,
.taxi-wrapper .input-wrapper label + textarea {
    padding-top: 35px;
}

.cancel-rooms-wrapper .input-wrapper textarea::-webkit-input-placeholder,
.taxi-wrapper .input-wrapper textarea::-webkit-input-placeholder { /* Edge */
    color: var(--site-border-color);
}

.cancel-rooms-wrapper .input-wrapper textarea:-ms-input-placeholder,
.taxi-wrapper .input-wrapper textarea::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--site-border-color);
}

.cancel-rooms-wrapper .input-wrapper textarea::placeholder,
.taxi-wrapper .input-wrapper textarea::placeholder {
    color: var(--site-border-color);
}

.cancel-rooms-wrapper .btns-wrapper,
.taxi-wrapper .btns-wrapper {
    text-align: center;
    margin-top: 20px;
}

.cancel-rooms-wrapper .btns-wrapper .btn,
.taxi-wrapper .btns-wrapper .btn {
    width: 100%;
    max-width: 320px;
    margin: 10px auto 0;
    padding: 16px;

    font-size: 16px;
    line-height: 1.5em;
}

@media only screen and (min-width: 600px) {
    .cancel-rooms-wrapper .cancel-rooms-list .cancel-room .room-info,
    .cancel-rooms-wrapper .cancel-rooms-list .cancel-room .room-price {
        display: inline-block;
        vertical-align: top;
        max-width: calc(100% - 50px);
    }

    .cancel-rooms-wrapper .cancel-rooms-list .cancel-room .room-price {
        float: right;
        text-align: right;
        margin-top: 0;
    }

    .cancel-rooms-wrapper .options-wrapper:after {
        content: '';
        display: block;
        clear: both;
    }

    .cancel-rooms-wrapper .options-wrapper .btn {
        float: left;
        width: calc(50% - 24px);
    }

    .cancel-rooms-wrapper .options-wrapper .MuiCollapse-container {
        float: left;
        clear: left;
        width: calc(100% - 24px);
    }

    .cancel-rooms-wrapper .btns-wrapper .btn {
        display: inline-block;
        vertical-align: top;
        width: calc(50% - 14px);
    }

    .cancel-rooms-wrapper .btns-wrapper .btn + .btn {
        margin-left: 24px;
    }
}

@media only screen and (min-width: 700px) {
    .popup-content .update-wrapper,
    .popup-content .cancel-rooms-wrapper,
    .popup-content .taxi-wrapper,
    .popup-content .match-rate-wrapper {
        padding-left: 50px;
        padding-right: 50px;
    }

    .cancel-rooms-wrapper .cancel-cart-wrapper .cancel-conditions-link,
    .taxi-wrapper .cancel-cart-wrapper .cancel-conditions-link {
        float: left;
        display: block;
        margin: 17px 0 0;
    }

    .cancel-rooms-wrapper .cancel-cart-wrapper .cart-price,
    .cancel-rooms-wrapper .cancel-cart-wrapper hr,
    .cancel-rooms-wrapper .cancel-cart-wrapper .btn-primary {
        float: right;
        margin-right: 0;
    }
}

@media only screen and (min-width: 1100px) {
    #cancel_rooms.apriv-page {
        min-height: calc(100vh - 457px);
    }

    .cancel-rooms-wrapper .cancel-cart-wrapper::after,
    .taxi-wrapper .cancel-cart-wrapper::after {
        content: '';
        display: block;
        clear: both;
    }

    .cancel-rooms-wrapper .options-wrapper .btn {
        width: calc(33.33% - 24px);
    }

    .popup-content .cancel-rooms-wrapper .options-wrapper .btn {
        width: calc(50% - 24px);
    }
}

/** END Popup de cancelaciónes **/

/** Pagina de login Area Privada **/
.login-columns .login-form-wrapper {
    padding: 25px 0 40px;
}

.login-columns .login-info-wrapper {
    margin: 0 -20px -25px;
    padding: 60px 20px 50px;
}

.login-columns .login-info-wrapper .login-info {
    margin: auto;
    max-width: 19.3em;
    font-size: 17px;
}

.login-columns .login-info-wrapper .login-info .title {
    font-family: var(--site-primary-title-font);
}

.login-columns .login-info-wrapper .login-info-list,
.login-columns .login-info-wrapper .login-info-list li {
    position: relative;
    display: block;
    margin: 0;
    padding: 10px 0 0;
}

.login-columns .login-info-wrapper .login-info-list li {
    padding-top: 20px;
    padding-left: 40px;
}

.login-columns .login-info-wrapper .login-info-list li svg {
    position: absolute;
    top: 20px;
    left: 0;
    display: block;
}

.login-columns .login-info-wrapper .login-info-list li svg[width="32px"] {
    margin-left: -4px;
}

.login-columns .login-info-wrapper .login-info-list li svg[height="32px"] {
    margin-top: -6px;
}

@media only screen and (min-width: 790px) {
    .login-columns {
        position: relative;
        display: flex;
    }

    .login-columns .login-form-wrapper,
    .login-columns .login-info-wrapper {
        position: relative;
        z-index: 2;
        width: calc(50% - 40px);
        margin: 0;
        padding: 60px 0 40px;
        background: none;
    }

    .login-columns .login-form-wrapper {
        margin-right: 20px;
    }

    .login-columns .login-info-wrapper {
        margin-left: 20px;
    }

    .login-columns .login-info-wrapper .login-info {
        width: calc(100% - 30px);
        margin-right: 0;
    }

    .login-columns::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;

        display: block;
        width: 50vw;
        height: calc(100% + 25px);
        background: var(--site-color-light);
    }
}

@media only screen and (min-width: 1100px) {
    .login-columns .login-form-wrapper,
    .login-columns .login-info-wrapper {
        width: calc(50% - 60px);
        padding-top: 120px;
    }

    .login-columns .login-form-wrapper {
        margin-right: 60px;
    }

    .login-columns .login-info-wrapper {
        margin-left: 60px;
    }

    .login-columns .login-info-wrapper .login-info {
        margin-right: auto;
    }

    .login-columns::before {
        left: calc(50% + 60px);
        width: (50vw - 60px);
    }
}

/** FIN Pagina de login Area Privada **/

/** Formulario de datos Area Privada **/
.apriv-data-block {
    margin: 24px auto 16px;
}

.apriv-data-block .title,
.taxi-form .title {
    margin-bottom: 1.5em;
    font-weight: bold;
    color: var(--text-color);
}

.taxi-form .title {
    margin-bottom: 0;
}

.apriv-data-block .inputs-group {
    padding: 32px;
}

.apriv-data-block .form-footer {
    padding: 0 32px 40px;
    text-align: right;
}

.apriv-data-block .form-footer input[type="submit"] {
    min-width: 240px;
}

.apriv-data-block .inputs-group .input-wrapper {
    margin-bottom: 30px;
}

.apriv-data-block .inputs-group .column-inputs .input-wrapper {
    width: calc(50% - 14px);
}

.apriv-data-block .inputs-group .column-inputs .input-wrapper + .input-wrapper {
    margin-left: 24px;
}

.apriv-data-block .inputs-group .column-inputs .input-wrapper > label + .react-tel-input > input {
    width: calc(100% + 2px);
    max-width: none;
}

.apriv-data-block .inputs-group .column-inputs .input-wrapper > label + .react-tel-input .selected-flag .flag {
    margin-top: -0.5em;
}

.apriv-data-block .inputs-group .input-wrapper > label {
    color: var(--text-color);
}

.apriv-data-block .inputs-group .input-wrapper > label span {
    color: var(--text-color-light);
}

/* changes for taxi form */
.taxi-form, .taxi-cancel {
    max-width: 700px;
    margin: 0 auto;
}

.taxi-form .form-style {
    margin: 40px auto 0;
}

.taxi-form .inputs-group {
    margin: 0 0 24px;
}

.taxi-form .form-style .column-inputs {
    font-size: 0;
    margin: 0 -24px;
}

.taxi-form .form-style .column-inputs > .input-wrapper {
    display: inline-block;
    width: 50%;

    font-size: 12px;
    margin: 0;
    padding: 0;
}

.taxi-form .form-style .column-inputs > .input-wrapper > * {
    margin: 16px 24px 0;
}

.match-rate-wrapper {
    max-width: 100%;
}

.update-date .form-footer .update-link,
.taxi-form .form-footer .cancel-link {
    display: inline-block;
    margin: 20px auto 0;
    text-decoration: none;
    font-weight: normal;
}

/* changes for match rate form */
.match-rate-form .form-footer .price-table {
    background-color: var(--grey-color-light);
    margin-bottom: 2em;
}

.match-rate-form .form-footer .price-table th,
.match-rate-form .form-footer .price-table td {
    padding: 0.2em 1em;
}

.match-rate-form .form-footer .price-table tr:first-child th,
.match-rate-form .form-footer .price-table tr:first-child td {
    padding-top: 1em;
}

.match-rate-form .form-footer .price-table tr:last-child th,
.match-rate-form .form-footer .price-table tr:last-child td {
    padding-bottom: 1em;
}

.match-rate-form .form-footer .btn {
    width: 100%;
    max-width: 100%;
}

/* Mobile changes */
@media only screen and (max-width: 600px) {
    .apriv-data-block .inputs-group,
    .apriv-data-block .form-footer {
        padding: 10px 20px 0;
    }

    .apriv-data-block .inputs-group:first-child {
        padding-top: 25px;
    }

    .apriv-data-block .form-footer {
        padding-bottom: 25px;
    }

    .apriv-data-block .form-footer input[type="submit"] {
        width: 100%;
    }
}

/** FIN Formulario de datos Area Privada **/

/** Páginas de recuperación y modificación de contraseña **/
.recover-password-wrapper,
.modify-password-wrapper {
    min-height: calc(100vh - 545px);
    margin: 0 auto;
    padding: 70px 0;
}

.recover-password-wrapper {
    max-width: 590px;
}

.recover-password-header .title + p,
.modify-password-header .title + p {
    margin-top: 12px;
}

.recover-password-block,
.modify-password-block {
    margin: 30px auto 0;
}

.recover-password-block .inputs-group,
.recover-password-block .form-footer,
.recover-password-footer,
.modify-password-block .inputs-group,
.modify-password-block .form-footer,
.modify-password-footer {
    padding: 0 32px;
}

.recover-password-block .inputs-group,
.recover-password-footer,
.modify-password-block .inputs-group,
.modify-password-footer {
    padding-top: 30px;
}

.recover-password-block .inputs-group .input-wrapper,
.modify-password-block .inputs-group .input-wrapper {
    margin-bottom: 20px;
}

.recover-password-block .inputs-group .column-inputs .input-wrapper,
.modify-password-block .inputs-group .column-inputs .input-wrapper {
    width: calc(50% - 14px);
}

.recover-password-block .inputs-group .column-inputs .input-wrapper + .input-wrapper,
.modify-password-block .inputs-group .column-inputs .input-wrapper + .input-wrapper {
    margin-left: 24px;
}

.recover-password-block .form-footer,
.modify-password-block .form-footer {
    padding-bottom: 30px;
}

.recover-password-block .form-footer input[type="submit"] {
    width: 100%;
}

.modify-password-block .form-footer,
.recover-password-footer {
    text-align: right;
}

/* Tablet changes */
@media only screen and (max-width: 1099px) {
    .recover-password-wrapper,
    .modify-password-wrapper {
        min-height: calc(100vh - 503px);
        padding: 50px 0;
    }
}

/* Mobile changes */
@media only screen and (max-width: 600px) {
    .recover-password-wrapper,
    .modify-password-wrapper {
        min-height: calc(100vh - 463px);
        padding: 30px 0;
    }

    .modify-password-block .form-footer input[type="submit"] {
        width: 100%;
    }
}

/** FIN Páginas de recuperación y modificación de contraseña **/

/** CSS para la comisión de la agencias **/
.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}

/** FIN CSS para la comisión de la agencias **/

.btn-up, .btn-up:hover, .btn-up:focus, .btn-up:active, .btn-up:visited,
.btn-up.active:hover, .btn-up.active:focus {
    font-family: var(--site-primary-title-font);
    color: var(--site-color-up);
    border-color: var(--site-color-up);
    background-color: #FFFFFF;
}

.btn-up > * {
    text-decoration: none !important;
}

.btn-up.active, .btn-up.active:focus, .btn-up.active:active, .btn-up.active:visited {
    background-color: var(--site-color-up);
}

/* NUEVO ICONO QUITAR HABITACIÓN */
.process-cart > .MuiCollapse-container div.cart-content > div.rooms-list .cart-room > .cart-room-header > .price {
    padding-right: 30px;
}

.process-cart > .MuiCollapse-container div.cart-content > div.rooms-list .cart-room > .cart-room-header > .remove-room::before,
.process-cart > .MuiCollapse-container div.cart-content > div.rooms-list .cart-room > .cart-room-header > .remove-room::after {
    display: none;
}

.process-cart > .MuiCollapse-container div.cart-content > div.rooms-list .cart-room > .cart-room-header > .remove-room {
    top: 1px;
    width: 20px;
    height: 20px;
    color: #8B8B8B;
}

.process-cart > .MuiCollapse-container div.cart-content > div.rooms-list .cart-room > .cart-room-header > .remove-room:hover,
.process-cart > .MuiCollapse-container div.cart-content > div.rooms-list .cart-room > .cart-room-header > .remove-room:focus {
    color: var(--site-color);
}

/* NUEVO CARRITO MOBILE */
@media only screen and (max-width: 1099px) {
    .search-result-aside > .process-cart {
        left: 12px;
        max-width: calc(100% - 24px);
        margin-bottom: 35px;
    }

    .search-result-aside > .process-cart.opened {
        z-index: 2147483640;
    }

    .process-cart > .process-cart-header {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 46px;
        width: calc(50% - 30px);
        padding: 9px 15px;
        background: var(--site-color-light);
        border: none;
    }

    .process-cart > .process-cart-footer {
        z-index: 3;
        width: 50%;
        margin-left: auto;
    }

    .process-cart > .process-cart-footer .final-btn {
        min-height: 64px;
        padding-bottom: 14px;
    }

    .process-cart > .process-cart-footer .ico-arrow::after {
        display: none;
    }

    .process-cart > .MuiCollapse-container > * {
        position: relative;
        z-index: 2;
        background: #FFF;
    }

    .process-cart > .MuiCollapse-container::after {
        content: '';
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;

        display: block;
        width: 100vw;
        min-width: 370px;
        height: 100%;
        min-height: 100vh;
        background: black;
        opacity: 0.2;
    }
	
    .checkout .checkout-content .checkout-elements > .process-cart > .MuiCollapse-container::after {
        display: none;
    }
}

/* AJUSTES IPAD CHECKOUT */
.checkout .checkout-content .checkout-elements,
.checkout .checkout-content .checkout-aside {
    position: relative;
    z-index: 1;
    top: auto;
    left: auto;
    float: none;
    width: auto;
}

@media only screen and (min-width:1100px){
    .checkout .checkout-content .checkout-elements .payment-data,
    .checkout .checkout-content .checkout-elements .payment-data + button{
        width:150%;
    } 
}

.checkout .checkout-content .checkout-aside .process-cart {
    position: relative;
    bottom: auto;
    left: auto;

    display: block;
    width: auto;
    max-width: none;
    margin: 0;
}

.checkout .checkout-content .checkout-aside .process-cart > .process-cart-header {
    position: relative;
    width: auto;
}

.checkout .checkout-content .checkout-aside .process-cart > .process-cart-header::after,
.checkout .checkout-content .checkout-aside .process-cart > .MuiCollapse-container::after {
    display: none;
}

.checkout .checkout-content .checkout-aside .process-cart .process-cart-content > .cart-price {
    display: flex;
}

.checkout .checkout-content .checkout-aside .process-cart .process-cart-content > .final-btn {
    width: calc(100% + 2px);
    margin: 0 -1px -1px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper::after {
    content: '';
    display: block;
    clear: both;
}

.checkout .checkout-content .checkout-elements dl.checkout-box .rccs .rccs__card--unknown > div {
    background: linear-gradient(25deg, #0d5ea8, #1383be);
}

.checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper  .rccs-wrapper .rccs {
    margin-bottom: 1.5em;
}

@media only screen and (min-width: 790px) {
    .checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper .rccs-wrapper::after {
        content: '';
        display: block;
        clear: both;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper .rccs {
        float: right;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper  .rccs-wrapper .rccs {
        margin-top: 1.7em;
        margin-bottom: 0;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper > br {
        display: none;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box .form-wrapper .text-block {
        float: left;
        width: calc(100% - 320px);
    }
}

/* AJUSTE USUARIOS REGISTRADOS (SPRING CLUB) */
.room-card .room-config.spring-club-user .title-row .standar-price,
.room-card .room-config.spring-club-user .room-pensions dt::before,
.room-card .room-config.spring-club-user .room-pensions dt > .price,
.room-card .room-config.spring-club-user .room-pensions dd .pension-standar,
.room-card .room-config.spring-club-user .room-pensions dd .pension-club .mobile-info {
    display: none !important;
}

.room-card .room-config.spring-club-user .room-pensions dd {
    display: block !important;
}

.room-card .room-config.spring-club-user .room-pensions dd > * {
    background: none;
    text-align: left;
}

@media only screen and (max-width: 750px) {
    .room-card .room-config.spring-club-user .room-pensions dd > * {
        border-top: none;
        padding-top: 0;
    }

    .room-card .room-config.spring-club-user .room-pensions dd .pension-club .pension-price-info .motor-modal-link::after {
        content: ' + Spring Club';
        display: inline;
    }
}

@media only screen and (min-width: 757px) {
    .room-card .room-config.spring-club-user > .title-row > .title,
    .room-card .room-config.spring-club-user > .room-pensions > div > dt {
        width: calc(100% - 22em);
    }

    .room-card .room-config.spring-club-user > .title-row > .club-price {
        width: 18em;
    }

    .room-card .room-config.spring-club-user > .room-pensions > div > dd {
        width: 20em;
    }

    .room-card .room-config.spring-club-user::before {
        display: none;
    }

    .room-card .room-config.spring-club-user > .room-pensions dd > .pension-club {
        width: calc(100% - 2em);
        text-align: right;
    }

    .room-card .room-config > .room-pensions dd .pension-info {
        display: none;
    }
}

.poromotion-link {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.poromotion-link img {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.poromotion-link.down img.after {
    transform: rotate(180deg);
}

/* AJUSTES Cabecera de resultado de búsqueda */
@media only screen and (max-width: 1099px) {
    .only-desktop,
    #motor_header .right-nav a.phone-icon span,
    #motor_header .right-nav a.mail-icon span,
    #motor_header .right-nav a.user-icon span {
        display: none;
    }

    #motor_header .right-nav a.mail-icon #screensharing {
        display:block;
    }
}

@media only screen and (max-width: 740px) {
    .search-result .booking-process {
        display:none;
    }
}

.search-result .search-result-header .motor-info-banner {
    border: none;
    padding: 0;
    text-align: center;
}

.search-result .search-result-header .motor-info-banner > * {
    display: inline-block;
    box-sizing: border-box;

    margin: 0 8px;
    padding: 10px 3px;

    border: 1px solid var(--site-border-color);
    border-radius: 8px;

    text-align: left;
    font-weight:bold;
}

.search-result .search-result-header .motor-info-banner > *:first-child {
    margin-left: 0;
}

.search-result .search-result-header .motor-info-banner > *:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 750px) {
    
    .motor-info-banner {display:grid;}

    .search-result .search-result-header .motor-info-banner > *{
        margin: 10px 0;
        padding:0;
    }
    
    .search-result .search-result-header .motor-info-banner > *{
        /* max-width: 85px; */
        max-width: auto;
        font-size: 10px;
        text-align: center;
    }

    .search-result .search-result-header .motor-info-banner > * > span {
        display: block;
        margin: 0 auto;
        max-width: none;
    }
}

@media only screen and (min-width: 751px) {
    .search-result .search-result-header .motor-info-banner > *{
        display:flex;
        align-items:center;
    }
}

.spinner .motor-info-banner > p{
    font-size:14px !important;
    font-weight:bold !important;
    border:1px solid var(--site-border-color);
    border-radius: 8px;
    padding: 10px 3px !important;
    margin: 10px 8px !important;
    display: flex !important;
    align-items: center !important;
}

.spinner .motor-info-banner > p span{
    text-align:left;
}

@media only screen and (min-width: 751px) {
    .spinner .motor-info-banner {
        display:flex !important;
        margin: 70px 8px !important;
    }
}

@media only screen and (max-width: 750px) {
    .spinner .motor-info-banner {
        display:grid !important;
    }
}
/* OCULART CÓDIGO PROMOCIONAL + NÚMERO NOCECES */
.search-content .form-style .input-wrapper,
.search-content .form-style .column-btns,
.availability-modal .form-style .column-btns {
    clear: both;
}

.search-content .form-style .num-nights,
.availability-modal .form-style .num-nights {
    float: right;
    display: block;
    color: var(--site-color);
    margin-bottom: 12px;
    font-size: 14px;
}

.search-content .form-style .pormotion-drop .pormotion-drop-button {
    position: relative;
    display: inline-block;
    margin-bottom: 5px;
    padding-right: 20px;

    cursor: pointer;

    font-size: 14px;
    color: var(--site-color);
    text-decoration: none;
}

.search-content .form-style .pormotion-drop .pormotion-drop-button::after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 3px);
    display: block;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    border-color: var(--site-color) transparent transparent transparent;
    line-height: 0px;

    transition: transform 100ms;
    -moz-transition: transform 100ms;
    -webkit-transition: transform 100ms;
    -o-transition: transform 100ms;
}

.search-content .form-style .pormotion-drop .pormotion-drop-button.close::after {
    transform: rotate(-180deg);
}

.search-content .form-style .pormotion-drop input[type="text"]:first-child {
    padding: 5px;
}

.search-content .form-style .MuiCollapse-container .MuiCollapse-wrapper {
    margin-bottom: 15px;
}

.search-content .form-style .MuiCollapse-container h3 {
    position: relative;
    z-index: 1;

    margin: 12px 0;
    padding: 0 40px 0 0;
    font-family: var(--site-primary-title-font);
}

.search-content .form-style .MuiCollapse-container h3 + a {
    position: relative;
    z-index: 2;
    top: -32px;
    right: 0;

    float: right;
}

@media only screen and (max-width: 600px) {
    .search-content .form-style .num-nights {
        float: none;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 410px) {
    .search-content .form-style .column-btns .btn-big,
    .search-content .form-style .column-btns .btn-primary {
        font-size: 12px;
    }
}

/* availability modal */
.availability-modal {
    padding: 20px 40px 40px;
}

.availability-modal .header-navigation {
    margin: 0 -20px 10px;
}

.availability-modal .header-navigation::after {
    content: '';
    display: block;
    clear: both;
}

.availability-modal .header-navigation .left-link {
    float: left;
}

.availability-modal .header-navigation .right-link {
    float: right;
}

.availability-modal .header-titles {
    margin: 0 0 14px;
    text-align: center;
}

.availability-modal .header-titles strong {
    font-weight: bold;
    color: var(--site-color-spring) !important;
}

.availability-modal .header-titles .title {
    font-size: 24px;
    line-height: 32px;

    font-family: var(--site-primary-title-font);
    font-weight: bold;

    text-align: center;
    color: var(--site-color-spring) !important;

    margin: 0 0 10px;
}

/* put spring colors */
.availability-modal a,
.availability-modal a:hover,
.availability-modal a:focus,
.availability-modal a:active,
.availability-modal a:visited {
    color: var(--site-color-spring);
}

.availability-modal input[type="text"],
.availability-modal input[type="text"]:hover,
.availability-modal input[type="text"]:focus,
.availability-modal input[type="number"],
.availability-modal input[type="number"]:hover,
.availability-modal input[type="number"]:focus,
.availability-modal input[type="phone"],
.availability-modal input[type="phone"]:hover,
.availability-modal input[type="phone"]:focus,
.availability-modal input[type="time"],
.availability-modal input[type="time"]:hover,
.availability-modal input[type="time"]:focus,
.availability-modal input[type="date"],
.availability-modal input[type="date"]:hover,
.availability-modal input[type="date"]:focus,
.availability-modal input[type="email"],
.availability-modal input[type="email"]:hover,
.availability-modal input[type="email"]:focus,
.availability-modal input[type="password"],
.availability-modal input[type="password"]:hover,
.availability-modal input[type="password"]:focus,
.availability-modal select, select:hover,
.availability-modal select:focus,
.availability-modal textarea,
.availability-modal textarea:hover,
.availability-modal textarea:focus {
    border-color: #CCD3DD;
    color: var(--site-color-spring);
}

.availability-modal input[type="text"]:not(:disabled):hover,
.availability-modal input[type="text"]:not(:disabled):focus,
.availability-modal input[type="number"]:not(:disabled):hover,
.availability-modal input[type="number"]:not(:disabled):focus,
.availability-modal input[type="phone"]:not(:disabled):hover,
.availability-modal input[type="phone"]:not(:disabled):focus,
.availability-modal input[type="time"]:not(:disabled):hover,
.availability-modal input[type="time"]:not(:disabled):focus,
.availability-modal input[type="date"]:not(:disabled):hover,
.availability-modal input[type="date"]:not(:disabled):focus,
.availability-modal input[type="email"]:not(:disabled):hover,
.availability-modal input[type="email"]:not(:disabled):focus,
.availability-modal input[type="password"]:not(:disabled):hover,
.availability-modal input[type="password"]:not(:disabled):focus,
.availability-modal select:not(:disabled):hover,
.availability-modal select:not(:disabled):focus,
.availability-modal textarea:not(:disabled):hover,
.availability-modal textarea:not(:disabled):focus {
    border-color: var(--site-color-spring);
}

.availability-modal .form-style .select-wrapper::after {
    border-top-color: var(--site-color-spring);
}

.availability-modal .btn-primary,
.availability-modal .btn-primary:hover,
.availability-modal .btn-primary:focus,
.availability-modal .btn-primary:active,
.availability-modal .btn-primary:visited,
.availability-modal .btn-primary.active:hover,
.availability-modal .btn-primary.active:focus {
    background-color: var(--site-color-spring);
    border-color: var(--site-color-spring);
}

/* vailability calendar */
.availability-modal .react-datepicker {
    border-color: #CCD3DD;
}

.availability-modal .react-datepicker .react-datepicker__navigation::after {
    border-left-color: var(--site-color-spring);
}

.availability-modal .react-datepicker .react-datepicker__current-month {
    border-bottom-color: #F2F4F6;
    background-color: #F2F4F6;
    color: var(--site-color-spring);
}

.availability-modal .form-style .num-nights,
.availability-modal .react-datepicker .react-datepicker__day,
.availability-modal .react-datepicker .react-datepicker__day:hover,
.availability-modal .react-datepicker .react-datepicker__day:focus,
.availability-modal .react-datepicker .react-datepicker__day.react-datepicker__day--in-range {
    color: var(--site-color-spring);
}

.availability-modal .react-datepicker .react-datepicker__day.react-datepicker__day--in-range {
    background-color: #F2F4F6;
}

.availability-modal .react-datepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start,
.availability-modal .react-datepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end {
    background-color: var(--site-color-spring);
    color: #FFF;
}

/* vailability calendar day prices */
.availability-modal .react-datepicker .react-datepicker__month .react-datepicker__day span {
    display: block;
    margin: -5px 0 0;
    padding: 0 0 5px;

    font-size: 8px;
    min-height: 1em;
    line-height: 1em;

    color: var(--green-color);
}

.availability-modal .react-datepicker .react-datepicker__month .react-datepicker__day span.diable {
    color: var(--text-color-light);
}

.availability-modal .react-datepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start span,
.availability-modal .react-datepicker .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end span {
    color: #fff;
}


/* STANDAR PRICE MODAL */
.standar-selected-modal,
.discount-info-wrapper {
    padding: 48px 40px 30px;

    font-size: 16px;
    line-height: 24px;
}

.discount-info-wrapper {
    padding-bottom: 70px;
}

.standar-selected-modal h3,
.discount-info-wrapper h3 {
    display: block;
    margin-bottom: 15px;

    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
}

.discount-info-wrapper ul.no-list {
    text-align: left;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
}

.standar-selected-modal p,
.discount-info-wrapper p,
.discount-info-wrapper ul.no-list > li {
    display: block;
    margin-bottom: 20px;
}

.standar-selected-modal p svg,
.discount-info-wrapper p svg,
.discount-info-wrapper li svg {
    display: inline-block;
    vertical-align: middle;

    margin-right: 8px;
}

.standar-selected-modal .column-btns .btn.full-weidth + .btn.full-weidth {
    margin-top: 8px;
}

/* START Selection Inputs */
#motor_search_box .search-content .checkbox-group input[type="checkbox"] + label,
.radio-group label,
.radio-group input,
.checkbox-group label,
.checkbox-group input,
.switch-group label,
.switch-group input {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin: auto;
}

#motor_search_box .search-content .checkbox-group input[type="checkbox"] + label,
.radio-group label + input,
.radio-group input + label,
.checkbox-group label + input,
.checkbox-group input + label,
.switch-group label + input,
.switch-group input + label {
    margin-left: 0.3em;
}

.radio-group input,
.checkbox-group input,
.switch-group input {
    position: relative;

    width: 1.5em;
    height: 1.5em;

    background-color: #FFF;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* radio input */
.radio-group input {
    border: 1px solid var(--site-border-color);
    border-radius: 50%;
}

.radio-group input:hover,
.radio-group input:checked {
    border-color: var(--site-color);
}

.radio-group input:checked:hover {
    border-color: var(--site-color-hover);
}

.radio-group input:checked:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;

    display: block;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border-radius: 50%;

    background: var(--site-color);
}

.radio-group input:checked:hover:before {
    background-color: var(--site-color-hover);
}

/* checkbox/switch input */
.checkbox-group input,
.switch-group input {
    border: 1px solid var(--site-border-color);
}

.checkbox-group input:hover,
.checkbox-group input:checked,
.switch-group input:hover,
.switch-group input:checked {
    border-color: var(--site-color);
}

.checkbox-group input:checked,
.switch-group input:checked {
    background-color: var(--site-color);
}

.checkbox-group input:checked:hover,
.switch-group input:checked:hover {
    border-color: var(--site-color-hover);
    background-color: var(--site-color-hover);
}

.checkbox-group input:checked:before,
.switch-group input:checked:before {
    content: '';
    position: absolute;
    top: 0.3em;
    left: 0.3em;

    display: block;
    width: 0.7em;
    height: 0.3em;

    border: 2px solid #FFFFFF;
    border-top: none;
    border-right: none;

    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* switch input */
.switch-group input {
    width: 3.7em;
    height: 1.8em;
    border-radius: 1em;
}

.switch-group input::after {
    content: '';
    position: absolute;
    top: 0.3em;
    left: 0.3em;

    display: block;
    width: 1.1em;
    height: 1.1em;
    border-radius: 50%;

    background: var(--site-border-color);
    
    transition: left 100ms;
    -moz-transition: left 100ms;
    -webkit-transition: left 100ms;
    -o-transition: left 100ms;
}

.switch-group input:checked::before {
    top: 0.5em;
    left: 0.5em;
}

.switch-group input:checked::after {
    left: 2.2em;
    background: #FFFFFF;
}

/* END Selection Inputs */

/** NUEVO LOADING **/
.spinner.hi-spinner {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: rgba(255, 255, 255, 0.95);
}

.spinner.hi-spinner > div {
    position: relative;

    display: block;
    width: auto;
    height: auto;

    margin: auto;
    padding: 20px;
    text-align: center;

    border: none;
    border-radius: 0;
    color: var(--site-color);
    background: none;

    -webkit-animation: none;
    animation: none;
    margin-top: 0px;
}

.spinner.hi-spinner > div > p {
    display: block;
    margin: 0 0 30px;
    padding: 0;

    color: var(--site-color);
    font-family: var(--site-title-font);
    font-size: 25px;
    line-height: 32px;
    font-weight: normal;
}

.spinner.hi-spinner > div > div {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 120px;
    height: 120px;
    margin: auto;
    padding: 40px;

    border: none;
    border-radius: 0;
    color: var(--site-color);
    background: none;

    -webkit-animation: none;
    animation: none;
}

.spinner.hi-spinner > div > div > svg {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: auto;

    -webkit-animation: hi-spin 5s linear infinite; /* Safari */
    animation: hi-spin 5s linear infinite;
}

@-webkit-keyframes hi-spin { /* Safari */
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes hi-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner.hi-spinner > div > div > p {
    display: block;
    margin: 0;
    padding: 0;

    color: var(--site-color);
    font-family: var(--site-font);
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
}

.spinner.hi-spinner > div > div > p > span {
    display: inline-block;
}

/** END NUEVO LOADING **/

/** SESIÓN CADUCADA **/
/* Popup Sesión caducada */
.sesion-end {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: rgba(0,0,0,0.8);
}

.sesion-end > .sesion-end-content {
    position: relative;

    display: block;
    width: 580px;
    max-width: calc(100% - 40px);
    height: auto;

    margin: auto;
    padding: 60px 50px;
    text-align: center;

    border: none;
    border-radius: 0;
    color: var(--text-color);
    background: #FFFFFF;

    font-family: var(--site-primary-title-font);
    font-size: 16px;
}

.sesion-end > .sesion-end-content p {
    display: block;
    max-width: 480px;
    margin: 0 auto;
    padding: 0;

    line-height: 1em;
}

.sesion-end > .sesion-end-content .title {
    margin-bottom: 30px;

    color: var(--site-color);
    font-family: var(--site-title-font);
    font-size: 25px;
    line-height: 32px;
    font-weight: normal;
}

.sesion-end > .sesion-end-content .buttons-wrapper {
    display: block;
    margin: 40px 0 0;
    padding: 0;
}

.sesion-end > .sesion-end-content .buttons-wrapper .btn {
    display: block;
    width: 100%;

    padding: 1.4em;
    line-height: 1em;
}

.sesion-end > .sesion-end-content .buttons-wrapper .btn + .btn {
    margin-top: 1em;
}

/* Botones cuando va a caducar la sesión */
.final-btn[class*='expire-in'] {
    position: relative;
}

.final-btn[class*='expire-in']::before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;

    display: inline-block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);

    background: #FFF;
    opacity: 0.3;

    transition: width 1s;
    -moz-transition: width 1s;
    -webkit-transition: width 1s;
    -o-transition: width 1s;
}

.final-btn.expire-in9::before {
    width: 90%;
}

.final-btn.expire-in8::before {
    width: 80%;
}

.final-btn.expire-in7::before {
    width: 70%;
}

.final-btn.expire-in6::before {
    width: 60%;
}

.final-btn.expire-in5::before {
    width: 50%;
}

.final-btn.expire-in4::before {
    width: 40%;
}

.final-btn.expire-in3::before {
    width: 30%;
}

.final-btn.expire-in2::before {
    width: 20%;
}

.final-btn.expire-in1::before {
    width: 10%;
}

/** END SESIÓN CADUCADA **/

/** TAXI BANNER **/
.taxi-banner,
.taxi-banner + .taxi-alert {
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    
    max-width: 800px;
    margin: 40px auto;
    padding: 18px 30px;

    border: 1px solid var(--site-color);
    background: var(--site-color-light);
    color: var(--site-color);

    font-size: 16px;
}

@media only screen and (min-width:1100px){
    .taxi-banner {
        width:134%;
    }
}

.taxi-banner:first-child {
    margin-top: 0;
}

.taxi-banner:last-child,
.taxi-banner + .taxi-alert:last-child {
    margin-bottom: 0;
}

.taxi-banner > *,
.taxi-banner + .taxi-alert > * {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    line-height: 1em;
}

.taxi-banner > * + *,
.taxi-banner + .taxi-alert > * + * {
    margin-left: 1em;
}

.taxi-banner > p {
    flex: 1;
    text-align: left;
}

.taxi-banner > .taxi-info > * {
    display: block;
    color: var(--grey-color);
    text-align: right;
    line-height: 1.3em;
}

.taxi-banner > .taxi-info > * + * {
    margin-top: 0.5em;
}

.taxi-banner > .link,
.taxi-banner > .btn,
.taxi-banner > .btn:hover,
.taxi-banner > .btn:not(:disabled):hover,
.taxi-banner > .btn:focus,
.taxi-banner > .btn:active {
    display: block;
    width: 140px;
    min-width: 0;
    height: 32px;
    margin: 0 0 0 40px;
    padding: 0;

    text-align: center;
}

.taxi-banner > .link {
    width: auto;
    line-height: 30px;
}

.taxi-banner > .btn > * {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
}

.taxi-banner > .btn > * + * {
    margin-left: 10px;
}

.taxi-banner + .taxi-alert {
    margin-top: -40px;

    background: var(--site-color);
    color: #FFFFFF;
}

.taxi-banner + .taxi-alert::before,
.taxi-banner + .taxi-alert::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: -10px;
    right: 40px;

    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent var(--site-color) transparent;
}

.taxi-banner + .taxi-alert::after {
    z-index: 3;
    top: -9px;
    border-color: transparent transparent var(--site-color) transparent;
}

@media only screen and (max-width: 600px) {
    .taxi-banner {
        display: block;
        text-align: center;
    }

    .taxi-banner > *:first-child,
    .taxi-banner > .taxi-info,
    .taxi-banner > .taxi-info + * {
        margin-left: 0;
    }

    .taxi-banner > .taxi-info,
    .taxi-banner > .taxi-info + * {
        margin-top: 1em;
    }

    .taxi-banner > .taxi-info {
        display: block;
    }

    .taxi-banner > .taxi-info > * {
        text-align: center;
    }

    .taxi-banner > .link {
        display: inline-block;
    }

    .confirm-booking-info .taxi-banner > *,
    .confirm-booking-info .taxi-banner > .btn,
    .confirm-booking-info .taxi-banner > .btn:hover,
    .confirm-booking-info .taxi-banner > .btn:not(:disabled):hover,
    .confirm-booking-info .taxi-banner > .btn:focus,
    .confirm-booking-info .taxi-banner > .btn:active {
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    .confirm-booking-info .taxi-banner > * + * {
        margin-top: 1em;
    }

    .confirm-booking-info .taxi-banner >  * + .btn {
        margin-top: 1.5em;
    }
}

/* taxi banner en el area privada */
.booking-view-room > .taxi-banner,
.booking-view-room > .taxi-banner + .taxi-alert {
    padding: 18px 20px;
    font-size: 14px;
}

.booking-view-room > .taxi-banner {
    margin-top: 12px;
    border: none;
}

/** END TAXI BANNER **/


/** AJUSTES PARA AÑADIR LOS VUELOS **/
.search-result .search-result-header.full-width {
    width: 100%;
}

/* Process stpes banner */
.search-result .search-result-header .motor-process-banner .step {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0;
    padding: 0;

    opacity: 0.5;
    font-size: 16px;
    text-decoration: none;
    color: var(--site-color);
}

.search-result .search-result-header .motor-process-banner .step + .step {
    margin-left: 5em;
}

.search-result .search-result-header .motor-process-banner .step + .step.half::after,
.search-result .search-result-header .motor-process-banner .step + .step::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 26px;
    left: calc(2px - 7em);

    display: block;
    width: calc(9em - 6px);
    height: 2px;
    background-color: var(--site-color);
}

.search-result .search-result-header .motor-process-banner .step .step-title {
    display: block;
    min-width: 7em;
    margin: 0 auto 8px;
    padding: 0;

    font-family: var(--site-primary-title-font);
    font-weight: bold;
}

.search-result .search-result-header .motor-process-banner .step .ico-circle {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 56px;
    margin: 0 auto;
    padding: 0;

    font-size: 0;
    line-height: 0;

    border-radius: 50%;
    border: 1px solid var(--site-color);
}

/* active step */
.search-result .search-result-header .motor-process-banner .step.active {
    opacity: 1;
}

.search-result .search-result-header .motor-process-banner .step.active .ico-circle {
    color: #FFFFFF;
    background-color: var(--site-color);
    cursor:pointer;
}

.search-result .search-result-header .motor-process-banner .step.active .ico-circle:hover {
    background-color: var(--site-color-hover);
}

/* halve active step */
.search-result .search-result-header .motor-process-banner .step + .step.half{
    opacity: 1;
}

.search-result .search-result-header .motor-process-banner .step + .step.half > *,
.search-result .search-result-header .motor-process-banner .step + .step.half::before {
    opacity: 0.5;
}

.search-result .search-result-header .motor-process-banner .step + .step.half::after {
    z-index: 2;
    width: calc(4.5em - 3px);
    opacity: 1;
}

/* link step */
.search-result .search-result-header .motor-process-banner a.step:hover {
    color: var(--site-color-hover);
}

.search-result .search-result-header .motor-process-banner a.step:hover .ico-circle {
    background-color: var(--site-color-hover);
    border-color: var(--site-color-hover);
}

/* END process stpes banner */

/* Search result header */
.search-result .search-result-content .search-result-title {
    display: block;
    margin: 0;
    padding: 15px 0 22px;
    border-bottom: 1px solid #CCD9D6;

    font-family: var(--site-title-font);
    font-size: 25px;
    color: var(--site-color);
    font-weight: 400;
}

.search-content .form-style .add-flights-select-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin: 0;
    padding: 20px 23px;

    text-align: left;
    background: var(--site-color-light);
}

.search-result .search-result-content .add-flights-select-wrapper {
    display: inherit !important;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    margin: 0;
    padding: 20px 23px;

    text-align: left;
    background: var(--site-color-light);
}

@media only screen and (max-width:600px){
    .search-result .search-result-content .add-flights-select-wrapper {
        width:100%;
    }
}

.search-content .form-style .add-flights-select-wrapper > input[type="checkbox"],
.search-result .search-result-content .add-flights-select-wrapper > input[type="checkbox"] {
    display: none;
}

.search-content .form-style .add-flights-select-wrapper .add-flights-select,
.search-content .form-style .add-flights-select-wrapper .add-flights-select,
.search-content .form-style .add-flights-select-wrapper .add-flights-text,
.search-content .form-style .add-flights-select-wrapper .add-flights-select,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
}

.search-content .form-style .add-flights-select-wrapper .add-flights-select,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select {
    position: relative;
    width: 48px;
    height: 24px;
    margin-right: 17px;

    cursor: pointer;

    border: 1px solid var(--site-border-color);
    background-color: #FFFFFF;
    color: #FFFFFF;
    border-radius: 12px;
}

.search-content .form-style .add-flights-select-wrapper .add-flights-select svg,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select svg {
    position: absolute;
    top: 7px;
    left: 7px;

    display: none;
}

.search-content .form-style .add-flights-select-wrapper .add-flights-select::before,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;

    width: 12px;
    height: 12px;

    border-radius: 50%;
    background: var(--site-border-color);

    transition: left 100ms;
    -moz-transition: left 100ms;
    -webkit-transition: left 100ms;
    -o-transition: left 100ms;
}

@media only screen and (min-width:601px){
    .search-content .form-style .add-flights-select-wrapper .add-flights-text,
    .search-result .search-result-content .add-flights-select-wrapper .add-flights-text {
        max-width: calc(100% - 67px);
    }
}

.search-content .form-style .add-flights-select-wrapper .add-flights-text strong,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-text strong {
    color: var(--site-color);
}

/* selected add filghts */
.search-content .form-style .add-flights-select-wrapper > input[type="checkbox"]:checked + .add-flights-select,
.search-content .form-style .add-flights-select-wrapper .add-flights-select.selected,
.search-result .search-result-content .add-flights-select-wrapper > input[type="checkbox"]:checked + .add-flights-select,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select.selected {
    background-color: var(--site-color);
    color: #FFFFFF !important;
}

.search-content .form-style .add-flights-select-wrapper > input[type="checkbox"]:checked + .add-flights-select::before,
.search-content .form-style .add-flights-select-wrapper .add-flights-select.selected::before,
.search-result .search-result-content .add-flights-select-wrapper > input[type="checkbox"]:checked + .add-flights-select::before,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select.selected::before {
    left: 30px;
    background: #FFFFFF;
}

.search-content .form-style .add-flights-select-wrapper > input[type="checkbox"]:checked + .add-flights-select svg,
.search-content .form-style .add-flights-select-wrapper .add-flights-select.selected svg,
.search-result .search-result-content .add-flights-select-wrapper > input[type="checkbox"]:checked + .add-flights-select svg,
.search-result .search-result-content .add-flights-select-wrapper .add-flights-select.selected svg {
    display: inline-block;
}

/* filgths form */
.search-content .form-style .add-flights-wrapper .add-flights-form,
.search-result .search-result-content .add-flights-wrapper .add-flights-form {
    display: block;
    margin: 0;
    padding: 17px 0 0;   
}

.search-result-content .search-result-list .add-flights-wrapper {
    margin-bottom:25px;
}

 /* @media only screen and (min-width:1100px) and (max-width:1199px){
    .search-result .search-result-content .add-flights-wrapper  {
        width:120%;
    }
}

@media only screen and (min-width:1200px){
    .search-result .search-result-content .add-flights-wrapper  {
        width:128%;
    }
} */

.search-content .form-style .add-flights-wrapper .add-flights-form + *,
.search-result .search-result-content .add-flights-wrapper .add-flights-form + * {
    margin-top: 40px;
}

/* only for search form */
.search-content .form-style .add-flights-wrapper {
    margin-bottom: 17px;
}

/* only for search result form */
.search-result .search-result-content .add-flights-wrapper .add-flights-form label {
    color: var(--site-color);
    margin: 0 0 4px;
}

.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group > * {
    display: inline-block;
    vertical-align: middle;
}

.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .airport {
    /*width: calc(100% - 11.2em);*/
    max-width: 250px;
    margin-right: 1em;
}

.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .airport.input-wrapper {
    vertical-align: top;
}

.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .airport input[type="text"] {
    width: 100%;
}

.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .btn {
    background: none;
    color: var(--text-color-light);
    border-color: var(--text-color-light) !important;
    box-shadow: none;
}

.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .btn:hover,
.search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .btn:focus {
    background: none;
    color: var(--site-color);
    border-color: var(--site-color) !important;
}

/* END search result header */

/* Lists header */
.search-result .search-result-content .search-result-list .list-header {
    padding-top: 30px;
    margin-bottom: 2em;
    text-align: left;
}
.search-result .search-result-content .search-result-list .list-header + div{
    display: flex;
    flex-direction: column;
    /*max-width: 750px;*/
    padding-inline: 20px;
    margin-inline: auto;
    gap: 5vh;
}
.search-result .search-result-content .search-result-list .list-header + .checkout {max-width: 750px;}

@media only screen and (min-width:1100px){
    .search-result .search-result-content .search-result-list .list-header {
        width:93%;
    }
}

.search-result .search-result-content .search-result-list .list-header > * {
    display: inline-block;
    vertical-align: bottom;
    margin: 0 28px 0 0;
}

.search-result .search-result-content .search-result-list .list-header > *:last-child {
    margin-right: 0;
}

.search-result .search-result-content .search-result-list .list-header .list-title {
    color: var(--site-color);
    font-family: var(--site-title-font);
    font-weight: normal;
    font-size: 1.7em;
}

.search-result .search-result-content .search-result-list .list-header .list-title svg + span {
    color: var(--text-color);
}

.search-result .search-result-content .search-result-list .list-header .flight-offer {
    color: #60A05F;
}

.search-result .search-result-content .search-result-list .list-header .flight-offer svg {
    display: inline-block;
    vertical-align: middle;
}

/* .search-result .search-result-content .search-result-list .list-header + div {
    display:flex;
} */

.search-result .search-result-content .search-result-list .list-header + div .orderby {
    margin-left:20px;
}

.search-result .search-result-content .search-result-list .list-header + div .orderby select{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--site-color);
    font-weight: bold;
}
/* @media only screen and (max-width:669px){
    .search-result .search-result-content .search-result-list .list-header button,
    .search-result .search-result-content .search-result-list .list-header .orderby {
        margin-top:20px;
    }
} */

.search-result .search-result-content .search-result-list .filters-hotels {
    padding:20px;
    display:flex;
}

.search-result .search-result-content .search-result-list .filters-hotels .filters-prices{
    display: inline-block;
    width: calc(50% - 15px);
}

.search-result .search-result-content .search-result-list .filters-hotels .filters-prices .checkbox-wrapper .checkbox-group {
    margin-bottom:10px;
    margin-top:10px;
}

.search-result .search-result-content .search-result-list .filters-hotels .filters-prices .checkbox-wrapper .checkbox-group label{
    color: var(--text-color);
}


.search-result .search-result-content .search-result-list .filters-hotels .filters-stars{
    display: inline-block;
    width: calc(50% - 15px);
}

.search-result .search-result-content .search-result-list .filters-hotels .filters-stars .checkbox-wrapper .checkbox-group {
    margin-bottom:8px;
    margin-top:8px;
}

.search-result .search-result-content .search-result-list .filters-hotels .filters-stars .golden-star{ 
    color:#F1BD3F;
    font-size:16px;
}



@media only screen and (max-width:500px){
    .search-result .search-result-content .search-result-list .filters-hotels {
        padding: 0px 20px; 
        display:grid;
    }
    .search-result .search-result-content .search-result-list .filters-hotels .filters-prices{
        margin:20px 0px;
        width:100%
    }

    .search-result .search-result-content .search-result-list .filters-hotels .filters-stars{
        margin:20px 0px;
        width:100%
    }
}

/* END Lists header */

/* No Flights message */
.search-result .search-result-content .add-flights-wrapper .no-flights {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 35px 30px 30px 35px;
    background: var(--site-color-light);
    color: var(--site-color);
}

.search-result .search-result-content .add-flights-wrapper .no-flights svg {
    width: 20px;
    min-width: 20px;
    height: auto;
    margin: 3px 1em 0 0;
}

.search-result .search-result-content .add-flights-wrapper .no-flights .info-wrapper p {
    margin: 0 0 12px 4px;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-color);
}

.search-result .search-result-content .add-flights-wrapper .no-flights .info-wrapper label {
    color: var(--site-color) !important;
}

/* END No Flights message */

/* Flight card */
.search-result .search-result-content .search-result-list .flight-wrapper {
    border: none;
    border-radius: 0;
    box-shadow: none;

    padding-bottom: 2em;
    border-bottom: 1px solid var(--site-border-color);
    margin-bottom: -10px;
}

.search-result .search-result-content .search-result-list .flight-wrapper::after {
    content: '';
    display: block;
    clear: both;
}

.select-flights-popup-content .flights-list-wrapper .flight-card,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card {
    overflow: hidden;
    display: inline-block;
    vertical-align: top;

    width: calc(50% - 15px);
    margin: 0 0 16px;
    padding: 0;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid var(--site-border-color);
    border-radius: 4px;
}

.booking-view-contain .booking-view-info .flight-card {
    width: 99% !important;
}

.select-flights-popup-content .flights-list-wrapper .flight-card {
    display: block;
    width: 99% !important;

    cursor: pointer;
}

.select-flights-popup-content .flights-list-wrapper .flight-card:not(.selected):hover {
    border-color: var(--site-color);
} 

.select-flights-popup-content .flights-list-wrapper .flight-card.selected,
.select-flights-popup-content .flights-list-wrapper .flight-card.selected .flight-card {
    cursor: default;
    border-color: var(--site-color)  !important;
}

.select-flights-popup-content .flights-list-wrapper .flight-card.selected .combined-header .circulo,
.select-flights-popup-content .flights-list-wrapper .flight-card.selected .combinable-header .circulo {
    background-color: var(--site-color) !important;
    border-color: var(--site-color)  !important;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .combined-header .price .promoted,
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header .price .promoted {
    text-align:center;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .combined-header .price .promoted,
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header .price .promoted,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header .date .promoted  {
    /* display: flex;
    justify-content: center; */
    padding: 7px;
    font-weight:bold;
    text-transform:uppercase;
    background-color: #9AE4D6;
    font-size:10px;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .combined-header .price span:last-child {
    white-space: nowrap;
}

.seleccionado {
    display:none;
    margin: 0;
    font-family: var(--site-font);
    font-weight: 700;
    font-size: 16px;
    color: var(--site-color);
}

.select-flights-popup-content .flights-list-wrapper .flight-card.selected .seleccionado {
    display:block;
}

.select-flights-popup-content .flights-list-wrapper .flight-card.selected .price{
    display:none;
}

.select-flights-popup-content .flights-list-wrapper .flight-card:not(.selected) .price {
    display:block;
}

.select-flights-popup-content .flights-list-wrapper .flight-card:not(.selected) .seleccionado {
    display:none;
}

.select-flights-popup-content .flights-list-wrapper .flight-card:not(.selected) .flight-card.selected {
    cursor: default;
}

.search-result .search-result-content .search-result-list .flight-wrapper .flight-card.outbound {
    float: left;
}

.search-result .search-result-content .search-result-list .flight-wrapper .flight-card.inbound {
    float: right;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 19px;
}

/* Fligh card area de clientes*/
.booking-view-contain .booking-view-info .flight-card {
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 15px);
    margin: 0 0 16px;
    padding: 0;
    border-radius: 4px;
}

.booking-view-contain .booking-view-info .flight-card .flights .flight-card.outbound,
.booking-view-contain .booking-view-info .flight-card .flights .flight-card.inbound {
    border: 1px solid var(--site-border-color);
}

.booking-view-contain .booking-view-info .flight-card .flight-card-header,
.booking-view-contain .booking-view-info .flight-card .flight-itinerary,
.booking-view-contain .booking-view-info .flight-card .flight-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 19px;
}

.booking-view-contain .booking-view-info .flight-card .flight-card-header{
    border-bottom: 1px solid var(--site-border-color);
    background-color:#FFFFFF;
}

.booking-view-contain .booking-view-info .flight-card .flight-card-header .type svg{
    margin-right:8px;
    vertical-align:middle;
}

.booking-view-contain .booking-view-info .flight-card .flights .flight-card .flight-info ul,
.booking-view-contain .booking-view-info .flight-card .flights .flight-card .flight-info li {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1em;
}

.booking-view-contain .booking-view-info .flight-card .flight-itinerary{
    text-align:center;
    background: #F2F5F5;
}

.booking-view-contain .booking-view-info .flight-card .flight-card-header .type,
.booking-view-contain .booking-view-info .flight-card .flight-card-header .date,
.booking-view-contain .booking-view-info .flight-card .flight-card-header .airline,
.booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-departure,
.booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-stops,
.booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-arrival {
    width:33%;
}

.booking-view-contain .booking-view-info .flight-card .flight-info .included {text-align:left;}
.booking-view-contain .booking-view-info .flight-card .flight-info .extra-info {text-align:right;}

.booking-view-contain .booking-view-info .flight-card .flight-info .included,
.booking-view-contain .booking-view-info .flight-card .flight-info .extra-info{
    width:50%;
}


.booking-view-contain .booking-view-info .flight-card .flight-card-header .type {float:left}
.booking-view-contain .booking-view-info .flight-card .flight-card-header .date {text-align:center}
.booking-view-contain .booking-view-info .flight-card .flight-card-header .airline {float:right}

.booking-view-contain .booking-view-info .flight-card .flight-info {
    background-color:#FFFFFF;
}

.booking-view-contain .booking-view-info .flight-card .flight-info,
.booking-view-contain .booking-view-info .flight-card .flight-itinerary {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 19px;
}

@media only screen and (max-width: 500px) {
    .booking-view-contain .booking-view-info .flight-card .flight-itinerary {
        display:grid;
    }

    .booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-departure,
    .booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-stops,
    .booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-arrival {
        width:100%;
        margin-bottom:10px;
    }
}

.booking-view-contain .booking-view-info .flight-card .flight-card-header .date,
.booking-view-contain .booking-view-info .flight-card .flights .flight-card, .flight-info li ,
.booking-view-contain .booking-view-info .flight-card .flight-itinerary .flight-stops {
    color: var(--site-color);
}

.booking-view-contain .booking-view-info .flight-card .flight-card-header .type,
.booking-view-contain .booking-view-info .flight-card .flights .flight-card, .flight-info ul span {
    color: var(--text-color);
}

.booking-view-contain .booking-view-info .flight-card .popup-content .flight-info-segments {width:100%}

.booking-view-contain .booking-view-info .flight-card .popup-content .flight-info {
    border-top: 1px solid var(--site-border-color);
}
/* Fin fligh card area de clientes*/

/* Modal aea de clientes*/
.MuiBox-root {border:none !important;}

.MuiBox-root > p {
    text-align:right;
    margin-bottom: 10px;
    font-size: 20px;
}

.MuiBox-root > p > a {
    padding: 4px;
    border: 1px solid var(--site-border-color);
    border-radius: 4px;
}

.MuiBox-root .flight-card.combined .flights{
    display:flex;
    justify-content: center;
}

.MuiBox-root .flight-card.combined article {
    border: 1px solid var(--site-border-color);
    border-radius: 4px;
    margin-bottom: 10px;
}

.MuiBox-root .flight-card .flight-card-header {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 19px;
    border-bottom: 1px solid var(--site-border-color);
}

.MuiBox-root .flight-card .flight-card-header .date{
    text-align:center;
    color:var(--site-color);
}

.MuiBox-root .flight-card .flight-info {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-align: center;
    padding:19px;
}

.MuiBox-root .flight-card .flight-info .included,
.MuiBox-root .flight-card .flight-info .extra-info {
    width:50%;
}

.MuiBox-root .flight-card .flight-info ul {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1em;
    text-decoration:none;
}

.MuiBox-root .flight-card .flight-info ul li a {text-decoration:none;}

.MuiBox-root .flight-card .flight-info .included {text-align:left;}
.MuiBox-root .flight-card .flight-info .extra-info {text-align:right;}

.MuiBox-root .flight-card .flight-itinerary{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 19px;
    background: #F2F5F5;
    text-align:center;
}

.MuiBox-root .flight-card .flight-itinerary .flight-departure{
    width:33%;
    float:left;
}
.MuiBox-root .flight-card .flight-itinerary .flight-stops {
    width:33%;
    text-align:center;
    color:var(--site-color);
}
.MuiBox-root .flight-card .flight-itinerary .flight-arrival{
    width:33%;
    float:right;
}

@media only screen and (min-width:501px){

    .MuiBox-root .flight-card .flight-card-header {display:flex;}

    .MuiBox-root .flight-card .flight-card-header .type,
    .MuiBox-root .flight-card .flight-card-header .date,
    .MuiBox-root .flight-card .flight-card-header .airline {width:33%}

    .MuiBox-root .flight-card .flight-itinerary{display:flex;}

    .MuiBox-root .flight-card .flight-itinerary .flight-departure{
        width:33%;
        float:left;
    }
    .MuiBox-root .flight-card .flight-itinerary .flight-stops {
        width:33%;
        text-align:center;
    }
    .MuiBox-root .flight-card .flight-itinerary .flight-arrival{
        width:33%;
        float:right;
    }
}

@media only screen and (max-width:500px){
    .MuiBox-root {
        zoom:75%;
    }
    
    .MuiBox-root .flight-card .flight-card-header {
        display:block;
        text-align:center;
        padding:0px;
    }

    .MuiBox-root .flight-card .flight-card-header > * {margin:7px 0px;}

    .MuiBox-root .flight-card .flight-card-header .type,
    .MuiBox-root .flight-card .flight-card-header .date,
    .MuiBox-root .flight-card .flight-card-header .airline {width:100%}

    .MuiBox-root .flight-card .flight-itinerary{
        display:grid;
        padding:9px;
    }

    .MuiBox-root .flight-card .flight-itinerary > *{margin:7px 0px;}

    .MuiBox-root .flight-card .flight-itinerary .flight-departure,
    .MuiBox-root .flight-card .flight-itinerary .flight-stops,
    .MuiBox-root .flight-card .flight-itinerary .flight-arrival {width:100%;}
    
}

@media only screen and (max-width:500px){
    .MuiBox-root .flight-card .flight-card-itinerary {
        display:block;
    }
}

@media only screen and (min-width:801px){
    #box {
        width:70% !important;
    }
} 

@media only screen and (max-width:800px){
    #box {
        width:300px !important;
    }
    .MuiBox-root .flight-card.combined .flights {
        display: block;
    }
}

@media (orientation: landscape) and (max-width:800px){
    .MuiBox-root {
        width:85% !important;
    }

    .MuiBox-root .flight-card.combined .flights {
        display: flex;
    }
} 

/*Fin modal area de clientes*/

.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary .flight-stops,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary .flight-stops {
    color: var(--site-color) !important;
}

@media only screen and (max-width:500px){
    .search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary {
        display:grid;
        padding:10px;
    }

    .search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary .flight-departure,
    .search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary .flight-stops {
        margin-bottom:10px;
    }
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid var(--site-border-color);
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header > *,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info > *,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary > *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header > *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info > *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary > * {
    display: inline-block;
    vertical-align: middle;
    flex: 1;
    padding: 0;
    text-align: center;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header > * + *,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info > * + *,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info .info-link + *,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary > * + *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header > * + *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info > * + *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info .info-link + *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary > * + * {
    padding-left: 1em;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header > *:first-child,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info > *,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header > *:first-child,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info > * {
    text-align: left;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header > *:last-child,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info > *:last-child,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header > *:last-child,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info > *:last-child {
    text-align: right;
}

.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info .info-link,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info .info-link,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header .type svg,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header .date,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header .type svg,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header .date {
    display: inline-block;
    vertical-align: middle;
    color: var(--site-color);
    text-decoration: none;
}

.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info .info-link{
    white-space:nowrap;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header .type svg,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header .type svg {
    margin-right: 8px;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header .date,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header .date {
    font-weight: 700;
    white-space:nowrap;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-card-header .airline img,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header .airline img {
    margin-right: 0;
    max-height: 30px;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info ul,
.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info li,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info ul,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info li {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1em;
    color: var(--site-color);
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info ul li,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info ul li {
    margin-left: 0.5em;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-info ul li span,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info ul li span{
    color: var(--text-color);
}

.select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary {
    background: #F2F5F5;
}

/* combined flight card */
.select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header,
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header {
    display: flex;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header > div:first-child, 
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header .price,
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header .seleccionado {
    width:50%;
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header .circulo,
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header .circulo{
    background-color: transparent;
    width: 20px;
    height: 20px;
    border: 1px solid var(--site-border-color);
    border-radius: 50%;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header {
    padding:10px;
    border-bottom: 1px solid var(--site-border-color);
}

.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-card-header,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-info,
.search-result .search-result-content .search-result-list .flight-wrapper .flight-card .flight-itinerary{
    cursor:default;
}

.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header p {
    text-align:right;
}


@media only screen and (max-width:599px){
    
    .select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header {
        display: flex;
        flex-wrap:wrap;
    }
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header > * {
    flex: 1;
    padding: 14px;
    border-bottom: 1px solid var(--site-border-color);
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header > *:last-child{
    text-align: right;
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header .combined-name {
    margin: 0;

    font-family: var(--site-primary-title-font);
    font-weight: 700;
    font-size: 16px;
    color: var(--text-color);
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .combined-header .price,
.select-flights-popup-content .flights-list-wrapper .flight-card .combinable-header .price {
    margin: 0;

    font-family: var(--site-font);
    font-weight: 700;
    font-size: 16px;
    color: var(--site-color);
    text-align: right;
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .flight-card {
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.select-flights-popup-content .flights-list-wrapper .flight-card.combined .flight-card + .flight-card {
    border-top: 1px solid var(--site-border-color);
}

/* selected flight footer */
.search-result .search-result-content .search-result-list .flight-wrapper .selected-flight-footer {
    clear: both;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-result .search-result-content .search-result-list .flight-wrapper .selected-flight-footer > *:first-child {
    text-align: left;
}

.search-result .search-result-content .search-result-list .flight-wrapper .selected-flight-footer > *:last-child {
    flex: 1;
    text-align: right;
}

.search-result .search-result-content .search-result-list .flight-wrapper .selected-flight-footer .price-box .price {
    font-weight: bold;
    font-size: 2em;
    color: var(--site-color);
}

/* changes in select flights popup and flight info popup */
.select-flights-popup-wrapper,
.flight-info-popup-wrapper {
    padding: 33px 33px 40px;
}

.select-flights-popup-wrapper .select-flights-popup-header .title,
.flight-info-popup-wrapper .flight-info-popup-header .title {
    display: block;
    margin: 0 !important;
    padding: 0 0 30px !important;

    text-align: center;

    min-height: 2em;
    font-family: var(--site-title-font);
    font-weight: normal !important;
    font-size: 25px !important;
    color: var(--site-color);
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-type-selector {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 0 18px;
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-type-selector > * {
    flex: 1;
    text-align: center;

    opacity: 1;
    color: var(--site-color);
    border-bottom: 3px solid var(--site-color);
    background: var(--site-color-light);
    padding:10px;
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-type-selector > :not(.selected) {
    /* opacity: 0.2; */
    cursor: pointer;
    border-bottom: 1px solid var(--site-color);
    background-color:transparent;
    color:var(--site-color);
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-type-selector .type span {
    color: var(--text-color);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-list-wrapper {
    /*max-height: calc(100vh - 50em);*/
    overflow: auto;
    padding: 0 20px 0 0;
}

@media only screen and (max-width:778px) {
    .select-flights-popup-wrapper .select-flights-popup-content .flights-list-wrapper {
        padding:0px;
    }
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-list-wrapper::-webkit-scrollbar { /* scroll width */
    width: 6px;
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-list-wrapper::-webkit-scrollbar-track { /* scroll Track */
    box-shadow: inset 0 0 5px var(--site-color-light);
    border-radius: 7px;
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-list-wrapper::-webkit-scrollbar-thumb { /* scroll Handle */
    background: var(--site-color);
    border-radius: 7px;
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-list-wrapper::-webkit-scrollbar-thumb:hover { /* Handle on hover */
    background: var(--site-color-hover);
}

.select-flights-popup-wrapper .select-flights-popup-content .flights-wrapper .title {
    margin: 0 0 1em;
    text-align: center;

    color: var(--site-color);
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
}

.select-flights-popup-wrapper .select-flights-popup-footer {
    padding: 18px 0 0;
    position: sticky;
    bottom: 7px;
    z-index: 1055;
}

.select-flights-popup-wrapper .select-flights-popup-footer .btn-next,
.select-flights-popup-wrapper .select-flights-popup-footer .btn-save {
    display: block;
    width: 100%;

    color: #FFFFFF;
    background-color: var(--site-color);
    border-color: var(--site-color);
}

.select-flights-popup-wrapper .select-flights-popup-footer .btn-next:hover,
.select-flights-popup-wrapper .select-flights-popup-footer .btn-next:focus,
.select-flights-popup-wrapper .select-flights-popup-footer .btn-save:hover,
.select-flights-popup-wrapper .select-flights-popup-footer .btn-save:focus {
    color: #FFFFFF;
    background-color: var(--site-color-hover);
    border-color: var(--site-color-hover);
}

.select-flights-popup-wrapper .select-flights-popup-footer::after {
    content: '';
    display: block;
    clear: both;
}

.select-flights-popup-wrapper .select-flights-popup-footer .btn-prev,
.select-flights-popup-wrapper .select-flights-popup-footer .btn-prev:not(.hidden) + .btn-save {
    display: inline-block;
    width: calc(50% - 3px);
    vertical-align: middle;
}

.select-flights-popup-wrapper .select-flights-popup-footer .btn-prev:not(.hidden) + .btn-save {
    float: right;
}

.select-flights-popup-wrapper .select-flights-popup-footer .btn.hidden {
    display: none;
}

/* flight info popup */
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

@media only screen and (min-width:600px){
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-header > *,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header > *,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary > *:first-child,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary > *:last-child,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale > *,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-luggage > * {
        flex: 1;
        color: var(--text-color);
    }
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header > *:last-child,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header > *:last-child,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary > *:last-child,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale > *:last-child {
    text-align: right;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header > *:first-child img {
    margin-left: 0;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header > *:last-child img {
    margin-right: 0;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-luggage,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-baggage,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale {
    padding-bottom: 18px;
    border-bottom: 1px solid var(--site-border-color);
    margin-bottom: 18px;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary {
    padding-bottom: 18px;
    margin-bottom: 18px;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper {
    display:flex;
}
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper p{ width:50%}
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .airline{width:50%;}
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .airline img {float:right;}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-luggage .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-baggage .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-rate .title {
    color: var(--grey-color);

    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-luggage .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-baggage .title,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-rate .title {
    margin-bottom: 10px;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type {
    text-align:left;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type svg,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type span {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    color: var(--text-color);
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type svg,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary svg {
    color: var(--site-color);
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .type svg {
    margin-right: 5px;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header .flight-id {
    font-weight: bold;
}

.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-info-header,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment,
.flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale {
    text-align:left;
}

@media only screen and (max-width: 599px){
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-header > *,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-header > *,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary > *:first-child,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary > *:last-child,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale > *,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-luggage > * {
        color: var(--text-color);
    }
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-itinerary {
        padding-bottom: 18px;
        margin-bottom: 18px;
    }
    
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper {
        display:initial;
    }
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper p{ width:100%}
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .airline{width:100%;}
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-header .flight-type-wrapper .airline img {float:left;}

    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-info-header,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-header,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-itinerary,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale {
        display: flex;
        flex-wrap: wrap;
    } 

    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-info-header .flight-type-wrapper,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-info-header .flight-airline-wrapper,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-header .title,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-header .flight-id,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-itinerary .flight-segment-outbound,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-itinerary svg,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment .flight-segment-itinerary .flight-segment-inbound,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale .title,
    .flight-info-popup-wrapper .flight-info-popup-content .flight-info-segments .flight-segment-scale .time {
        position: relative;
        width: 100%;
        /* padding-right: 15px;
        padding-left: 15px; */
        text-align:left;
        margin-top:10px;
    }
}

@media only screen and (min-width: 991px) {
    /* outbound and inbound flights in columns */
    .select-flights-popup-wrapper .select-flights-popup-content .flights-wrapper .title {
        text-align: left;
    }

    .select-flights-popup-wrapper .select-flights-popup-footer .btn-next,
    .select-flights-popup-wrapper .select-flights-popup-footer .btn-prev{
        display: none !important;
    }

    .select-flights-popup-wrapper .select-flights-popup-footer .btn-save {
        display: block !important;
        width: 100% !important;
        margin: 0 !important;
    }

    .select-flights-popup-wrapper .select-flights-popup-content .combinable-wrapper > .flights-wrapper {
        display: inline-block !important;
        width: calc(50% - 30px);
        padding-right: 13px;
        vertical-align:top;
    }

    .select-flights-popup-wrapper .select-flights-popup-content .combinable-wrapper > .flights-wrapper + .flights-wrapper {
        margin-left: 30px;
    }

    .select-flights-popup-wrapper .select-flights-popup-footer .btn-save {
        display: block !important;
    }

    .select-flights-popup-wrapper .select-flights-popup-content .combinable-wrapper > .flights-type-selector > * {
        opacity: 1 !important;
        cursor: default !important;
    }
    /* in combined */
    .select-flights-popup-content .flights-list-wrapper .flight-card.combined .flights,
    .booking-view-contain .booking-view-info .flight-card .flights {
        display: flex;
    }

    .select-flights-popup-content .flights-list-wrapper .flight-card.combined .flight-card + .flight-card {
        border-top: none;
        border-left: 1px solid var(--site-border-color);
    }

    /* .select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary .flight-arrival .flight-arrival-airport,
    .select-flights-popup-content .flights-list-wrapper .flight-card .flight-itinerary .flight-departure .flight-departure-airport,
    .flight-card.outbound .flight-itinerary .flight-departure .flight-departure-airport,
    .flight-card.inbound .flight-itinerary .flight-departure .flight-departure-airport,
    .flight-card.outbound .flight-itinerary .flight-arrival .flight-arrival-airport,
    .flight-card.inbound .flight-itinerary .flight-arrival .flight-arrival-airport {
        white-space:nowrap;
    } */


    
}

/* END Flight card */

/* New lateral car */
.process-cart {
    box-shadow: none;
    border: 1px solid var(--site-border-color);
}

.process-cart div.cart-content {
    max-height: calc(100vh - 38em);
    /* min-height: 13.5em; */
    overflow: auto;
}

@media only screen and (max-width: 1099px) {
    .process-cart div.cart-content-only-mobile {
        display:block;
        min-height: 0;
    }
}

@media only screen and (max-width: 1099px) {
    .process-cart.opened > div.cart-content-only-mobile::after, .process-cart div.cart-content-only-mobile .dropdown-cart-header::after {
        transform: rotate(-180deg);
    }
}
@media only screen and (max-width: 1099px) {
    .process-cart > div.cart-content-only-mobile::after {
        top: auto;
        bottom: 1.5em;
    }
}
.process-cart > div.cart-content-only-mobile::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 60px;
    display: block;
    width: 8px;
    height: 5px;
    margin: -3px 0 0;
    box-sizing: border-box;
    border-style: solid;
    border-color: transparent transparent var(--site-color) transparent;
    border-width: 0 4px 4px 4px;
    transition: all 100ms;
    -moz-transition: all 100ms;
    -webkit-transition: all 100ms;
    -o-transition: all 100ms;
} 

.checkout + * .search-result-aside .process-cart div.cart-content {
    max-height: calc(100vh - 34em);
}

.process-cart div.cart-content .dropdown-cart-header,
.process-cart > .process-cart-header {
    border-bottom: 1px solid var(--site-border-color);
}

.process-cart div.cart-content .dropdown-cart-header {
    background: none;
}

.process-cart > .process-cart-header .title,
.process-cart div.cart-content .dropdown-cart-header .title {
    color: var(--site-color) !important;
    font-family: var(--site-primary-title-font);
    font-weight: normal;
    font-size: 1.35em;
}

.process-cart div.cart-content .dropdown-cart-header .title svg{
    float:right;
}

.process-cart div.cart-content .flights-list .flights-list-header .header-info,
.process-cart div.cart-content .rooms-list .rooms-list-header .header-info,
.process-cart div.cart-content .extras-list .extras-list-header  {
    border-bottom: 1px solid var(--site-color-light);
    margin-bottom: 17px;
    cursor:pointer;
}


.process-cart div.cart-content .flights-list .flights-list-header .header-info::after,
.process-cart div.cart-content .rooms-list .rooms-list-header .header-info::after,
.process-cart div.cart-content .extras-list .extras-list-header .header-info::after,
.process-cart div.cart-content .extras-list .cart-extra::after {
    content: '';
    display: block;
    clear: both;
}

.process-cart div.cart-content .flights-list .flights-list-header .header-info .title,
.process-cart div.cart-content .rooms-list .rooms-list-header .header-info .title,
.process-cart div.cart-content .extras-list .extras-list-header .header-info .title,
.process-cart div.cart-content .extras-list .cart-extra .title {
    float: left;

    display: block;
    margin: 0;
    padding: 0 5px 10px 0;

    font-family: var(--site-primary-title-font);
}

.process-cart div.cart-content .flights-list .flights-list-header .header-info p:last-child {
    float:right;
    color:var(--text-color-light);
}

.process-cart div.cart-content .flights-list .flights-list-header .header-info .price,
.process-cart div.cart-content .rooms-list .rooms-list-header .header-info .price,
.process-cart div.cart-content .extras-list .extras-list-header .header-info .price,
.process-cart div.cart-content .extras-list .cart-extra .price {
    float: right;

    display: block;
    margin: 0;
    padding: 0 5px 10px 0;

    font-size: 16px;
    font-weight: bold;
    color: var(--site-color);
}

.process-cart div.cart-content .extras-list .extras-list-header > p {
    text-align:right;
    color: var(--text-color-light);
    margin-bottom:7px;
}

.process-cart div.cart-content .extras-list .cart-extra .price {
    font-size: 1em;
    font-weight: normal;
}

.process-cart div.cart-content .extras-list .cart-extra p:first-child { float:left}

.process-cart div.cart-content .extras-list .cart-extra p:last-child {float:right}

.process-cart div.cart-content .flights-list {
    border-bottom: 1px solid var(--site-border-color);
}

.process-cart div.cart-content .flights-list > div:last-child > table:first-child {
    border-right: 1px solid var(--site-color-light);
}

.process-cart div.cart-content .flights-list .cart-flight {
    /* margin-top:16px; */
    padding: 0px 10px;
}

.process-cart div.cart-content .flights-list .cart-flight .airline img{
    margin:0;
}

.process-cart div.cart-content .flights-list .cart-flight .date {
    padding-left: 5px;
    padding-right: 5px;
    color: var(--site-color);
    white-space:nowrap;
    font-weight:bold;
}

.process-cart div.cart-content .flights-list .cart-flight .airports,
.process-cart div.cart-content .flights-list .cart-flight .stops, 
.process-cart div.cart-content .flights-list .cart-flight .see-more,
.process-cart div.cart-content .flights-list .cart-flight .luggage {
    padding-left: 5px;
}

.process-cart div.cart-content .flights-list .cart-flight .stops {
    color:var(--site-color);
}

.process-cart div.cart-content .flights-list .cart-flight th, 
.process-cart div.cart-content .flights-list .cart-flight td { 
    text-align:left;
}

.process-cart div.cart-content .promo-banner,
.mobile-booking-info .basic-info .promo-banner {
    width: calc(100% - 18px);
    margin-left: -9px;
    margin-right: -9px;
    padding: 10px 18px;
    background: rgba(223, 242, 223, 0.35);
    color: var(--site-color)
}

.process-cart div.cart-content .promo-banner::after,
.mobile-booking-info .basic-info .promo-banner::after {
    content: '';
    display: block;
    clear: both;
}

.process-cart div.cart-content .promo-banner p,
.mobile-booking-info .basic-info .promo-banner p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: calc(100% - 65px);
}

.process-cart div.cart-content .promo-banner .discount-price,
.mobile-booking-info .basic-info .promo-banner .discount-price {
    max-width: 65px;
    float: right;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    color: #60A05F;
}

.process-cart div.cart-content .promo-banner p span,
.process-cart div.cart-content .promo-banner p svg,
.mobile-booking-info .basic-info .promo-banner p span,
.mobile-booking-info .basic-info .promo-banner p svg {
    display: inline-block;
    vertical-align: middle;
    max-width: calc(100% - 25px);
}

.process-cart div.cart-content .promo-banner p svg,
.mobile-booking-info .basic-info .promo-banner p svg {
    max-width: 16px;
    height: auto;
    margin-right: 5px;
}

.process-cart .process-cart-content {
    background: none;
    border-top: 1px solid var(--site-border-color);
}

.process-cart .process-cart-content > .cart-price {
    display: block;
    background: none;
    border: none;
}

.process-cart .process-cart-content > .cart-price::after {
    content: '';
    display: block;
    clear: both;
}

.process-cart .process-cart-content > .cart-price + .cart-price {
    position: relative;
}

.process-cart .process-cart-content > .cart-price + .cart-price::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;

    display: block;
    width: calc(100% - 40px);
    height: 1px;
    margin: 0;
    padding: 0;

    background: var(--site-color-light);
}

.process-cart .process-cart-content > .cart-price > h5 {
    float: left;
    padding-right: 5px;

    font-size: 16px;
    color: var(--text-color);
}

.process-cart-content .cart-price > th > h5 {
    font-size:16px;
    color: var(--text-color);
}

.process-cart .process-cart-content > .cart-price > h5 span {
    font-weight: normal;
    font-size: 12px;
}

.process-cart .process-cart-content > .cart-price .price {
    float: right;
    padding-left: 5px;

    color: var(--site-color);
}

.process-cart .process-cart-content > .cart-price .price strong:not(.final) {
    font-size: 16px;
}

@media only screen and (max-width: 1099px) {
    .process-cart div.cart-content {
        max-height: calc(100vh - 25em);
    }

    .process-cart .process-cart-content {
        border-top: 1px solid var(--site-color-light);
        background: #FFF;
    }

    .process-cart .process-cart-content > .cart-price {
        padding-top: 10px;
        padding-bottom: 0;
    }

    .process-cart .process-cart-content > .cart-price:last-child {
        padding-bottom: 10px;
    }

    .process-cart .process-cart-content > .cart-price > h5,
    .process-cart .process-cart-content > .cart-price .price strong:not(.final) {
        font-size: 12px;
    }

    .process-cart .process-cart-content > .cart-price::before,
    .process-cart .process-cart-content > .cart-price:first-child {
        display: none !important;
    }

    .process-cart > .process-cart-header {
        border: none;
    }
}

@media only screen and (min-width:1100px) and (max-width:1160px){
    .search-result .search-result-header .motor-process-banner .step .step-title{
        min-width:5em;
    } 
}

@media only screen and (min-width:741px) and (max-width:779px){
    .search-result .search-result-header .motor-process-banner .step .step-title{
        min-width:5em;
    } 
}

@media only screen and (max-width: 740px) {
    /* Mobile process steps version */
    .search-result .search-result-header .motor-process-banner .step .step-title {
        display: none;
    }

    .search-result .search-result-header .motor-process-banner .step .ico-circle {
        width: 36px;
        height: 36px;
    }

    .search-result .search-result-header .motor-process-banner .step .ico-circle svg {
        width: 15px;
        height: auto;
    }

    .search-result .search-result-header .motor-process-banner .step + .step {
        margin-left: 0;
        padding-left: 53px;
    }

    .search-result .search-result-header .motor-process-banner .step + .step::before,
    .search-result .search-result-header .motor-process-banner .step + .step.half::after {
        bottom: 50%;
        left: -2px;
        width: 55px;
    }

    .search-result .search-result-header .motor-process-banner .step + .step.half::after {
        width: 28px;
    }

    /* Mobile flight card */
    .search-result .search-result-content .search-result-list .flight-wrapper .flight-card.outbound,
    .search-result .search-result-content .search-result-list .flight-wrapper .flight-card.inbound {
        float: none;
        width: 98%;
    }

    .search-result .search-result-content .search-result-list .flight-wrapper .flight-card.inbound {
        margin-top: 0;
    }
}

@media only screen and (max-width: 420px) {
    .search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .airport,
    .search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .btn {
        display: block;
        width: 100%;
    }

    .search-result .search-result-content .add-flights-wrapper .add-flights-form .input-group .airport {
        max-width: none;
        margin-right: 0;
        margin-bottom: 16px;
    }
}

/* END New lateral car */

/* tooltip info */
.tooltip-linck {
    position: relative;
}

.tooltip-linck .tooltip-info {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;

    display: none !important;
    min-width: 190px;
    padding: 7px 0 0;
    font-weight: normal;
    color: var(--text-color);
}

.tooltip-linck:hover .tooltip-info {
    display: block !important;
}

.tooltip-linck .tooltip-info:before,
.tooltip-linck .tooltip-info:after {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: calc(100% - 8px);
    left: 15px;

    display: block;
    height: 0;
    width: 0;

    pointer-events: none;
    border: solid transparent;
}

.tooltip-linck .tooltip-info:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #FFF;
    border-width: 7px;
    margin-left: -7px;
}

.tooltip-linck .tooltip-info:before {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: var(--site-border-color);
    border-width: 8px;
    margin-left: -8px;
}

.tooltip-linck .tooltip-info .tooltip-header,
.tooltip-linck .tooltip-info .tooltip-body {
    position: relative;
    z-index: 1;
    display: block;
    width: calc(100% - 60px);
    margin: 0;
    padding: 20px 30px;
    border: 1px solid var(--site-border-color);
    background: #FFFFFF;
}

.tooltip-linck .tooltip-info .tooltip-header {
    border-bottom: none;
}

.tooltip-linck .tooltip-info .tooltip-header::after {
    content: '';
    display: block;
    clear: both;
}

.tooltip-linck .tooltip-info .tooltip-header > * + *:last-child {
    float: right;
}

.tooltip-linck .tooltip-info .tooltip-body:last-child {
    padding-bottom: 35px;
}

.tooltip-linck .tooltip-info .tooltip-body {
    color: var(--text-color-light);
}

.tooltip-linck .tooltip-info .tooltip-body > span {
    display: block;
}

.tooltip-linck .tooltip-info .tooltip-body > span + span {
    margin-top: 10px;
}

.tooltip-linck .tooltip-info .tooltip-header svg,
.tooltip-linck .tooltip-info .tooltip-body svg {
    display: inline-block;
    vertical-align: middle;
    color: var(--site-color);
    width: 1em;
    height: auto;
}

.tooltip-linck .tooltip-info .tooltip-header svg + span,
.tooltip-linck .tooltip-info .tooltip-body svg + span {
    display: inline-block;
    vertical-align: middle;
}

/* END tooltip info */
/** END AJUSTES PARA AÑADIR LOS VUELOS **/

/** PÁGINA DE EXTRAS **/
.no-extras {
    position: relative;
    display: flex;
    align-items: flex-start;

    margin: 30px 0 25px;
    padding: 35px 30px 30px 35px;

    background: var(--site-color-light);
    color: var(--site-color);
    border: none;
    box-shadow: none;
}

.no-extras svg {
    margin-right: 5px;
}

.extra-card {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 25px;
    padding: 24px 20px 24px 24px;

    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
    border: 1px solid var(--site-border-color);
}

@media only screen and (min-width: 1100px){
    .extra-card {
        width:142%;
    }

    .extra-footer {
        width:142%;
    }
}

.extra-card.selected {
    border-color: var(--site-color);
}

.extra-card:first-child {
    margin-top: 30px;
}

.extra-card svg {
    color: var(--site-color);
}

.extra-card .extra-info {
    flex: 1;
    padding-left: 25px;
    padding-right: 25px;
}

.extra-card .extra-info .name {
    font-family: var(--site-title-font);
    font-size: 16px;
    font-weight: normal;
    color: var(--site-color);
}


.MuiAccordionDetails-root {
    display: grid !important;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded,
.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    width:100%
}


@media only screen and (min-width:600px){
    .extra-card .extras-ida {
        border-bottom: 1px solid var(--site-border-color);
    }

    .extra-card .extras-ida,
    .extra-card .extras-vuelta {
        padding:20px !important;
    }
}

.extra-card .extras-ida,
.extra-card .extras-vuelta {
    padding:10px;
}

.extra-card .extras-ida .title,
.extra-card .extras-vuelta .title {
    font-weight:bold;
    color:var(--site-color);
}

.extra-card .bottom-info {
    align-items: center;
    margin-top:12px;
}

.extra-card .extra-text {
    width:64%;
}

.extra-card .extra-price {
    padding-right: 30px;
    text-align: right;
    width:20%;
}

.extra-card .btn-wrapper .btn-selected {
    color: var(--green-color);
    border-color: var(--green-color);
    background-color: #FFFFFF;
}
.extra-card .btn-wrapper .btn-selected:hover {
    background-color: var(--green-color-light);
}

@media only screen and (min-width:600px){
    .extra-card .bottom-info {
        display: flex;
    }
}

@media only screen and (max-width:599px){
    .extra-card .extra-text {
        width:100%;
    }

    .extra-card .extra-price {
        padding-right: 30px;
        /* text-align: left; */
        width: 20%;
        float: left;
        margin-top:20px;
    }

    .extra-card .btn-wrapper {
        text-align:right;
        margin-top:10px;
    }

}

@media only screen and (max-width:365px){
    .extra-card .extra-price, .extra-card .btn-wrapper {
        width:100%;
        text-align:center;
    }
    
    .extra-card .btn-wrapper {
        margin-top:60px;
    }
}

.extra-card .extra-price .old {
    text-decoration: line-through;
    color: var(--text-color-light);
    line-height: 1em;
}

.extra-card .extra-price .final {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
}

.extra-card .btn-wrapper .btn svg {
    margin-right: 5px;
}

.extra-card.selected .btn-wrapper .btn-select,
.extra-card:not(.selected) .btn-wrapper .btn-remove {
    display: none;
}

.extra-card .btn-wrapper .btn-remove:not(:hover) {
    color: #FFFFFF;
    background-color: var(--site-color);
}

.extra-card .btn-wrapper .btn-remove:not(:disabled):hover {
    color: var(--site-color);
    background-color: transparent;
}

.extra-card .btn-wrapper .btn-included {
    cursor: default;
    border: none !important;
    background: none !important;
    color: var(--site-color) !important;
    box-shadow: none;
}

.extra-footer {
    text-align: right;
}

.extra-footer .price {
    margin-top: 5px;
    font-size: 25px;
    font-weight: bold;
    font-family: var(--site-primary-title-font);
    color: var(--site-color);
}

/* lateral cart */
.process-cart div.cart-content .extras-list {
    border-top: 1px solid var(--site-border-color);
}

/** END PÁGINA DE EXTRAS **/

/** START PÁGINA INTERMEDIA DE LOGIN/REGISTER **/
.search-result .search-result-content .search-result-list .login-form-wrapper,
.search-result .search-result-content .search-result-list .register-form-wrapper {
    margin: 33px auto 0;
    padding: 30px 30px 40px;
    border: 1px solid var(--site-border-color);
    background: var(--grey-color-light);
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-box {
    display: block;
    width: 100%;
    max-width: 318px;
    margin: 0 auto;
    padding: 0;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-box + .form-box {
    margin-top: 16px;
    border-top: 1px solid var(--site-border-color);
    padding-top: 16px;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .title,
.search-result .search-result-content .search-result-list .login-form-wrapper .sub-title {
    margin: 0 auto 1em;
    font-family: var(--site-title-font);
    font-size: 20px;
    color: var(--text-color);
    font-weight: 400;
    text-align: center;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .sub-title {
    font-size: 16px;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .input-wrapper {
    margin-bottom: 16px;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .input-wrapper.simple-input label {
    color: var(--site-color);
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .input-wrapper.simple-input input::-webkit-input-placeholder { /* Edge */
    color: var(--grey-color);
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .input-wrapper.simple-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--grey-color);
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .input-wrapper.simple-input input::placeholder {
    color: var(--grey-color);
}

.search-result .search-result-content .search-result-list .login-form-wrapper .btn {
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .btn-primary,
.search-result .search-result-content .search-result-list .login-form-wrapper .form-style p {
    margin: 0;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style .btn-primary + p,
.search-result .search-result-content .search-result-list .login-form-wrapper .form-style p + .btn-primary,
.search-result .search-result-content .search-result-list .login-form-wrapper .form-style p + p {
    margin-top: 16px;
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style p {
    color: var(--grey-color);
}

.search-result .search-result-content .search-result-list .login-form-wrapper .form-style p a {
    text-decoration: none;
}

/* register page */
.search-result .search-result-content .search-result-list .register-form-wrapper {
    padding: 0 0 20px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper > * {
    padding: 21px 18px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper > .title {
    text-align: left;
    background-color: #FFF;
    color: var(--site-color);
}

.search-result .search-result-content .search-result-list .register-form-wrapper > .title span{
    font-family: var(--site-title-font);
    font-weight: 400;
    font-size: 20px;
    color: var(--text-color);
    margin-left: 0.5em;
}
.search-result .search-result-content .search-result-list .register-form-wrapper > .title span {
    color: var(--text-color);
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group {
    text-align: center;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group + .inputs-group {
    margin-top: 10px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group > * {
    text-align: left;
    max-width: 580px;
    margin: 0 auto;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .input-wrapper,
.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group p,
.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .input-wrapper + .error-msg-red {
    margin-bottom: 20px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group > .sub-title {
    max-width: none;
    margin-bottom: 28px;
    padding: 0 0 8px;
    border-bottom: 1px solid var(--site-border-color);
    text-align: left;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
    color: var(--site-color);
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .checkbox-wrapper:not(:first-child) {
    margin-top: 16px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .checkbox-wrapper:not(:last-child),
.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper:not(:last-child) {
    margin-bottom: 32px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .checkbox-wrapper > .checkbox-group + .checkbox-group {    
    margin-top: 20px;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper {
    max-width: 520px;
    padding: 22px 32px 35px;

    background-color: var(--site-color-up);
    color: #FFF;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper p {
    line-height: 1.2em;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper .title {
    margin-bottom: 10px;

    color: currentColor !important;
    font-size: 1.35em;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper a,
.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper input[type="checkbox"] + label,
.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper .checkbox-group label {
    color: currentColor !important;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper .checkbox-group input {
    background-color: #FFF;
    border-color: #FFF;
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .spring-club-wrapper .checkbox-group input::before {
    border-color: var(--site-color-up);
}

.search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .buttons-wrapper > * + * {
    margin-top: 20px;
}

@media only screen and (min-width: 601px) {
    .search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .buttons-wrapper {
        display: flex;
    }

    .search-result .search-result-content .search-result-list .register-form-wrapper .inputs-group .buttons-wrapper > * + * {
        margin-top: 0;
        margin-left: 16px;
    }
}

/** END PÁGINA INTERMEDIA DE LOGIN **/

/** START FORMULARIO DE CHECKOUT **/
.checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: transparent;
    border-bottom: none;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) + dd {
    border-top: none;
}

.checkout .checkout-content .checkout-elements > * + * {
    margin-top: 40px;
    margin-bottom: 40px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box,
.checkout .checkout-content .checkout-elements dl.checkout-box > dd + dt:not(button):not(.spring-club-banner) {
    margin-top: 25px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) > * + *,
.checkout .checkout-content .checkout-elements dl.checkout-box > dt .title > * + * {
    margin-left: 0.5em;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) > *:last-child {
    text-align: right;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt .right-info {
    font-family: var(--site-title-font);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2em;
    color: var(--grey-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt .title {
    font-family: var(--site-title-font);
    font-weight: 400;
    font-size: 20px;
    color: var(--text-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dt .title svg {
    color: var(--site-color);
}

.checkout .checkout-content .itinerary-wrapper {
    position: relative;
    max-width: 400px;
    margin: 20px auto 40px;
    padding: 0;

    font-size: 10px;
    color: var(--text-color-light);
}

.checkout .checkout-content .itinerary-wrapper > * {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.checkout .checkout-content .itinerary-wrapper > * + * {
    margin-top: 24px;
}

.checkout .checkout-content .itinerary-wrapper::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 40%;

    display: block;
    width: 2px;
    height: calc(100% - 25px);
    margin: 0 0 0 -1px;

    background: var(--site-color);
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step.flight {
    z-index: 3;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-circle {
    position: absolute;
    top: 0;
    left: 40%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 26px;
    height: 26px;
    margin: 0 auto 0 -15px;
    padding: 0;

    color: var(--site-color);
    background: var(--site-color-light);
    border: 2px solid var(--site-color);
    border-radius: 50%;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-circle svg {
    display: block;
    max-width: 14px;
    height: auto;
    margin: auto;
    padding: 0;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-taxi,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .date {
    display: none;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-date {
    position: absolute;
    top: 0;
    right: 60%;

    display: block;
    margin: 0;
    padding: 0 30px 0 0;

    color: var(--site-color);
    font-size: 10px;
    line-height: 12px;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step svg,
.checkout .checkout-content .itinerary-wrapper .itinerary-step svg + * {
    display: inline-block;
    vertical-align: middle;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step svg + * {
    margin-left: 5px;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info {
    position: relative;
    display: block;
    width: calc(60% - 30px);
    margin: 0 0 0 auto;
    text-align: left;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info p {
    line-height: 1em;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info ul.extras,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info ul.extras li {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info > * + *,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .time span,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info ul.extras li {
    margin-top: 8px;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .title {
    position: relative;
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: var(--site-color);
    text-decoration: none;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info a.title:hover,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .title.info-link:hover {
    color: var(--site-color-hover);
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .title > span,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .title > span + svg {
    display: inline-block;
    vertical-align: middle;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .title > span + svg {
    margin-left: 5px;
    line-height: 1em;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .time {
    margin-top: 0;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .time span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
}

.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .night svg,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .date svg,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info ul.extras li,
.checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .time strong {
    font-weight: normal;
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header,
.checkout .checkout-content .checkout-elements dl.checkout-box > dd .inputs-box-title {
    display: block;
    width: 100%;
    margin: 0 0 28px;
    padding: 0 0 10px;
    border-bottom: 1px solid var(--site-border-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header .title,
.checkout .checkout-content .checkout-elements dl.checkout-box > dd .inputs-box-title {
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    font-size: 16px;
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header > * {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header > * + * {
    margin-left: 1em;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header > .checkbox-group,
.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header > .input-wrapper {
    display: block;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header .radio-group
.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header .right-info {
    float: right;
    
    font-family: var(--site-primary-title-font);
    font-weight: 700;
    font-size: 16px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header .right-info .price {
    font-weight: 700;
    font-size: 20px;
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .header .right-info * + .price {
    display: inline-block;
    margin-left: 0.5em;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .inputs-box + .inputs-box {
    margin-top: 20px;
    border-top: 1px solid var(--site-border-color);
    padding-top: 30px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .inputs-box > * {
    /* max-width: 584px; */
    margin-left: auto;
    margin-right: auto;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .input-wrapper.inline-radio > * {
    display: inline-block;
    vertical-align: middle;

    font-family: var(--site-primary-title-font);
    font-size: 16px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .input-wrapper.inline-radio > * + * {
    margin-left: 34px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box > dd .inputs-box p + *,
.checkout .checkout-content .checkout-elements .checkbox-wrapper > .checkbox-group + .checkbox-group {
    margin-top: 20px;
}

.checkbox-wrapper .terminos-y-condiciones {
    padding: 15px 0px 15px 0px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.invoice-data > dd .checkbox-group,
.checkout .checkout-content .checkout-elements dl.checkout-box.invoice-data > dd .radio-group {
    font-family: var(--site-primary-title-font);
    font-weight: 700;
    font-size: 16px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.invoice-data > dd .header {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.invoice-data > dd #invoice_data_content {
    margin-top: 25px;
    padding-top: 28px;
    border-top: 1px solid var(--site-border-color);
}

.checkout .checkout-content .checkout-elements dl.payment-data > dd .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout .checkout-content .checkout-elements dl.payment-data > dd .header > *:last-child {
    text-align: right;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info dd {
    background-color: transparent;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info dd > * {
    border-top: 1px solid var(--site-border-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .open-booking-details {
    display: inline-block;
    margin: 0 0 40px;

    font-family: var(--site-primary-title-font);
    font-weight: bold;
    text-decoration: underline;
    color: var(--text-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .open-booking-details:hover,
.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .open-booking-details:focus {
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info,
.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info > * {
    display: block;
    margin: 0;
    padding: 0;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info > * + * {
    margin-top: 30px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .info-wrapper,
.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .price-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .info-wrapper .title {
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    color: var(--text-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .info-wrapper .dates {
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .info-wrapper .dates .separator {
    display: inline-block;
    margin-left: 1em;
    margin-right: 1em;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .info-wrapper .price {
    font-size: 16px;
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .promo-banner {
    margin-left: -8px;
    margin-right: -8px;

    padding: 16px;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .price-wrapper .title {
    font-size: 16px;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    color: var(--text-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .price-wrapper .price {
    font-size: 20px;
    font-weight: bold;
    color: var(--site-color);
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .price-wrapper .price .old {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-color-light);
    text-decoration: line-through;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .price-wrapper .price * {
    display: inline-block;
    vertical-align: middle;
}

.checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info .basic-info .price-wrapper .price * + * {
    margin-left: 8px;
}

/* popup with booking details */
.booking-details-popup-wrapper .booking-details-popup-header {
    padding: 25px;
    border-bottom: 1px solid var(--site-border-color);
}

.booking-details-popup-wrapper .booking-details-popup-header .title {
    margin: 0;
    padding: 0;

    font-size: 16px;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    color: var(--text-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content > * {
    padding: 20px;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content > * + * {
    border-top: 1px solid var(--site-border-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .header-info,
.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .header-info {
    padding: 0 0 11px;
    border-bottom: 1px solid var(--grey-color-light);
    margin: 0 0 18px;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .header-info .title {
    font-size: 1em;
    font-family: var(--site-primary-title-font);
    font-weight: normal;
    color: var(--text-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .header-info .price {
    font-size: 16px;
    font-weight: bold;
    color: var(--site-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content table tr td {
    vertical-align: top;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content table tr td + td {
    padding-left: 16px;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content table .date,
.booking-details-popup-wrapper .booking-details-popup-content .cart-content table .dates,
.booking-details-popup-wrapper .booking-details-popup-content .cart-content table .price {
    color: var(--site-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .room-info + .room-info td,
.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-extra + .cart-extra td {
    padding-top: 5px;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .room-info .discount {
    font-size: 10px;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    color: var(--site-color-up);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-price .title {
    font-size: 16px;
    font-family: var(--site-primary-title-font);
    font-weight: bold;
    color: var(--text-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-price .price {
    font-size: 20px;
    font-weight: bold;
    color: var(--site-color);
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-price .price * {
    display: inline-block;
    vertical-align: middle;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-price .price * + * {
    margin-left: 8px;
}

.booking-details-popup-wrapper .booking-details-popup-content .cart-content .cart-price .price .old {
    font-size: 14px;
    font-weight: normal;
    color: var(--text-color-light);
    text-decoration: line-through;
}

/* with lateral cart */
@media only screen and (min-width: 1100px) {
    .process-cart div.cart-content .dropdown-cart-header,
    .process-cart > .process-cart-header {
        cursor: default !important;
    }

    .process-cart div.cart-content .MuiCollapse-container,
    .search-result-aside .process-cart .MuiCollapse-container,
    .checkout .checkout-content .checkout-elements dl.checkout-box > dd.plegable > * {
        display: block !important;
        height: auto !important;
        padding: 20px !important;
    }

    .process-cart div.cart-content .dropdown-cart-header::after,
    .process-cart > .process-cart-header::after,
    .checkout .checkout-content .checkout-elements dl.checkout-box.mobile-booking-info {
        display: none !important;
    }
}

/* without lateral cart */
@media only screen and (max-width: 1099px) {
    .checkout .checkout-content .checkout-elements dl.checkout-box > dt.close,
    .checkout .checkout-content .checkout-elements dl.checkout-box > dt.opened,
    .success .success-summary .success-elements dl.success-box > dt.close,
    .success .success-summary .success-elements dl.success-box > dt.opened {
        cursor: pointer;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box > dt.close::after,
    .checkout .checkout-content .checkout-elements dl.checkout-box > dt.opened::after,
    .success .success-summary .success-elements dl.success-box > dt.close::after,
    .success .success-summary .success-elements dl.success-box > dt.opened::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        display: block;
        width: 8px;
        height: 5px;
        margin: -3px 0 0;
        box-sizing: border-box;
        border-style: solid;
        border-color: transparent transparent var(--site-color) transparent;
        border-width: 0 4px 4px 4px;
        
        transition: all 100ms;
        -moz-transition: all 100ms;
        -webkit-transition: all 100ms;
        -o-transition: all 100ms;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box > dt.close::after,
    .success .success-summary .success-elements dl.success-box > dt.close::after {
        transform: rotate(-180deg);
    }
}

/* small mobile version */
@media only screen and (max-width: 599px) {
    .search-result .search-result-content .search-result-title {
        border-bottom: none;
    }

    .search-result .search-result-content .search-result-list .list-header:first-child {
        padding-top: 0;
    }

    .search-result .search-result-content .search-result-list .login-form-wrapper,
    .search-result .search-result-content .search-result-list .register-form-wrapper,
    .checkout .checkout-content .checkout-elements dl.checkout-box {
        margin-left: -20px;
        margin-right: -20px;
    }

    .extra-card:first-child,
    .search-result .search-result-content .search-result-list .login-form-wrapper,
    .search-result .search-result-content .search-result-list .register-form-wrapper,
    .checkout .checkout-content .checkout-elements dl.checkout-box,
    .checkout .checkout-content .checkout-elements dl.checkout-box > dd + dt:not(button):not(.spring-club-banner) {
        margin-top: 0;
    }

    .extra-card {
        display: block;
        margin-bottom: 16px;
    }

    .extra-card > svg,
    .extra-card > .extra-info {
        display: inline-block;
        vertical-align: middle;
    }

    .extra-card > .extra-info {
        max-width: calc(100% - 5em);
        padding-right: 0;
    }

    .extra-card .bottom-info {
        justify-content: flex-end;

        margin-top: 25px;
        border-top: 1px solid var(--site-border-color);
        padding-top: 18px;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box + dl.checkout-box > *:not(button):not(.spring-club-banner),
    .checkout .checkout-content .checkout-elements dl.checkout-box > dd + dt:not(button):not(.spring-club-banner) {
        border-top: none;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) {
        display: block;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) > * + * {
        margin-top: 0.5em;
        margin-left: 1.8em;
    }

    .checkout .checkout-content .checkout-elements dl.checkout-box > dt:not(button):not(.spring-club-banner) > *:last-child {
        text-align: left;
    }

    .search-result .search-result-content .search-result-list .login-form-wrapper,
    .search-result .search-result-content .search-result-list .register-form-wrapper,
    .checkout .checkout-content .checkout-elements dl.checkout-box > *:not(button):not(.spring-club-banner) {
        border-left: none;
        border-right: none;
    }

    .search-result .search-result-content .search-result-list .login-form-wrapper,
    .search-result .search-result-content .search-result-list .register-form-wrapper {
        margin-bottom: -25px;
    }
}

@media only screen and (max-width: 450px) {
    .checkout .checkout-content .itinerary-wrapper::before,
    .checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-circle {
        left: 120px;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-date {
        right: auto;
        left: 0;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info {
        width: calc(100% - 150px);
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .tooltip-linck .tooltip-info {
        left: auto;
        right: 0;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .tooltip-linck .tooltip-info:before,
    .checkout .checkout-content .itinerary-wrapper .itinerary-step .tooltip-linck .tooltip-info:after {
        left: auto;
        right: 30px;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .tooltip-linck .tooltip-info:after {
        margin-left: 0;
        margin-right: 1px;
    }
}

@media only screen and (min-width: 600px) {
    .checkout .checkout-content .form-style .column-inputs > .input-wrapper.inline-radio {
        padding-top: 30px;
    }
}

/* horizontal version */
@media only screen and (min-width: 700px) {
    .checkout .checkout-content .itinerary-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;

        max-width: 700px;
    }

    .checkout .checkout-content .itinerary-wrapper > * + * {
        margin-top: 0;
        margin-left: 45px;
    }

    .checkout .checkout-content .itinerary-wrapper::before {
        top: 15px;
        left: 10px;
        width: calc(100% - 25px);
        height: 2px;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step.place {
        flex: 1;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step.hotel {
        flex: 2;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step.flight {
        flex: 3;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step:last-child {
        background-color: var(--site-color-light);
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-circle {
        position: relative;
        top: auto;
        left: 0;
        margin: 0;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info {
        width: auto;
        margin: 10px 0 0;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-date {
        display: none;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-taxi {
        position: absolute;
        top: 0;
        right: 20%;

        display: block;
        color: var(--site-color);
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .icon-taxi svg {
        width: 1.3em;
        height: auto;
    }

    .checkout .checkout-content .itinerary-wrapper .itinerary-step .itinerary-step-info .date {
        display: block;
        color: var(--site-color);
    }
}


.insurances .inputs-box .insurance-name {float:left;}

.insurances .inputs-box .price {float:right;}

.insurances .inputs-box .insurance-name,
.insurances .inputs-box .price {
    font-weight: bold;
    color: var(--site-color);
    font-size:15px;
}
    
.insurances .MuiAccordion-root.Mui-expanded {
    margin:0px;
}

.insurances .MuiAccordionDetails-root {
    display:grid !important;
} 

.insurances .insurance-description {
    display:flex;
}
.insurances .insurance-description .description {
    width:75%;
}

.insurances .insurance-description .price{
    text-align:right;
    width:25%;
}


.insurances .insurance-description .price p{
    font-weight: bold;
    color: var(--site-color);
    font-size:16px;
}


/** END FORMULARIO DE CHECKOUT **/

/** PÁGINA DE CONFIRMACION **/
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-header {
    font-family: var(--site-title-font);
    width:100%;
}

/* .confirm-booking-wrapper .confirm-booking-content .confirm-booking-header span {
    font-size:16px;
} */

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-header .text-failure {
    color:red;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-header .text-failure-2 {
    font-size:16px;
    font-weight:bold;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-header .booking-id {
    float: right;
    padding-left: 1em;
    font-family: var(--text-font);
    font-size: 16px;
    color: var(--text-color);
}

@media only screen and (max-width:713px){
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-header {
        padding-bottom: 50px;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-header .booking-id {
        width:100%;
    }
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-header .booking-id > strong {
    font-family: var(--site-title-font);
    font-size: 25px;
    color: var(--site-color);
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info > * + * {
    margin-top: 40px;
    margin-bottom: 0;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info > p {
    font-size: 16px;
    margin-bottom: 1em;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info {
    font-size: 16px;
    padding: 22px 0;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info > * + * {
    margin-top: 19px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .check-msg {
    font-family: var(--site-title-font);
    font-size: 20px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .check-msg svg {
    color: var(--site-color);
}

@media only screen and (min-width:600px){
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .sent {
        margin-top: 50px;
    }
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files a,
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files a > * {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    font-family: var(--site-primary-title-font);
    font-weight: normal;
    font-size: 16px;
}

@media only screen and (min-width:442px) and (max-width:599px){
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files a,
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files a > * {
        margin-top:0px !important;
    } 

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files svg {
        padding-left:10px;
    }
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files a + a {
    margin-top: 1em;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .download-files a > * + * {
    margin-left: 10px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box {
    margin-top: 40px;
    padding: 35px;
    border: 1px solid #CCD9D6;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .airline:first-child img{
    float:left;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .airline:last-child img{
    float:right;
}

@media only screen and (min-width:1100px){
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box {
        width:132%;
    }
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table {
    width: calc(100% - 40px);
    margin: 0 auto;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr th,
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td {
    padding: 6px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr th:last-child,
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td:last-child {
    padding-right: 0;
    text-align: right;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr th:first-child,
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td:first-child {
    padding-left: 20px;
    text-align: left;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr th {
    font-family: var(--site-primary-title-font);
    color: var(--grey-color);
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr + tr th {
    padding-top: 14px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr th strong {
    color: var(--text-color);
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr th::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin: 11px 0 0;

    background: var(--grey-color-light);
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box .booking-data > td:first-child {
    color:var(--text-color);
    font-size:16px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box .booking-data > td:last-child {
    color: var(--site-color);
    font-size:16px;
    font-weight:bold;
}

@media only screen and (min-width:601px){
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td > * + * {
        margin-top: 5px;
    }
}

@media only screen and (max-width:600px){
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td > * + * {
        margin-top: 0px;
    }
}
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .price {
    font-size: 16px;
    color: var(--site-color);
    white-space:nowrap;
}


@media only screen and (max-width: 600px) {
    table .flex {
        display:flex;
        padding-left:20px !important;
        padding-right:0 !important;
        text-align:left !important;
    }
}


.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .price-text,
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .date {
    color: var(--site-color);
    white-space:nowrap;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .price .old-price {
    margin-right: 8px;
    text-decoration: line-through;
    font-size: 14px;
    color: var(--grey-color);
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr .discount-info {
    color: #9A8249;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td.separator {
    padding: 14px 0;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr td.separator::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;

    background: #CCD9D6;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount {
    position: relative;
    background: #DFF2DF59;
}

/* .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 14px;
    left: -55px;

    display: block;
    width: calc(100% + 110px);
    height: calc(100% - 28px);
    background: #DFF2DF59;
} */

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount td {
    position: relative;
    z-index: 2;

    padding-top: 24px;
    padding-bottom: 24px;

    color: #3E8F3D;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount .price {
    color: currentColor;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount td p > * {
    display: inline-block;
    vertical-align: middle;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-download-files {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 16px;
}

@media only screen and (min-width:1100px) {
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons {
        width:146%;
    }
}
.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons > * {
    flex: 1;
    width: 100%;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons > * + * {
    margin-top: 25px;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons .btn > * {
    display: inline-block;
    vertical-align: middle;
}

.confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons .btn > * + * {
    margin-left: 10px;
}

@media only screen and (min-width: 600px) {
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .check-msg {
        display: inline-block;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .download-files {
        float: right;
        text-align: right;
        max-width: 14em;
        margin-top: 0;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .download-files > * + * {
        margin-top: 2em;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .header-info .download-files + * {
        clear: both;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons {
        flex-direction: row;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .footer-buttons > * + * {
        margin-top: 0;
        margin-left: 25px;
    }
}

@media only screen and (max-width: 700px) {
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table {
        width: 100%;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount::after {
        left: -35px;
        width: calc(100% + 70px);
    }
}

@media only screen and (max-width: 500px) {
    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box {
        padding: 20px;
    }

    .confirm-booking-wrapper .confirm-booking-content .confirm-booking-info .info-box table tr.flight-discount::after {
        left: -20px;
        width: calc(100% + 40px);
    }
}

/** END PÁGINA DE CONFIRMACION **/

/** Agency Spring 40 switcher **/
.agency-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 24px auto;
    padding: 20px 23px;
    text-align: left;
    background: var(--site-color-light);
}

.agency-wrapper:first-child {
    margin-top: 0;
}

.agency-wrapper:last-child {
    margin-bottom: 0;
}

.agency-wrapper .switch-group {
    white-space: nowrap;
}

.agency-wrapper > * + * {
    margin-left: 70px;
}

.agency-wrapper p {
    color: var(--grey-color);
}

.search-result-title + .search-result-list .agency-wrapper:first-child {
    margin-top: 20px;
    margin-bottom: 0;
}

@media only screen and (max-width: 599px) {
    .agency-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .agency-wrapper > * + * {
        margin-top: 1em;
        margin-left: 0;
    }

    .search-result-title + .search-result-list .agency-wrapper:first-child {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

/** END Agency Spring 40 switcher **/

/** Ajuste para los popups **/
.room-card .room-config.opened-popup,
.room-card .room-config.opened-popup .title-row,
.room-card .room-config.opened-popup > .room-pensions{ z-index: 4; }
.room-card .room-config.opened-popup.title-popup .title-row{ z-index:5; }


/* Nuevos estilos añadidos por Javier */
#changeModal {
    padding: 33px 33px 40px;
}

#changeModal .header h1 {
    text-align:center;
    margin-bottom: 20px;
}

#changeModal .flight-wrapper {
    border-bottom:none;
}

#changeModal .lista {
    margin-bottom: 20px;
}

#changeModal #button-combined, #button-combinable {
    width:50%;
    border:none;
    border-bottom: 1px solid;
}

#changeModal #button-save {
    width:100%;
}

.combined-flight {
    text-align:center;
}

.combined-inbound, .combined-outbound {
    box-shadow:none !important;
    margin-bottom: 0px !important;
}

.combined-outbound {
    border-right:none !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.combined-inbound {
    border-left:none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

@media (max-width: 740px){
    .combined-inbound, .combined-outbound {
        width:98% !important;
        margin:0 !important;
    }
    
    .combined-outbound {
        border-bottom: none !important;
        /* border-left: 1px solid var(--site-border-color) !important;
        border-top: 1px solid var(--site-border-color) !important;
        border-right: 1px solid var(--site-border-color) !important; */
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .combined-inbound {
        border-top: none !important;
        /* border-left: 1px solid var(--site-border-color) !important;
        border-bottom: 1px solid var(--site-border-color) !important;
        border-right: 1px solid var(--site-border-color) !important; */
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
}

.seleccion {
    padding: 10px;
    text-align:right;
    color: var(--site-color);
    font-size:16px;
}

.seleccion-combinada{
    border: 1px solid var(--site-border-color);
    border-top: none;
    margin: 0px 14px 0px 14px;
    padding: 10px;
    text-align: right;
    color: var(--site-color);
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

@media (max-width: 720px){
    .seleccion-combinada{
        margin: 0px 2px 0px 2px;
    }
}

/******* ESTILOS MULTIHOTEL *************/
.multihotel {
    border-radius:8px !important;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 4px;
    width: 100%;
    border: 1px solid rgb(204, 217, 214);
    box-shadow: rgb(0 0 0 / 15%) 0px 4px 8px 0px;
    gap: 10px;
}

.multihotel .info-hotel {
    width:auto !important;
    border-bottom: 2px solid #D8D8D8;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    padding:0px;
    background:white;
    display:flex;
}

.multihotel .info-hotel > div:first-child{
    display: flex;
}

.multihotel .info-hotel > div:last-child{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 2em;
    padding: 30px 24px 20px;
}

.multihotel .info-hotel > div:first-child img{
    border-top-left-radius:8px;
}


.multihotel .info-hotel .valoration .golden-star{ 
    color:#F1BD3F;
    font-size:16px;
}

.multihotel .info-hotel .valoration .grey-star{ 
    color:#7A7A7A;
    font-size:16px;
}

.multihotel .info-hotel .localization p{
    color:#7A7A7A;
    font-weight:bold;
}

.multihotel .info-hotel .promoted {
    margin-top:-20px;
    margin-bottom:-10px;
}

.multihotel .info-hotel .promoted span{
    text-align: center;
    position: relative;
    background-color: #9AE4D6;
    padding:7px;
    float:right;
    font-weight:bold;
}
@media only screen and (min-width:601px){
    .multihotel .info-hotel > div:first-child{
        display: flex;
        width: 50%;
    }
}

@media only screen and (max-width:600px){
    .multihotel .info-hotel {
        display:block;
    }
    .multihotel .info-hotel > * {
        width: 100%;
    }
    .multihotel .info-hotel > div:last-child {
        padding: 20px 20px 20px !important;
        width:90%;
    }
    .multihotel .info-hotel > div:first-child img{
        border-top-left-radius:8px;
        border-top-right-radius:8px;
    }
    .multihotel .flex-menu {
        display: flex;
        flex-wrap: wrap;
    }
}

.multihotel .flex-menu {
    display:flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    gap: 2em;
}

.multihotel .flex-menu > div:last-child {
    padding: 0px 24px 8px 0 !important;
    align-items: center;
    gap: 1em;
    display:flex;
}

.multihotel .flex-menu .menu {
    justify-content: space-evenly;
    padding: 0 12px;
    width: 100%;
    display: flex;
}

.multihotel .flex-menu .menu > * {
    min-width: 80px;
    padding: 10px 14px 14px;
    cursor: pointer;
    color: #8B8B8B;
    text-decoration:none;
    text-align:center;
    font-size:13px;
}

.multihotel .flex-menu .menu > *:hover {
    text-decoration:underline;
}

.multihotel .flex-menu .choose-hotel.btn-select{
    color: #FFFFFF;
    background-color: var(--site-color-button);
    border: 1px solid var(--site-color-button);
    margin-left:0px;
    transition: background-color 0.5s linear;
}
.multihotel .flex-menu .choose-hotel.btn-select:hover{
    border-color: var(--site-color-hover);
    background-color: var(--site-color-hover);
    margin-left:0px;
}
.multihotel .flex-menu .choose-hotel.btn-selected{
    color: var(--green-color);
    border-color: var(--green-color);
    background-color: #FFFFFF;
    transition: background-color 0.5s linear;
}
.multihotel .flex-menu .choose-hotel.btn-selected:hover{
    background-color: var(--green-color-light);
}

.multihotel .flex-menu .pricetext {
    font-size: 1.3em;
    font-weight:bold;
}
/* .multihotel .flex-menu .menu > *:active {
    border-bottom: 1px solid red;
} */

@media only screen and (max-width:400px) {
    .multihotel .flex-menu > div:last-child p {
        width:35%;
        text-align:right;
    }
}

@media only screen and (min-width:401px) and (max-width:600px) {
    .multihotel .flex-menu > div:last-child p {
        width:50%;
    }
}

@media only screen and (min-width:600px) and (max-width:700px){
    .multihotel .flex-menu > div:last-child {
        display:initial;
        text-align:center;
    }
}

@media only screen and (max-width:600px) {
    .multihotel .flex-menu .menu > * {
        min-width: 0px;
    }
    .multihotel .flex-menu > div:last-child {
        display:block;
        text-align:center;
        flex:1;
    }

    .multihotel .flex-menu > div:first-child {
        order:2;
        margin-top:-10px;
    }

    .multihotel .flex-menu > div:last-child {
        order:1;
        margin-top:10px;
        display:flex;
    }

    .multihotel .flex-menu .choose-hotel,
    .multihotel .flex-menu .choose-hotel:hover{
        margin-left:20px;
    }
}

@media only screen and (min-width:601px) {
    .multihotel .flex-menu .menu > * {
        min-width: 80px;
    }
    .multihotel .flex-menu .choose-hotel,
    .multihotel .flex-menu .choose-hotel:hover{
        margin-left:0px;
    }
}

.multihotel .room-card {
    border:none;
    border-radius:0px;
}
.multihotel .description {
    width:auto !important;
}
/******* FIN ESTILOS MULTIHOTEL *************/


/******* ESTILOS DEL TIMER ******************/
.timer {
    background-color: var(--site-color-light);
    padding: 20px 23px;
    text-align:center;
}
.timer span {
    color: var(--site-color);
    font-weight:bold;
}

@media only screen and (max-width:1099px){
    .timer { padding: 7px 23px; }
}
/******* FIN ESTILOS DEL TIMER **************/

/******* MODAL DE EXPIRACION DE SESION ******/
/* #modal-expire::before {
    content: 'x';
    
    display: block;
    height: 1.2em;
    width: 80%;
    margin: auto auto 0;
    padding: 5px;
    font-family: monospace, Verdana, sans-serif;
    font-size: 20px;
    text-align: right;
    font-weight: lighter;
    color: red;
    background: transparent;
} */
@media only screen and (max-width:740px){
    #modal-expire {
        width: 250px !important;
    }
}

@media only screen and (max-width:741px){
    #modal-expire {
        width: 400px;
    }
}

#modal-expire a {
    float: right;
    color: var(--site-color);
    font-size: 18px;
}

#modal-expire .cuerpo {
    margin-top:25px;
}
/******* FIN MODAL DE EXPIRACION DE SESION **/

/****** ANIMACIONES DE BOTONES *******/
@media only screen and (max-width:740px){
    .animate-button {
        animation: zoomOutDown 1s ease;
    }
}

